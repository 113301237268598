// Get dependencies
import * as React from 'react';
import { 
    View,
    Text,
    StyleSheet,
    Pressable,
    Image,
    Keyboard, 
    Platform} from 'react-native';
import AsyncStorage from '@react-native-async-storage/async-storage';

// Get custom
import { style } from '../../lib/styles';
import { useUser } from '../../shared/services/user.service';
import { Heading, Paragraph } from '../../shared/ContentItems';

export const User = () => {

    const user = Platform.OS === 'ios' ? useUser() : useUser().user;

    return (
        <View style={[style.flex.std, style.flex.column]}>
            <Heading>Welcome, {user.displayName}</Heading>
            <Paragraph>Here you will see a list of your current stats.</Paragraph>
        </View>
    );
};

const componentStyles = StyleSheet.create({
    container: {
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
    },
});
