import type { NavigatorScreenParams } from '@react-navigation/native';

// Get screens
import { DashboardScreen } from './Dashboard';
import { SimulatorScreen } from './Simulator';
import { ProfileSettingsScreen } from './Profile';
import { BettingTrackerScreen } from './BettingTracker';
import { NewsScreen } from './News';
import { InformationScreen } from './Information';
import { AwardsScreen } from './Awards';

export type RootDrawerParamList = {
	Examples: undefined;
};
  
export type LinkComponentDemoParamList = {
	Article: { author: string };
	Albums: undefined;
};

export const SCREENS = {
	Dashboard: {
		title: 'Dashboard',
		component: DashboardScreen,
	},
	// BettingTracker: {
	// 	title: 'BettingTracker',
	// 	component: BettingTrackerScreen,
	// },
	// Simulator: {
	// 	title: 'Simulator',
	// 	component: SimulatorScreen,
	// },
	News: {
		title: 'News',
		component: NewsScreen,
	},
	// Awards: {
	// 	title: 'Stats',
	// 	component: AwardsScreen,
	// },
	InformationScreen: {
		title: 'Information',
		component: InformationScreen,
	},
	Profile: {
		title: 'Profile',
		component: ProfileSettingsScreen,
	},
	
}

type ParamListTypes = {
	Dashboard: undefined;
	NotFound: undefined;
	LinkComponent: NavigatorScreenParams<LinkComponentDemoParamList> | undefined;
};

export type RootStackParamList = {
	[P in Exclude<keyof typeof SCREENS, keyof ParamListTypes>]: undefined;
} & ParamListTypes;

// Make the default RootParamList the same as the RootStackParamList
declare global {
	// eslint-disable-next-line @typescript-eslint/no-namespace
	namespace ReactNavigation {
		interface RootParamList extends RootStackParamList {}
	}
}
  