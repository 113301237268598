import * as React from 'react';
import {
	Image,
	StyleSheet,
	View } from 'react-native';
	
// Get custom
import { styles, style } from '../lib/styles';

export function Welcome({navigation}) {

	return (
		<View style={styles.content}>
			<View style={[style.display.flex, style.flex.centerAll]}>
				<Image 
					source={require('../assets/img/MVPIQ-logo_full.png')}
					style={{width: 200, height: 200}} />
			</View>
		</View>
	);
};

const simStyle = StyleSheet.create({
	container: {
		flex: 1,
		marginHorizontal: 16,
	},
	item: {
		padding: 20,
		marginVertical: 8,
	},
	header: {
		fontSize: 32,
	},
	title: {
		fontSize: 24,
	},
});