import * as React from 'react';
import axios from 'axios';

import { globals } from './globals';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { parse } from 'path';

export class SportsData {

    static async fetchAFLEvents(): Promise<any> {

        try {
            const response = await fetch(globals.espn_australian_football_afl + '/events');
            const result = await response.json();
            const fetchEvents = result.items.map(async (item) => {
                try {
                    const itemResponse = await fetch(item.$ref);
                    const itemResult = await itemResponse.json();

                    return itemResult;
                } catch (error) {
                    return error;
                }
            });
            const eventsData = await Promise.all(fetchEvents);
            return eventsData;
        } catch (error) {
            return error;
        }
    }

    static async fetchNBAEvents(): Promise<any> {

        try {
            const response = await fetch(globals.espn_basketball_nba + '/events');
            const result = await response.json();
            const fetchEvents = result.items.map(async (item) => {
                try {
                    const itemResponse = await fetch(item.$ref);
                    const itemResult = await itemResponse.json();

                    return itemResult;
                } catch (error) {
                    return error;
                }
            });
            const eventsData = await Promise.all(fetchEvents);
            return eventsData;
        } catch (error) {
            return error;
        }
    }

    static async getNBAEvents(): Promise<any> {

        try {
            const response = await fetch(globals.NBAEventsURL);
            const data = await response.json();

            return data;
        } catch (error) {
            console.log(error);
            throw error;
        }
    }

    static async getNBAEvent(eventId: string): Promise<any> {
        
        try {
            const response = await fetch(globals.NBAEventsURL + eventId + '/competitions/' + eventId);
            const data = await response.json();
            return data;

        } catch (error) {
            return error;
        }
    }

    static async getNBATeam(teamId: string): Promise<any> {

        try {
            const response = await fetch(globals.NBATeamsURL + teamId);
            const data = await response.json();
            return data;

        } catch (error) {
            return error;
        }
    }

    static async getNFLEvents(): Promise<any> {

        try {
            const response = await fetch(globals.NFLEventsURL);
            const data = await response.json();
            return data;
        } catch (error) {
            console.log(error);
            throw error;
        }
    }

    static async fetchDataFromUrl(url: string): Promise<any> {

        try {
            const response = await fetch(url);
            const result = await response.json();

            return result;
        } catch (error) {
            return error;
        }
    }

    static async fetchESPNLeague(url: string): Promise<any> {

        try {
            const response = await fetch(url);
            const result = await response.json();

            return result;
        } catch (error) {
            return error;
        }
    }

    static async fetchESPNEvents(url: string): Promise<any> {

        try {
            const response = await fetch(url + '/events');
            const result = await response.json();
            const fetchEvents = result.items.map(async (item) => {
                try {
                    const itemResponse = await fetch(item.$ref);
                    const itemResult = await itemResponse.json();

                    return itemResult;
                } catch (error) {
                    return error;
                }
            });
            const eventsData = await Promise.all(fetchEvents);
            return eventsData;
        } catch (error) {
            return error;
        }
    }
    
    static async fetchESPNTeam(sport: string, league: string, season: string, team: string): Promise<any> {
        // http://sports.core.api.espn.com/v2/sports/soccer/leagues/eng.1/seasons/2023/teams/382?lang=en&region=us
        const urlString = globals.base_espn_api_url + sport + '/leagues/' + league + '/seasons/' + season + '/teams/' + team;

        try {
            const response = await fetch(urlString);
            const result = await response.json();

            return result;
        } catch (error) {
            return error;
        }
    }

    static async getStoredSportsData() {
        const storedData = await AsyncStorage.getItem('storedSportsData');
        const parsedData = await JSON.parse(storedData);

        if (parsedData) {
            return parsedData;
        } else {
            return 'User has not stored league data';
        }
    }

    static async storeSportsData(sport: string, key: string, value: any) {
        const storedSportsData = await this.getStoredSportsData();

        try {

            if (storedSportsData) {
                const sportData = storedSportsData[sport] || {}; // Get existing sport data or create new object
    
                if (sportData[key]) {
                    // Key already exists, update its value
                    // sportData[key] = {
                    //     ...sportData[key], // Preserve existing data
                    //     ...value // Update with new data
                    // };
                    sportData[key] = value;
                } else {
                    // Key does not exist, create new object
                    sportData[key] = value;
                }
    
                // Update storedSportsData with modified sportData
                storedSportsData[sport] = sportData;
    
                // Store updated storedSportsData in AsyncStorage
                await AsyncStorage.setItem('storedSportsData', JSON.stringify(storedSportsData));
    
                console.log('League Settings Updated: ', storedSportsData[sport]);
            } else {
                console.error("League data not found.");
            }
        } catch (error) {
            console.error(error);
        }
    }
}