import * as React from 'react';
import { 
  SafeAreaView, 
  ScrollView,
  RefreshControl,
  View } from 'react-native';
import { Divider } from '@rneui/themed';

// Get custom
import { globals } from '../lib/globals';
import { styles, style } from '../lib/styles';
import { NFLArticles } from '../components/NFLData';
import { LeagueArticles } from '../components/Events/Events';
import { Section, Btn, Heading } from '../shared/ContentItems';
import { AccordionComponent } from '../components/ui/AccordionComponent';

export function NewsScreen({navigation}) {
	const [refreshing, setRefreshing] = React.useState(false);

	const onRefresh = React.useCallback(() => {
		setRefreshing(true);
		setTimeout(() => {
			setRefreshing(false);
		}, 2000);
	}, []);

	return (
		<ScrollView refreshControl={<RefreshControl refreshing={refreshing} onRefresh={onRefresh} />}>

			<View style={[style.screen.container, style.margin.mt4]}>
				{/* <Section>
					<LeagueArticles league={globals.espn_american_football_nfl} url={globals.espn_american_football_nfl_news} limit={'5'} />
				</Section> */}

				<Section>
					<LeagueArticles league={globals.espn_basketball_wnba} url={globals.espn_basketball_wnba_news} limit={'5'} sport={'basketball'} title={'Latest WNBA Articles'} />
				</Section>

				<Section>
					<LeagueArticles league={globals.espn_hockey_nhl} url={globals.espn_hockey_nhl_news} limit={'5'} sport={'hockey'} title={'Latest NHL Articles'} />
				</Section>

				<Section>
					<LeagueArticles league={globals.espn_baseball_mlb} url={globals.espn_baseball_mlb_news} limit={'5'} sport={'baseball'} title={'Latest MLB Articles'} />
				</Section>

				<Section>
					<LeagueArticles league={globals.espn_soccer_usa_1} url={globals.espn_soccer_usa_1_news} limit={'5'} sport={'soccer'} title={'Latest MLS Articles'} />
				</Section>

				<Section>
					<LeagueArticles league={globals.espn_soccer_uefa_euro_news} url={globals.espn_soccer_uefa_euro_news} limit={'5'} sport={'soccer'} title={'Latest EUFA EURO Articles'} />
				</Section>
			</View>

		</ScrollView>
	);
}