import * as React from 'react';
import * as Animatable from 'react-native-animatable';
import {
    Text,
    View,
    Image,
    Pressable,
    LayoutAnimation,
    UIManager,
    Platform,
    ActivityIndicator } from 'react-native';
import { Divider } from '@rneui/themed';
import { A, S } from '@expo/html-elements';
import * as Icons from '@expo/vector-icons';
import axios from 'axios';
import moment from 'moment';
import Moment from 'react-moment';
import AsyncStorage from '@react-native-async-storage/async-storage';

// Get Custom
import { generateScores } from '../../lib/simulationScript';
import { globals } from '../../lib/globals';
import { styles, style } from '../../lib/styles';
import { Section, Heading, Paragraph, Btn } from '../../shared/ContentItems';
import { UnorderedList2Col, UnorderedList } from '../ui/UnorderedList';
import { AccordionComponent } from '../ui/AccordionComponent';
import { SimResults } from '../NFL/SimResults';
import { NBASimulation } from '../NBA/NBAData';
import { SportsData } from '../../lib/sportsdata';
import Article from '../NFL/Article';
import { useUser } from '../../shared/services/user.service';

export const League = (props: any) => {
    const [loading, setLoading] = React.useState(true);
    const [league, setLeague] = React.useState({});
    const [leagueLogo, setLeagueLogo] = React.useState(undefined);
    const [seasonYear, setSeasonYear] = React.useState();
    const [events, setEvents] = React.useState([]);

    React.useEffect(() => {

        const getLeague = async () => {

            try {
                const leagueFetch = await fetch(props.leagueURL);
                const leagueData = await leagueFetch.json();

                if (leagueData) {
                    setLeague(leagueData);
                    setLeagueLogo(leagueData.logos[0].href);
                    setSeasonYear(leagueData.season.displayName.replace(/-/g, "_"));

                    const getEvents = async () => {
                        const eventsFetch = await fetch(leagueData.events.$ref);
                        const eventsData = await eventsFetch.json();
                        
                        if (eventsData && eventsData.items) {
    
                            const fetchEvents = eventsData.items.map(async (event) => {
                                try {
                                    const eventFetch = await fetch(event.$ref);
                                    const eventData = await eventFetch.json();
                                    
                                    if (eventData) { 
                                        const date = new Date(eventData.date);
                                        
                                        if (eventData.date) {
                                            const newDate = new Date(eventData.date);
                                            const formattedDate = newDate.toLocaleDateString('en-US', { month: 'long', day: 'numeric' });
                                            const formattedTime = newDate.toLocaleTimeString('en-US', { hour: 'numeric', minute: 'numeric'});
                                            eventData.day = formattedDate;
                                            eventData.time = formattedTime;
                                        }
    
                                        return eventData;
                                    } else {
                                        return 'Error fetching event data: event = ' + eventData; 
                                    }
                                } catch (error) {
                                    console.log(error);
                                    return error;
                                }
                            });
                            const eventsResult = await Promise.all(fetchEvents);
                            setEvents(eventsResult);
                        }
                    }
                    getEvents();
                }
            } catch (error) {
                console.log(error);
                throw error;
            } finally {
                setLoading(false);
            }
        }
        getLeague();

    }, [props.leagueURL]);

    const handleStoringLeagueData = async () => {
        const storedSportsData = await SportsData.getStoredSportsData();

        let leagueData: any = {
            userSelected: true,
            [seasonYear as string]: {
                events: {},
            }
        };

        SportsData.storeSportsData(props.sport, props.league, leagueData);
    }

    // Render loading indicator or handle the loading state
    if (loading) {
        return (
            <>
                <Heading>{props.sport}</Heading>
                <View style={styles.loadingContainer}> 
                    <ActivityIndicator size="large" color={globals.lightGray} />
                </View>
            </>
        );
    }

    return (
        <>
            {league ? ( 
                <>  
                    <View style={[style.flex.row, style.flex.jBetween, style.flex.aCenter, style.padding.p1_half]}>
                        <Heading style={[style.text.h2, style.margin.mb0]}>{props.title ? props.title : league['shortName']}</Heading>
                        {leagueLogo && (
                            <View>
                                <Image 
                                    source={leagueLogo}
                                    resizeMode={'contain'}
                                    style={{ width: 50, height: 50 }}
                                />
                            </View>
                        )}
                        
                    </View>
                    
                    <Divider style={styles.mb1} width={2} color={globals.lightGray} />
                    {/* <View>
                        <Btn onPress={() => handleStoringLeagueData()} style={[style.btn.std, style.btn.solidSec]}>Set LEAGUE Data</Btn>
                    </View> */}
                    {events && events.length > 0 ? (
                        <>
                            {events.map((item, i) => {
                                 if (i === 0 || item.day !== events[i - 1]?.day) {
                                    return (
                                        <Event key={i} data={item} day={item.day} first={i === 0 ? true : false} sport={props.sport} league={props.league} seasonYear={seasonYear} />
                                    );
                                } else {
                                    return (
                                        <Event key={i} data={item} day={null} first={null} sport={props.sport} league={props.league} seasonYear={seasonYear} />
                                    );
                                }
                            })}
                        </>
                    ) : (
                        <>
                            <Heading style={[style.text.h4]}>No Upcomming Events.</Heading>
                        </>
                    )}
                </>
            ) : (
                <>
                    <Heading style={[style.text.h3]}>No League Found.</Heading>
                </>
            )}
        </>
    )
}

export const Event = (props: any) => {
    const [loading, setLoading] = React.useState(true);
    const [error, setError] = React.useState(null);
    const [eventData, setEventData] = React.useState({});
    const [mmaFights, setmmaFights] = React.useState([]);
    const [competitors, setCompetitors] = React.useState([]);
    const [competitions, setCompetitions] = React.useState([]);
    const [eventStatus, setEventStatus] = React.useState();
    const [homeTeam, setHomeTeam] = React.useState({});
    const [homeTeamRecord, setHomeTeamRecord] = React.useState();
    const [homeTeamRank, setHomeTeamRank] = React.useState();
    const [homeTeamScore, setHomeTeamScore] = React.useState();
    const [homeTeamLogo, setHomeTeamLogo] = React.useState('');
    const [awayTeam, setAwayTeam] = React.useState({});
    const [awayTeamRecord, setAwayTeamRecord] = React.useState();
    const [awayTeamRank, setAwayTeamRank] = React.useState();
    const [awayTeamScore, setAwayTeamScore] = React.useState();
    const [awayTeamLogo, setAwayTeamLogo] = React.useState('');
    const [selectedAwayToWin, setSelectedAwayToWin] = React.useState(false);
    const [selectedHomeToWin, setSelectedHomeToWin] = React.useState(false);
    const [awayWinPercentage, setAwayWinPercentage] = React.useState(undefined);
    const [homeWinPercentage, setHomeWinPercentage] = React.useState(undefined);
    const [eventVisibility, setEventVisibility] = React.useState(false);
    const [isLoggedIn, setIsLoggedIn] = React.useState(false);

    const event = props.data;
    const wnba = (props.data.$ref as string).includes('/wnba/');
    const racing = (props.data.$ref as string).includes('/racing/');
    const golf = (props.data.$ref as string).includes('/golf/');
    const mma = (props.data.$ref as string).includes('/mma/');
    const tennis = (props.data.$ref as string).includes('/tennis/');

    React.useEffect(() => {
        AsyncStorage.getItem('loggedIn').then((res) => {
            if (res === 'true') setIsLoggedIn(true); 
            else setIsLoggedIn(false);
        })
    })

    React.useEffect(() => {

        const fetchData = () => {

            if (props.data.competitions) {

                if (golf || mma || racing || tennis) {
                    setCompetitions(props.data.competitions);

                    props.data.competitions.map(async (competition) => {
                        
                        if (competition.date) {
                            const competitionDate = new Date(competition.date);

                            if (new Date() > competitionDate) {
                                setCompetitors(competition.competitors);
                                setEventData(competition);
                            }
                        }
                    })

                    setLoading(false);
                    
                } else {

                    props.data.competitions.forEach(async (competition: any) => {
                        setEventData(competition);
    
                        if (competition.status) {
                            const statusFetch = await fetch(competition.status.$ref);
    
                            if (statusFetch) {
                                const statusFetchResponse = await statusFetch.json();
                                setEventStatus(statusFetchResponse);
                            }
                        }
    
                        if (competition.competitors) {
    
                            competition.competitors.forEach((competitor: any) => {
    
                                const fetchTeamData = async () => {
    
                                    const teamFetch = await fetch(competitor.team.$ref);
                                    const teamFetchResponse = await teamFetch.json();
    
                                    if (teamFetchResponse) {
    
                                        const scoreFetch = await fetch(competitor.score.$ref);
                                        const scoreFetchResponse = await scoreFetch.json();
    
                                        const recordFetch = await fetch(competitor.record.$ref);
                                        const recordFetchResponse = await recordFetch.json();
                                    
                                        if (competitor.homeAway) {
    
                                            if (competitor.homeAway === 'away') {
                                                setAwayTeam(teamFetchResponse);
                                                
                                                if (recordFetchResponse.count > 0) {
                                                    setAwayTeamRecord(recordFetchResponse.items[0]);
                                                }
    
                                                if (competitor.curatedRank) {
                                                    setAwayTeamRank(competitor.curatedRank);
                                                }
                                                
                                                if (teamFetchResponse.logos && teamFetchResponse.logos.length > 0) {
                                                    setAwayTeamLogo(teamFetchResponse.logos[0].href);
                                                }
                                                
                                                if (scoreFetchResponse) {
                                                    setAwayTeamScore(scoreFetchResponse);
                                                    setLoading(false);
                                                }
                                                setLoading(false);
                                            } else {
                                                setHomeTeam(teamFetchResponse);
    
                                                if (recordFetchResponse.count > 0) {
                                                    setHomeTeamRecord(recordFetchResponse.items[0]);
                                                }
    
                                                if (competitor.curatedRank) {
                                                    setHomeTeamRank(competitor.curatedRank);
                                                }
    
                                                if (teamFetchResponse.logos && teamFetchResponse.logos.length > 0) {
                                                    setHomeTeamLogo(teamFetchResponse.logos[0].href);
                                                }
            
                                                if (scoreFetchResponse) {
                                                    setHomeTeamScore(scoreFetchResponse);
                                                    setLoading(false);
                                                }
                                                setLoading(false);
                                            }
                                        } else {
                                            setLoading(false);
                                        }
                                    }                              
                                }
        
                                fetchTeamData();
                            });
    
                        }
                    });
                }
            }
        };
        fetchData();

    }, [props.data]);

    const eventExpanded = () => {
        LayoutAnimation.configureNext(LayoutAnimation.Presets.easeInEaseOut);
        setEventVisibility(!eventVisibility);
    }

    const setSelectedWinner = (choice: any) => {

        if (choice === 'away') {
            setSelectedHomeToWin(false);
            setSelectedAwayToWin(!selectedAwayToWin);
            AsyncStorage.setItem(props.data.id + '_choiceToWin', props.data.id + '_choiceToWin_' + awayTeam['abbreviation']);
        }
        if (choice === 'home') {
            setSelectedHomeToWin(!selectedHomeToWin);
            setSelectedAwayToWin(false);
            AsyncStorage.setItem(props.data.id + '_choiceToWin', props.data.id + '_choiceToWin_' + homeTeam['abbreviation']);
        }
    }

    const handleStoringEventData = async (eventId: string, choiceToWin: string) => {
        const storedSportsData = await SportsData.getStoredSportsData();

        let eventData: any = {
            [eventId as string]: {
                choiceToWin: choiceToWin,
            }
        };

        if (storedSportsData && 
            storedSportsData[props.sport] && 
            storedSportsData[props.sport][props.league] && 
            storedSportsData[props.sport][props.league][props.seasonYear] && 
            storedSportsData[props.sport][props.league][props.seasonYear]['events']) {
                
            storedSportsData[props.sport][props.league][props.seasonYear]['events'] = {
              ...storedSportsData[props.sport][props.league][props.seasonYear]['events'],
              [eventId]: eventData, // Assuming newEvent has a unique identifier 'id'
            };
        } else {
            console.error("League data or 'events' object not found.");
            return;
        }

        SportsData.storeSportsData(props.sport, props.league, storedSportsData);
    }

    const formatDate = (date: Date) => {
        const options = { year: 'numeric', month: 'long', day: 'numeric' };
        const formattedDate = date.toLocaleString(undefined, { year: 'numeric', month: 'short', day: 'numeric'});
        console.log(formattedDate);
        return 'ahsd;'
        // return date.toLocaleString(undefined, { year: 'numeric', month: 'short', day: 'numeric'});
    };

    // Render loading indicator or handle the loading state
    if (loading) {
        return (
            <>
                <Heading>{props.data.shortname}</Heading>
                <View style={styles.loadingContainer}> 
                    <ActivityIndicator size="large" color={globals.lightGray} />
                </View>
            </>
        );  
    }

    return (
        <>
            {props.day !== null && (
                <View style={[style.width.w90, style.margin.mt1_half, style.margin.mb1_quarter, props.first === false ? style.margin.mt2 : null]}>
                    <Heading style={[styles.h3, style.text.center, style.margin.mb0]}>{props.day}</Heading>
                </View>
            )}

            {/* {competitions && (
                
                <>
                    {competitions.map((competition, index) => (
                        <View style={[style.margin.mt1_half]}>

                            <View style={[style.flex.row, style.flex.jBetween, style.width.w100, style.position.relative, style.box.shadowDark, style.box.darkGray, style.box.round]}>
                                <View style={[style.padding.py1_half, style.width.w90]}>

                                    <View style={[style.flex.row, style.flex.jAround, style.flex.aCenter, style.padding.px1_half]}>
                                        <View style={[style.flex.row]}>
                                            <View style={[style.flex.column]}>
                                                {competitors.map((competitor, index) => (
                                                    <>{index === 0 && (
                                                        <Heading style={[style.text.h2, style.margin.mb0, style.text.center]}>{competitor.name}</Heading>
                                                    )}</>
                                                ))}
                                            </View>
                                        </View>
                                        <View style={[style.flex.column]}>
                                            <Heading style={[style.text.h6, style.text.center, style.margin.mb1]}>
                                                <Moment element={Text} format={'MMMM Do'}>{competition.date}</Moment>
                                            </Heading>
                                            {competition.court && competition.court.description ? (
                                                <Heading style={[style.text.h2, style.margin.mb0, style.padding.px1, style.text.center]}>{competition.court.description}</Heading>
                                            ) : (
                                                <Heading style={[style.text.h2, style.margin.mb0, style.padding.px1, style.text.center]}>TBD</Heading>
                                            )}
                                        </View>
                                        <View style={[style.flex.row]}>
                                            <View style={[style.flex.column]}>
                                                {competitors.map((competitor, index) => (
                                                    <>{index === 1 && (
                                                        <Heading style={[style.text.h2, style.margin.mb0, style.text.center]}>{competitor.name}</Heading>
                                                    )}</>
                                                ))}
                                            </View>
                                        </View>
                                    </View>
                                </View>
                            </View>
                        </View>
                    ))}
                </>
            )} */}

            {eventData && (
                
                <View style={[style.margin.mt1_half]}>

                    <View style={[style.flex.row, style.flex.jBetween, style.width.w100, style.position.relative, style.box.shadowDark, style.box.darkGray, style.box.round]}>
                        <View style={[style.padding.py1_half, style.width.w90]}>
                            {golf || mma || racing || tennis ? (
                                <View style={[style.flex.column, style.flex.jAround, style.flex.aCenter]}>
                                    <Heading style={[style.text.h6, style.text.center, style.margin.mb1]}>
                                        {eventStatus && eventStatus['type']['completed'] === true ? eventStatus['type']['shortDetail'] : props.data.time}
                                    </Heading>
                                    <Heading style={[style.text.h2, style.margin.mb0, style.padding.px1, style.text.center]}>{props.data.name}</Heading>
                                </View>
                            ) : (
                                <View style={[style.flex.row, style.flex.jAround, style.flex.aCenter, style.padding.px1_half]}>
                                    {isLoggedIn ? (
                                        <>
                                            <Pressable 
                                                onPress={() => setSelectedWinner('away')} 
                                                style={[style.width.w37_half, style.flex.jAround, style.flex.row, style.flex.aCenter, style.position.relative,
                                                selectedAwayToWin ? {borderWidth: 2, borderRadius: 4, borderColor: globals.green} : style.border.whiteDotted]}>
                                                <Image 
                                                    source={awayTeamLogo ? {uri: awayTeamLogo} : require('../../assets/img/MVPIQ-logo-empty_shield.png')}
                                                    resizeMode={'contain'}
                                                    style={{ width: 50, height: 50 }}
                                                />
                                                <View style={[style.flex.column, style.flex.jAround, style.flex.aCenter]}>
                                                    <Heading style={[style.text.h2, style.margin.mb0, style.text.center]}>{awayTeam['abbreviation']}</Heading>
                                                    {eventStatus && eventStatus['type']['id'] !== '1' && awayTeamScore && (
                                                        <Heading style={[style.text.h2, style.margin.mb0, awayTeamScore['winner'] === true ? style.text.darkPaleGreen : style.text.gray]}>{awayTeamScore['value']}</Heading>
                                                    )}
                                                </View>
                                                {selectedAwayToWin && (
                                                    <View style={[style.position.absolute, {top: -12, right: -12}]}>
                                                        <Icons.MaterialCommunityIcons name={'check-circle'} size={24} color={globals.green} />
                                                    </View>
                                                )}
                                            </Pressable>
                                        </>
                                    ) : (
                                        <View style={[style.flex.std, style.flex.row, style.flex.jAround, style.flex.aCenter]}>
                                            <Image 
                                                source={awayTeamLogo ? {uri: awayTeamLogo} : require('../../assets/img/MVPIQ-logo-empty_shield.png')}
                                                resizeMode={'contain'}
                                                style={{ width: 50, height: 50 }}
                                            />
                                            <View style={[style.flex.column, style.flex.centerAll]}>
                                                <Heading style={[style.text.h2, style.margin.mb0, style.text.center]}>{awayTeam['abbreviation']}</Heading>
                                                {eventStatus && eventStatus['type']['id'] !== '1' && awayTeamScore && (
                                                    <Heading style={[style.text.h2, style.margin.mb0, awayTeamScore['winner'] === true ? style.text.darkPaleGreen : style.text.gray]}>{awayTeamScore['value']}</Heading>
                                                )}
                                            </View>
                                        </View>
                                    )}
                                    
                                    <View style={[style.width.w25, style.flex.centerAll, style.flex.column]}>
                                        <Heading style={[style.text.h2, style.margin.mb0]}>@</Heading>
                                        {eventStatus && (
                                            <>
                                                {eventStatus['type']['id'] === '1' ? (
                                                    <Heading style={[style.text.h6, style.text.center, style.margin.my0]}>{props.data.time}</Heading>
                                                ) : (
                                                    <Heading style={[style.text.h6, style.text.center, style.margin.my0, style.text.lightGray]}>{eventStatus['type']['shortDetail']}</Heading>
                                                )}
                                            </>
                                        )}
                                    </View>

                                    {isLoggedIn ? (
                                        <Pressable 
                                            onPress={() => setSelectedWinner('home')} 
                                            style={[style.width.w37_half, style.flex.jAround, style.flex.row, style.flex.aCenter, 
                                            selectedHomeToWin ? {borderWidth: 2, borderRadius: 4, borderColor: globals.green} : style.border.whiteDotted]}>
                                            <View style={[style.flex.column, style.flex.jAround, style.flex.aCenter]}>
                                                <Heading style={[style.text.h2, style.margin.mb0, style.text.center]}>{homeTeam['abbreviation']}</Heading>
                                                {eventStatus && eventStatus['type']['id'] !== '1' && homeTeamScore && (
                                                    <Heading style={[style.text.h2, style.margin.mb0, homeTeamScore['winner'] === true ? style.text.darkPaleGreen : style.text.gray]}>{homeTeamScore['value']}</Heading>
                                                )}
                                            </View>
                                            <Image 
                                                source={homeTeamLogo ? {uri: homeTeamLogo} : require('../../assets/img/MVPIQ-logo-empty_shield.png')}
                                                resizeMode={'contain'}
                                                style={{ width: 50, height: 50 }}
                                            />
                                            {selectedHomeToWin && (
                                                <View style={[style.position.absolute, {top: -12, right: -12}]}>
                                                    <Icons.MaterialIcons name={'check-circle'} size={24} color={globals.green} />
                                                </View>
                                            )}
                                        </Pressable>
                                    ) : (
                                        <View style={[style.flex.std, style.flex.row, style.flex.jAround, style.flex.aCenter]}>
                                            <View style={[style.flex.column, style.flex.jAround, style.flex.aCenter]}>
                                                <Heading style={[style.text.h2, style.margin.mb0, style.text.center]}>{homeTeam['abbreviation']}</Heading>
                                                {eventStatus && eventStatus['type']['id'] !== '1' && homeTeamScore && (
                                                    <Heading style={[style.text.h2, style.margin.mb0, homeTeamScore['winner'] === true ? style.text.darkPaleGreen : style.text.gray]}>{homeTeamScore['value']}</Heading>
                                                )}
                                            </View>
                                            <Image 
                                                source={homeTeamLogo ? {uri: homeTeamLogo} : require('../../assets/img/MVPIQ-logo-empty_shield.png')}
                                                resizeMode={'contain'}
                                                style={{ width: 50, height: 50 }}
                                            />
                                        </View>
                                    )}
                                </View>
                            )}

                        </View>

                        <View style={[style.width.w10, style.position.relative, style.box.rightCap, style.overflow.hidden]}>
                            <Btn onPress={eventExpanded} style={[style.flex.std, style.flex.centerAll, style.width.w100, style.height.h100, style.btn.solidPink]}>
                                <Icons.MaterialCommunityIcons name={eventVisibility ? 'chevron-up' : 'chevron-down'} size={25} color={globals.white} />
                            </Btn>
                        </View>
                    </View>
                </View>
            )}

            {eventVisibility && (
                <>
                    {competitors && competitors.length > 0 ? (
                        <><EventExpanded event={event} competitions={competitions} competitors={competitors} day={props.day} name={event.name} sport={props.sport} league={props.league} /></>
                    ) : (
                        <><EventExpanded event={event} away={awayTeam} awayLogo={awayTeamLogo} home={homeTeam} homeLogo={homeTeamLogo} sport={props.sport} league={props.league} /></>
                    )}
                </>
            )}
        </>
    );
}

export const EventExpanded = (props: any) => {
    const [loading, setLoading] = React.useState(true);
    const [awayStats, setAwaySats] = React.useState(undefined);
    const [homeStats, setHomeStats] = React.useState(undefined);
    const nba = props.event.$ref.includes('/nba/');
    const nfl = props.event.$ref.includes('/nfl/');
    const wnba = props.event.$ref.includes('/wnba/');
    console.log(props)
    React.useEffect(() => {

        try {
            
            const fetchStats = async () => {
                if (props.away) {
                    const fetchStats = await fetch(props.away.statistics.$ref);
        
                    if (fetchStats) {
                        const fetchStatsResponse = await fetchStats.json();
                        setAwaySats(fetchStatsResponse.splits.categories);
                    }
                }
        
                if (props.home) {
                    const fetchStats = await fetch(props.home.statistics.$ref);
        
                    if (fetchStats) {
                        const fetchStatsResponse = await fetchStats.json();
                        setHomeStats(fetchStatsResponse.splits.categories);
                    }
                }
            }

            fetchStats();
            
        } catch (error) {
            console.log(error);
            throw error;
        } finally {
            setLoading(false);
        }

    }, [props.event]);

    if (loading) {
        return (
            <>
                <Heading>{props.sport}</Heading>
                <View style={styles.loadingContainer}> 
                    <ActivityIndicator size="large" color={globals.lightGray} />
                </View>
            </>
        );
    }

    return (
        <>
            {props.event && (
                
                <View style={[style.flex.column, style.position.relative, style.box.darkGray, style.box.bottomCap, style.margin.mtNegative1, style.padding.pt2, style.padding.px1, style.padding.pb1, style.position.zIndexNegative1]}>

                    {homeStats && awayStats && (
                        <ComparisonStats away={props.away} awayStats={awayStats} home={props.home} homeStats={homeStats} />
                    )}

                    {props.away &&  awayStats && (
                        <TeamStats data={awayStats} team={props.away} />
                    )}

                    {props.home && homeStats && (
                        <TeamStats data={homeStats} team={props.home} />
                    )}

                    {props.competitions ? (
                        <>
                            {props.competitions.map((item: any, i: number) => {
                                return (
                                    <Competition key={i} data={item} />
                                );
                            })}
                        </>
                    ) : (
                        <>
                            {nfl ? (
                                <SimResults 
                                    homeTeam={props.home} 
                                    homeTeamLogo={props.homeLogo} 
                                    // homeTeamStats={homeStats}
                                    awayTeam={props.away} 
                                    awayTeamLogo={props.awayLogo} 
                                    // awayTeamStats={awayStats}
                                    gameID={props.event.id} 
                                    // predictions={props.predictions}
                                    // summary={props.summary}
                                    // numSims={numSims}
                                    // showPlays={showPlays}
                                    // awayPlayStyle={awayPlayStyle}
                                    // homePlayStyle={homePlayStyle} 
                                    />
                            ) : (
                                <EventSimResults
                                    awayTeam={props.away}
                                    awayTeamLogo={props.awayLogo}
                                    awayTeamStats={awayStats}
                                    homeTeam={props.home} 
                                    homeTeamLogo={props.homeLogo}
                                    homeTeamStats={homeStats}
                                    eventData={props.event}
                                    sport={props.sport}
                                    league={props.league}
                                />
                            )}
                        </>
                    )}
                </View>
            )}
        </>
    )
}
export interface BaseballStats {
    gamesPlayed: number,
    runsBattedIn: number,
    hits: number, 
}
export const ComparisonStats = (props: any) => {
    const [awayStats, setAwayStats] = React.useState(undefined);
    const [homeStats, setHomeStats] = React.useState(undefined);
    const [awayGamesPlayed, setAwayGamesPlayed] = React.useState();
    const [homeGamesPlayed, setHomeGamesPlayed] = React.useState(undefined);
    const [statsExpanded, setStatsExpanded] = React.useState(false);

    React.useEffect(() => {
        var awayStats = [];
        var homeStats = [];
        var totalAwayGames;
        var totalHomeGames;
        var comparisonStats = {
            awayStats: [],
            homeStats: []
        }

        props.awayStats.map((awayStat) => {

            if (awayStat.name === 'batting') {

                awayStat.stats.map((stat) => {

                    if (stat.name === 'gamesPlayed') {
                        setAwayGamesPlayed(stat.value);
                    }
                
                    if (stat.name === 'gamesPlayed' || stat.name === 'RBIs' || stat.name === 'hits' || stat.name === 'runs' || stat.name === 'homeRuns' || stat.name === 'avg' || stat.name === 'OPS' || stat.name === 'runsRatio' || stat.name === 'MLBRating') {
                        comparisonStats.awayStats.push({
                            abbreviation: stat.abbreviation,
                            description: stat.description,
                            displayName: stat.displayName,
                            displayValue: stat.displayvalue,
                            name: stat.name,
                            rank: stat.rank,
                            shortDisplayName: stat.shortDisplayName,
                            value: stat.value
                        })
                    }
                })
            }
        });

        props.homeStats.map((homeStat) => {

            if (homeStat.name === 'batting') {

                homeStat.stats.map((stat) => {

                    if (stat.name === 'gamesPlayed') {
                        setHomeGamesPlayed(stat.value);
                    }
                
                    if (stat.name === 'gamesPlayed' || stat.name === 'RBIs' || stat.name === 'hits' || stat.name === 'runs' || stat.name === 'homeRuns' || stat.name === 'avg' || stat.name === 'OPS' || stat.name === 'runsRatio' || stat.name === 'MLBRating') {
                        comparisonStats.homeStats.push({
                            abbreviation: stat.abbreviation,
                            description: stat.description,
                            displayName: stat.displayName,
                            displayValue: stat.displayvalue,
                            name: stat.name,
                            rank: stat.rank,
                            shortDisplayName: stat.shortDisplayName,
                            value: stat.value
                        })
                    }
                })
            }
        });

        // comparisonStats.awayStats.map((awayStat) => {
        //     if (awayStat.name !== 'gamesPlayed' && awayGamesPlayed) {
        //         awayStat.value / awayGamesPlayed;
        //         console.log(awayStat.name, ' ', awayStat.value);
        //     }
        // });

        // comparisonStats.homeStats.map((homeStat) => {
        //     if (homeStat.name !== 'gamesPlayed' && awayGamesPlayed) {
        //         homeStat.value / awayGamesPlayed;
        //         console.log(homeStat.name, ' ', homeStat.value);
        //     }
        // });

        console.log(comparisonStats);
    }, []);

    const expandedStatsDropdown = () => {
        LayoutAnimation.configureNext(LayoutAnimation.Presets.easeInEaseOut);
        setStatsExpanded(!statsExpanded);
    }

    return (
        <>
            {awayStats && homeStats && (

                <View style={[style.box.xDarkGray, style.box.round, style.margin.mb1, style.padding.p1_half]}>

                    

                </View>
                
            )}
        </>
    )
}

export const TeamStats = (props: any) => {
    const [statsExpanded, setStatsExpanded] = React.useState(false);

    const expandedStatsDropdown = () => {
        LayoutAnimation.configureNext(LayoutAnimation.Presets.easeInEaseOut);
        setStatsExpanded(!statsExpanded);
    }

    return (
        <>
            {props.team && (

                <View style={[style.box.xDarkGray, style.box.round, style.margin.mb1, style.padding.p1_half]}>

                    <Pressable onPress={expandedStatsDropdown} style={[style.flex.row, style.flex.jBetween, style.flex.aCenter]}>
                        <View style={[style.padding.py1_quarter]}>
                            <Heading style={[style.text.h2, style.margin.mb0]}>{props.team.name} Stats</Heading>
                        </View>
                        <View>
                            <Icons.MaterialCommunityIcons name={statsExpanded === true ? 'chevron-up-circle' : 'chevron-down-circle'} color={globals.white} size={24} />
                        </View>
                    </Pressable>

                    {statsExpanded && (
                        <>
                            <View style={[style.padding.py1_half]}>
                                <Divider color={globals.gray} />
                            </View>

                            <View style={[style.padding.px1_half]}>
                                <>
                                    {props.data.map((item, index) => (
                                        <View key={index} style={[style.margin.mb1_half]}>
                                            <AccordionComponent 
                                                title={item.displayName} 
                                                content={
                                                    <UnorderedList2Col 
                                                        data={item.stats} 
                                                        bottomDivider={true}
                                                        labelString={'displayName'} 
                                                        valueString={'displayValue'} />
                                                } 
                                            />
                                        </View>
                                    ))}
                                </>
                            </View>
                        </>
                    )}

                </View>
                
            )}
        </>
    )
}

export const Competition = (props: any) => {
    const [loading, setLoading] = React.useState(true);
    const [competitionExpanded, setCompetitionExpanded] = React.useState(false);
    const [competitors, setCompetitors] = React.useState([]);

    React.useEffect(() => {

        if (props.data.competitors) {

            const fetchCompetitor = async () => {

                try {
                    var tmpCompetitorArray = [];
                
                    props.data.competitors.map(async (competitor: any) => {
                        const fetchAthlete = await fetch(competitor.athlete.$ref);
                        const fetchAthleteResponse = await fetchAthlete.json();
                        
                        if (fetchAthleteResponse) {
                            tmpCompetitorArray.push(fetchAthleteResponse);
                        }
                    });
    
                    setCompetitors(tmpCompetitorArray);
    
                } catch (error) {
                    console.log(error);
                } finally {
                    setLoading(false);
                }
                
            }
            fetchCompetitor();
        }

    }, [props.data.competitors]);

    const expandedCompetitionDropdown = () => {
        LayoutAnimation.configureNext(LayoutAnimation.Presets.easeInEaseOut);
        setCompetitionExpanded(!competitionExpanded);
    }

    // Render loading indicator or handle the loading state
    if (loading) {
        return (
            <>
                <Heading>{props.data.shortname}</Heading>
                <View style={styles.loadingContainer}> 
                    <ActivityIndicator size="large" color={globals.lightGray} />
                </View>
            </>
        );
    }

    return (
        <View style={[style.box.xDarkGray, style.box.round, style.margin.mb1, style.padding.p1_half, style.flex.column]}>

            <Pressable onPress={expandedCompetitionDropdown} style={[style.flex.row, style.flex.jBetween, style.flex.aCenter]}>
                <View style={[style.padding.py1_quarter]}>
                    {props.data.type ? (
                        <Heading style={[style.text.h2, style.margin.mb0]}>{props.data.type.text}</Heading>
                    ) : (
                        <Heading style={[style.text.h2, style.margin.mb0]}>{new Date(props.data.date).toLocaleDateString('en-US', { month: 'long', day: 'numeric' })}</Heading>
                    )}
                </View>
                <View>
                    <Icons.MaterialCommunityIcons name={competitionExpanded === true ? 'chevron-up-circle' : 'chevron-down-circle'} color={globals.white} size={24} />
                </View>
            </Pressable>

            {competitionExpanded && (
                <View style={[style.padding.px1_half]}>

                    <Divider style={style.margin.my1} color={globals.gray} />

                    {props.data.links && props.data.links.length > 0 && (
                        <>
                            <AccordionComponent
                                title={'Links'}
                                titleStyle={[style.text.h3, style.margin.mb0]}
                                content={
                                    <View style={[style.padding.py1_half]}>
                                        <Divider style={style.margin.mb1_half} color={globals.gray}/>
                                        {props.data.links.map((link: any, i: number) => {
                                            return (
                                                <View key={i} style={[style.padding.py1_half]}>
                                                    <A key={i} style={[style.text.white, style.text.bold, style.text.underline]} href={link.href} target="_blank">{link.text}</A>
                                                </View> 
                                            )
                                        })}
                                    </View>
                                } 
                            />
                        </>
                    )}
                    {competitors && competitors.length > 0 && (
                        <>
                            <AccordionComponent 
                                title={'Competitors'}
                                titleStyle={[style.text.h3, style.margin.mb0]}
                                content={
                                    <View style={[style.padding.py1_half]}>
                                        <Divider style={style.margin.mb1_half} color={globals.gray}/>
                                        {competitors.map((competitor: any, i: number) => {
                                            return (
                                                <View key={i} style={[i !== 0 ? style.margin.mt1_half : null]}>                                                    
                                                    <AccordionComponent
                                                        title={competitor.displayName}
                                                        titleStyle={[style.text.h4, style.margin.mb0]}
                                                        content={
                                                            <View style={[style.padding.py1_half]}>
                                                                {competitor.links && (
                                                                    <View style={[style.padding.px1_half]}>
                                                                        {competitor.links.map((link: any, j: number) => {
                                                                            return (
                                                                                <View key={j}>
                                                                                    <A key={j} style={[style.text.white, style.text.bold, style.text.underline]} href={link.href} target="_blank">{link.text}</A>
                                                                                </View> 
                                                                            )
                                                                        })}
                                                                    </View>
                                                                )}
                                                            </View>
                                                        } 
                                                    />
                                                </View>
                                            )
                                        })}
                                    </View>
                                } 
                            />
                        </>
                    )}
                </View>
            )}
        </View>
    )
}

export const EventSimResults = (props: any) => {
    const awayStat = props.awayTeamStats;
    const homeStat = props.homeTeamStats;
    const [avgAwayScore, setAvgAwayScore] = React.useState();
    const [avgHomeScore, setAvgHomeScore] = React.useState();
    const [scores, setScores] = React.useState({});

    React.useEffect(() => {
        getResults();
        if (props.league === 'wnba') {
            setScores(generateScores(85, 85, 15));
        } else if (props.league === 'nhl') {
            setScores(generateScores(2.75, 2.75, 2));
        } else if (props.league === 'mlb') {
            setScores(generateScores(6, 6, 2));
        } else if (props.league === 'mls') {
            setScores(generateScores(3.5, 3.5, 1));
        }
    }, []);

    const getResults = () => {
        if (props.sport === 'basketball') {
            if (props.league === 'wnba') {
                setScores(generateScores(85, 85, 15));
            } else {
                setScores(generateScores(100, 100, 25));
            }
        } else if (props.sport === 'hockey') {
            setScores(generateScores(2.75, 2.75, 2));
        } else if (props.sport === 'baseball') {
            setScores(generateScores(6, 6, 2));
        } else if (props.sport === 'soccer') {
            setScores(generateScores(2, 2, 1));
        }
    }

    return (
        <View style={[style.box.xDarkGray, style.box.round, style.padding.p1]}>
            {props.eventData ? (
                <>
                    <View style={[style.flex.column, {position: 'relative', zIndex: 2}]}>
                        <View style={[style.flex.std, style.flex.row, style.flex.jBetween, style.flex.aCenter, style.margin.my1]}>
                            <View style={[styles.w20, style.flex.row, style.flex.jStart, style.flex.aEnd]}>
                                {props.awayTeamLogo && (
                                    <Image 
                                        source={{uri: props.awayTeamLogo}}
                                        resizeMode={'contain'}
                                        style={{ width: 25, height: 25 }}
                                    />
                                )}
                                
                            </View>
                            <View style={[styles.w60, style.flex.centerAll, style.flex.row]}>
                                {props.numSims > 0 ? (
                                    <View style={[style.flex.column]}>
                                        <Heading style={[style.text.center]}>Sim Results</Heading>
                                        <Text style={[style.text.white]}>(avg of {props.numSims} simulations)</Text>
                                    </View>
                                ) : (
                                    <Heading style={[style.text.center]}>Sim Results</Heading>
                                )}
                                
                            </View>
                            <View style={[styles.w20, style.flex.row, style.flex.jEnd, style.flex.aEnd]}>
                                {props.homeTeamLogo && (
                                    <Image 
                                        source={{uri: props.homeTeamLogo}}
                                        resizeMode={'contain'}
                                        style={{ width: 25, height: 25 }}
                                    />
                                )}
                            </View>
                        </View>

                        {/* {props.eventData.map((item, i) => {
                            <View key={i} style={[style.margin.mb2, style.flex.column, styles.w100]}>
                                <View style={[style.flex.row, style.flex.jBetween, style.flex.aCenter, styles.w100, style.margin.mb1_half]}>
                                    <Text style={[{marginBottom: 0, color: 'white'}]}>{item.awayValue}</Text>
                                    <Heading style={styles.h4}>{item.heading}</Heading>
                                    <Text style={[{color: 'white'}]}>{item.homeValue}</Text>
                                </View>
                                <View style={[style.flex.row, styles.w100,]}>
                                    <View style={[{height: 4, width: item.awayPercentage, backgroundColor: '#' + props.awayTeam.color, paddingRight: 4}]}></View>
                                    <View style={[{height: 4, width: item.homePercentage, backgroundColor: '#' + props.homeTeam.color, paddingLeft: 4}]}></View>
                                </View>
                            </View>
                        })}; */}

                        <View style={[style.flex.row, style.flex.jBetween]}>
                            <View style={[styles.w50, style.flex.column, style.flex.centerAll]}>
                                <View style={[style.position.relative]}>
                                    <Image 
                                        source={{uri: props.awayTeamLogo}}
                                        resizeMode={'contain'}
                                        style={{width: 75, height: 75}}
                                    />
                                    {scores['teamAScore'] > scores['teamBScore'] && (
                                        <View style={[{left: -32, borderWidth: 2, borderColor: globals.pink}, style.position.top, style.border.round, style.bg.black, style.box.shadow, style.padding.p1_half]}>
                                            <Icons.MaterialCommunityIcons name={'trophy'} color={globals.pink} size={32} />
                                        </View>
                                    )}
                                </View>
                                <Heading  style={[{fontSize: 46}, style.margin.mt1_half, style.margin.mb0]}>{scores['teamAScore'] ? scores['teamAScore'] : 'A Number'}</Heading>
                            </View>
                            
                            <View style={[styles.w50, style.flex.column, style.flex.centerAll]}>
                                <View style={[style.position.relative]}>
                                    <Image 
                                        source={{uri: props.homeTeamLogo}}
                                        resizeMode={'contain'}
                                        style={{width: 75, height: 75}}
                                    />
                                    {scores['teamAScore'] < scores['teamBScore']&& (
                                        <View style={[{left: -32, borderWidth: 2, borderColor: globals.pink}, style.position.top, style.border.round, style.bg.black, style.box.shadow, style.padding.p1_half]}>
                                            <Icons.MaterialCommunityIcons name={'trophy'} color={globals.pink} size={32} />
                                        </View>
                                    )}
                                </View>
                                <Heading style={[{fontSize: 46}, style.margin.mt1_half, style.margin.mb0]}>{scores['teamBScore']? scores['teamBScore']: 'A Number'}</Heading>
                            </View>
                        </View>

                        <View style={[style.margin.mt1]}>
                            <Pressable onPress={() => getResults()} style={[style.border.round4, style.bg.darkPink, style.padding.py1_half]}>
                                <Icons.FontAwesome5 name={'play'} size={32} color={globals.white} style={style.text.center} />
                            </Pressable>
                        </View>
                    </View>
                </>
            ) : (
                <View>
                    <Paragraph>Unable to retrieve game data.</Paragraph>
                </View>
            )}
        </View>
    )
}

export const LeagueArticles = (props: any) => {
    const [loading, setLoading] = React.useState(true);
    const [data, setData] = React.useState([]);
    const [league, setLeague] = React.useState([]);
    const [leagueLogo, setLeagueLogo] = React.useState([]);
    const start = moment().add(-4, 'm');
    const [liked, setLiked] = React.useState(false);
    const [comments, setComments] = React.useState([]);
    const [commentsDisplay, setCommentsDisplay] = React.useState(false);

    React.useEffect(() => {
        const fetchArticles = async () => {
            
            try {
                const response = await axios.get(props.url + '?limit=' + props.limit);
                
                if (response.status === 200) {
                    const articles = response.data.articles;
                    setData(articles);
                }

            } catch (error) {
                console.log(error);
            } finally {
                setLoading(false);
            }
        }
        fetchArticles();

        const getLeague = async () => {

            try {
                const leagueFetch = await fetch(props.leagueURL);
                const leagueData = await leagueFetch.json();

                if (leagueData) {
                    setLeague(leagueData);
                    setLeagueLogo(leagueData.logos[0].href);
                }
            } catch (error) {
                console.log(error);
                throw error;
            } finally {
                setLoading(false);
            }
        }
        getLeague();
    }, []);

    // Render loading indicator or handle the loading state
    if (loading) {
        return (
            <>
                <Heading>{props.sport}</Heading>
                <View style={styles.loadingContainer}> 
                    <ActivityIndicator size="large" color={globals.lightGray} />
                </View>
            </>
        );
    }
   
    return (
        <>
            <View style={[style.flex.row, style.flex.jBetween, style.flex.aCenter, style.padding.p1_half]}>
                <Heading style={[style.text.h2, style.margin.mb0]}>{props.title ? props.title : league['shortName']}</Heading>
                {leagueLogo && (
                    <View>
                        <Image 
                            source={leagueLogo}
                            resizeMode={'contain'}
                            style={{ width: 50, height: 50 }}
                        />
                    </View>
                )}
                
            </View>
            
            <Divider style={styles.mb1} width={2} color={globals.lightGray} />
            {data.map((item, index) => (

                <View key={index}>
                    {item && index !== data.length - 1 ? (
                        <Article data={item} />
                    ) : (
                        <Article data={item} style={style.margin.mb1}  />
                    )}
                </View>

            ))}
        </>
    )
}