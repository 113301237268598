// Get dependencies
import * as React from 'react';
import { Text, View } from 'react-native';
import { Divider } from '@rneui/themed';

// Get custom
import { globals } from '../../lib/globals';
import { style } from '../../lib/styles';
import { Paragraph } from '../../shared/ContentItems';

export const UnorderedList = (props) => {

    return (
        <View style={{padding: 12, flex: 1, flexDirection: 'column'}}>
            {props.data.map((item, i) => (
                <View key={i}>
                    <View style={[globals.isWeb === true ? null : style.flex.std, props.data.length < i ? {paddingBottom: 8} : null, 
                        {flex: 1, flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'flex-start', paddingBottom: 8}]}>
                        <Paragraph style={{marginRight: 8, marginBottom: 0, fontWeight: 'bold'}}>{'\u2022'}</Paragraph>
                        <Paragraph style={{marginBottom: 0}}>{item}</Paragraph>
                    </View>
                    {props.bottomDivider === true && (
                        <Divider color={globals.gray} style={{marginBottom: 8}}/>
                    )}
                </View>
            ))}
        </View>
    );
}

export const UnorderedList2Col = (props) => {

    return (
        <View style={{padding: 12, flex: 1, flexDirection: 'column'}}>
            {props.data.map((item, i) => (
                <View key={i}>
                    <View style={[globals.isWeb === true ? null : style.flex.std, props.data.length < i ? {paddingBottom: 8} : null, 
                        {flex: 1, flexDirection: 'row', justifyContent: 'space-between', alignItems: 'flex-start'}]}>
                        {props.bullets == true ? (
                            <View>
                                <Text>{'\u2022'}</Text>
                                <Paragraph style={{marginRight: 8, marginBottom: 0, fontWeight: 'bold'}}>{props.labelString ? item[props.labelString] : item.label}</Paragraph>
                            </View>
                        ) : (
                            <Paragraph style={{marginBottom: 0}}>{props.labelString ? item[props.labelString] : item.label}</Paragraph>
                        )}
                        <View>
                            <Paragraph style={{marginBottom: 0}}>{props.valueString ? item[props.valueString] : item.value}</Paragraph>
                        </View>
                    </View>
                    {props.bottomDivider === true && (
                        <>
                            {i !== props.data.length - 1 ? (
                                <Divider color={props.dividerColor ? props.dividerColor : globals.lightGray}  style={{marginVertical: 8}}/>
                            ) : (
                                null
                            )}
                        </>
                    )}
                </View>
            ))}
        </View>
    );
}