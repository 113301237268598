import * as React from 'react';
import {
    Text,
    View,
    Image,
    Pressable,
    LayoutAnimation,
    UIManager,
    Platform } from 'react-native';
import { Divider } from '@rneui/themed';
import { A } from '@expo/html-elements';
import * as Icons from '@expo/vector-icons';
import Moment from 'react-moment';

// Get Custom
import { globals } from '../../lib/globals';
import { styles, style } from '../../lib/styles';
import { Heading, Paragraph, Btn } from '../../shared/ContentItems';
import { useUser } from '../../shared/services/user.service';

const Article = (props) => {
    const [liked, setLiked] = React.useState(false);
    const [comments, setComments] = React.useState([]);
    const [commentsDisplay, setCommentsDisplay] = React.useState(false);    
   
    const expandCommentsDropdown = () => {
        LayoutAnimation.configureNext(LayoutAnimation.Presets.easeInEaseOut);
        setCommentsDisplay(!commentsDisplay);
    }
    
    return (
        <>
            {props.data && (
                <>
                    <Heading style={[styles.h3, style.margin.mb1_half]}>{props.data.headline}</Heading>
                    <Text style={[{fontStyle: 'italic', color: '#999999', marginBottom: 12}]}><Moment element={Text} fromNow>{props.data.published}</Moment></Text>
                    <View style={[styles.mb2, style.box.darkGray, style.box.round, style.padding.p0]}>
                        <View>
                            <Image 
                                source={{
                                    uri: props.data.images.length > 0 ? props.data.images[0].url : 'https://gg.dwd.work/assets/images/MVPIQ-Colors_placeholder.jpg',
                                }}
                                resizeMode={'cover'}
                                style={{ width: '100%', height: 250 }} 
                            />
                            
                            <View style={[style.padding.py1_half, style.padding.px1]}>                                
                                <View style={[style.flex.row, style.flex.jBetween, style.flex.aCenter, style.padding.py1_half]}>
                                    <View style={[style.flex.row]}>
                                        <Pressable onPress={() => setLiked(!liked)} style={[style.padding.px1_quarter, style.margin.mr1]}>
                                            <Icons.MaterialCommunityIcons name={liked ? 'cards-heart' : 'cards-heart-outline'} size={24} color={globals.white} />
                                        </Pressable>

                                        <Pressable onPress={expandCommentsDropdown} style={[style.padding.px1_quarter, style.margin.mr1]}>
                                            <Icons.MaterialCommunityIcons name={'comment-multiple-outline'} size={24} color={globals.white} />
                                        </Pressable>

                                        <A href={props.data.links.web.href} style={[style.padding.px1_quarter]}>
                                            <Icons.MaterialCommunityIcons name={'share'} size={24} color={globals.white} />
                                        </A>
                                    </View>
                                    <View>
                                        <Pressable style={[style.padding.px1_quarter, style.margin.mr1]}>
                                            <Icons.MaterialCommunityIcons name={'content-save'} size={24} color={globals.white} />
                                        </Pressable>
                                    </View>
                                </View>
                            </View>
                        </View>

                        {commentsDisplay && (
                            <View style={[style.padding.p1, {minHeight: 100}]}>
                                <Divider style={style.margin.mb1} color={globals.white} />
                                <Heading style={style.margin.mb1}>Comments will go here!</Heading>
                                <Paragraph>There are none for now, but check back soon.</Paragraph>
                            </View>
                        )}
                    </View>
                </>
            )}
        </>
    )
}

export default Article;