import * as React from 'react';
import {
	Image,
	StyleSheet,
	View,
	Text, 
	SectionList } from 'react-native';
import { Divider } from '@rneui/themed';
// import Icon from 'react-native-vector-icons/MaterialCommunityIcons';
// import { Icon } from 'react-native-elements';
import * as Icons from '@expo/vector-icons';

// Get custom
import { globals } from '../../lib/globals';
import { styles, style } from '../../lib/styles';
import { Heading, Paragraph, GoToButton, ListItemWithIcon } from '../../shared/ContentItems';
import { NFLGameExample } from '../NFLGame';
import { EventExample } from '../Events/EventExample';
import GrayBox from '../ui/GrayBox';
import OrderedList from '../ui/OrderedList';

export default function AppBasics({navigation}) {

    const whatDoesItDoList = [
		{
			title: 'Simulate outcomes for each matchup',
			iconName: 'whistle'
		},
		{
			title: 'Uses each team\'s current stats, pulled from official APIs',
			iconName: 'cloud-sync',
		},
		{
			title: 'Displays real-time stats & scores for each matchup',
			iconName: 'scoreboard',
		},
		{
			title: 'Lists most recent articles for in-depth researching & analysis',
			iconName: 'newspaper-variant',
		},
        {
            title: 'Use all the available info to amplify your sports IQ and become a true MVP!',
            iconName: 'trophy',
            iconColor: globals.pink,
            textStyle: {
                color: globals.pink,
                fontWeight: 'bold'
            }
        }
	];

    return (
        <>        
            <View style={[style.list.uo, style.margin.mb2]}>
				{/* <GrayBox textColor={globals.lightPink}>
					<Paragraph>Signup for an account and get access to unique features immediately!</Paragraph>
				</GrayBox> */}
                <View style={style.margin.mb1}>
                    {
                        whatDoesItDoList.map((item, i) => (
                            <View key={i}>
                                <View style={[style.list.ulItem]}>
                                    <View>
                                        {/* <Icon name={item.iconName} size={24} color={item.iconColor ? item.iconColor : globals.white} iconStyle={style.margin.mr1} type={item.type ? item.type : 'material-community'}/> */}
                                        <Icons.MaterialCommunityIcons name={item.iconName} size={24} color={item.iconColor ? item.iconColor : 'white'} style={style.margin.mr1} />
                                    </View>
                                    <View style={[styles.w90]}>
                                        <Paragraph style={[item.textStyle ? item.textStyle : style.text.white, style.margin.mb0]}>{item.title}</Paragraph>
                                    </View>
                                </View>
                            </View>
                        ))
                    }
                </View>
                <Heading style={style.text.h4}>Simulation Explained</Heading>
                <ListItemWithIcon icon={'numeric-1-circle'} text={'Select your predicted winner by clicking within the dotted lines for each game'} />
                <ListItemWithIcon icon={'numeric-2-circle'} text={'Clicking the dropdown reveals simulation results and additional details about each game.'} />
                <EventExample navigation={navigation} />
            </View>
        
            <View>
                <GoToButton navigation={navigation} screenName={'Dashboard'} text={'Check out full Simulator!'} />
            </View>
        </>
    );
}