import * as React from 'react';
import { auth } from './firebase.db.service';

const UserContext = React.createContext();

export default function UserProvider({children}) {
    const [session, setSession] = React.useState({user: null});

    React.useEffect(() => {
        const unsubFromFbStateChange = auth.onAuthStateChanged(user => {
            setSession({user});
        });

        return () => unsubFromFbStateChange();
    }, []);

    return (
        <UserContext.Provider value={session}>{children}</UserContext.Provider>
    )
}

export const useUser = () => React.useContext(UserContext);