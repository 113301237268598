import * as React from 'react';
import * as Animatable from 'react-native-animatable';
import axios from 'axios';
import { 
    View, 
    Text, 
    Image,
    Pressable,
    TextInput,
    Modal,
    ScrollView,
    LayoutAnimation,
    UIManager,
    Platform,
    StyleSheet } from 'react-native';
import Animated, {
    useSharedValue,
    withTiming,
    useAnimatedStyle,
    Easing } from 'react-native-reanimated';
import { Divider } from '@rneui/themed';
import { Icon } from 'react-native-elements';
import * as Icons from '@expo/vector-icons';
import { A } from '@expo/html-elements';
import { Dropdown } from 'react-native-element-dropdown';
import Checkbox from 'expo-checkbox';
import GestureRecognizer from 'react-native-swipe-gestures';
import NumericInput from 'react-native-numeric-input';

// Get custom
import { simulationScript } from '../../lib/simulationScript';
import { globals } from '../../lib/globals';
import { styles, style } from '../../lib/styles';
import { Heading, Btn, Paragraph, ListItemWithIcon } from '../../shared/ContentItems';
import { UnorderedList2Col } from '../ui/UnorderedList';
import { AccordionComponent } from '../ui/AccordionComponent';
import { SimResults } from '../NFL/SimResults';
import { NFLGameOddsModal } from '../NFLGameOdds';
import { NFLArticlesForMatchup } from '../NFLData';
import Article from '../NFL/Article';

export const EventExample = ({navigation}) => {
    const [input1, setInput1] = React.useState('');
	const [input2, setInput2] = React.useState('');
    const [gameData, setGameData] = React.useState(null);
    const [selectedAwayToWin, setSelectedAwayToWin] = React.useState(false);
    const [selectedHomeToWin, setSelectedHomeToWin] = React.useState(false);
    const [gameExpanded, setGameExpanded] = React.useState(false);
    const [teamStatsVisibility, setTeamStatsVisibility] = React.useState(false);
    const [simulationResultsVisibility, setSimulationResultsVisibility] = React.useState(true);

    let homeName = 'home';
    let awayName = 'away';

    const getResults = () => {
        setGameData(simulationScript(homeName, null, awayName, null));
    }

    const generalStatsList = [
        {
            label: 'Avg Stat 1:',
            value: '46%',
        },
        {
            label: 'Avg Stat 2',
            value: '54%',
        },
        {
            label: 'Avg Stat 3:',
            value: '65.3%',
        },
        {
            label: 'Avg Stat 4:',
            value: '4.1 Yards',
        },
        {
            label: 'Avg Stat 5:',
            value: '7.6 Yards',
        },
        {
            label: 'Avg Rating:',
            value: '53.1',
        }
    ];

    const simulationSettings = [
        {
            label: 'Number of Simulations:',
            value: '1',
        },
        {
            label: 'Show Each Play:',
            value: 'O',
        },
        {
            label: 'Home Play Style:',
            value: 'Average',
        },
        {
            label: 'Away Play Style:',
            value: 'Average',
        }
    ];

    const setSelectedWinner = (choice) => {
        if (choice === 'away') {
            setSelectedHomeToWin(false);
            setSelectedAwayToWin(!selectedAwayToWin);
        } else if (choice === 'home') {
            setSelectedHomeToWin(!selectedHomeToWin);
            setSelectedAwayToWin(false);
        }
    }

    const expandGameDropdown = () => {
        LayoutAnimation.configureNext(LayoutAnimation.Presets.easeInEaseOut);
        getResults();
        setGameExpanded(!gameExpanded);
    }

    const collapsiblesTeamStats = () => {
        LayoutAnimation.configureNext(LayoutAnimation.Presets.easeInEaseOut);
        setTeamStatsVisibility(!teamStatsVisibility);
    }

    const collapsibleSimulationResults = () => {
        LayoutAnimation.configureNext(LayoutAnimation.Presets.easeInEaseOut);
        setSimulationResultsVisibility(!simulationResultsVisibility);
    }

    return (
        <View style={[{zIndex: 3, position: 'relative'}]}>

            <View style={{zIndex: 9}}>

                <View style={[style.flex.row, style.flex.jBetween, style.width.w100, style.position.relative, style.box.shadowDark]}>
                    <View style={[style.box.darkGray, style.box.leftCap, style.padding.p1_half, style.width.w90]}>
                        <View style={[style.flex.row, style.flex.jAround, style.flex.aCenter]}>
                            {/* Away Team */}
                            <View style={[selectedAwayToWin ? {borderWidth: 2, borderRadius: 4, borderColor: globals.green} : style.border.whiteDotted]}>
                                <Pressable 
                                    style={[style.flex.row, style.flex.aCenter, style.padding.py1_quarter, style.padding.px1]} 
                                    onPress={() => setSelectedWinner('away')}>
                                        <Icons.MaterialCommunityIcons name={'soccer'} size={40} color={globals.blue} />
                                        <Heading style={[style.padding.pl1_half]}>AWAY</Heading>
                                        
                                        {selectedAwayToWin && (
                                            <View style={[style.position.absolute, {top: -12, right: -12}]}>
                                                <Icons.MaterialCommunityIcons name={'check-circle'} size={24} color={globals.green} />
                                            </View>
                                        )}
                                </Pressable>
                            </View>
                            <Heading>@</Heading>
                            {/* Home Team */}
                            <View style={[selectedHomeToWin ? {borderWidth: 2, borderRadius: 4, borderColor: globals.green} : style.border.whiteDotted]}>
                                <Pressable 
                                    style={[style.flex.row, style.flex.aCenter, style.padding.py1_quarter, style.padding.px1]} 
                                    onPress={() => setSelectedWinner('home')}>
                                        <Heading style={[style.padding.pr1_half]}>HOME</Heading>
                                        <Icons.MaterialCommunityIcons name={'soccer'} size={40} color={globals.purple} />
                                        {selectedHomeToWin && (
                                            <View style={[style.position.absolute, {top: -12, right: -12}]}>
                                                <Icons.MaterialCommunityIcons name={'check-circle'} size={24} color={globals.green} />
                                            </View>
                                        )}
                                </Pressable>
                            </View>
                        </View>

                    </View>

                    <View style={[style.width.w10, style.position.relative, style.box.rightCap, style.overflow.hidden]}>
                        {/* <LinearGradient start={{x: 0, y: 0}} end={{x: 1, y: 0}} colors={[globals.purple, globals.pink]} style={gradient.linearGradient}> */}
                        <Btn onPress={expandGameDropdown} style={[style.flex.std, style.flex.centerAll, style.btn.solidPink, style.width.w100, style.height.h100]}>
                            {gameExpanded ? 
                                (
                                    <Icons.MaterialCommunityIcons name='chevron-up' size={24} color={globals.white} />
                                ) : (
                                    <Icons.MaterialCommunityIcons name='chevron-down' size={24} color={globals.white} />
                                )
                            }
                        </Btn>
                        {/* </LinearGradient> */}
                    </View>
                </View>

            </View>

            {gameExpanded && (
                <>
                    {gameData && (
                            
                        <View style={[style.padding.p1, style.box.darkGray, style.box.bottomCap, style.box.shadow, {position: 'relative', marginTop: -4, zIndex: 0}]}>

                            <ListItemWithIcon icon={'numeric-3-circle'} text={'Expand dropdowns to see stats used for each team'} />

                            <Pressable onPress={collapsiblesTeamStats} style={[style.flex.row, style.flex.jBetween, style.flex.aCenter, style.border.bottomLightGray]}>
                                <View style={[style.padding.py1_quarter, style.padding.px1, style.box.xDarkGray, style.box.topCap]}>
                                    <Heading style={[style.text.h2, style.margin.mb0]}>Team Stats</Heading>
                                </View>
                                <View>
                                    <Icons.MaterialCommunityIcons name={teamStatsVisibility === true ? 'chevron-up-circle' : 'chevron-down-circle'} color={globals.white} size={24} />
                                </View>
                            </Pressable>

                            {teamStatsVisibility === true && (
                                <View style={[style.padding.p1, style.box.xDarkGray, style.box.bottomCap]}>
                                    <View style={[style.padding.p1_half, style.margin.mb1]}>
                                        <AccordionComponent title={'Away Stats Used'} content={<UnorderedList2Col data={generalStatsList} bottomDivider={true} />} />
                                    </View>

                                    <View style={[style.padding.p1_half]}>
                                        <AccordionComponent title={'Home Stats Used'} content={<UnorderedList2Col data={generalStatsList} bottomDivider={true} />} />
                                    </View>

                                    <ListItemWithIcon icon={'numeric-4-circle'} text={'Expand settings dropdown to change execution settings'} />

                                    <View style={[style.padding.p1_half, style.margin.mb1]}>
                                        <AccordionComponent title={'Simulation Settings'} content={<UnorderedList2Col data={simulationSettings} bottomDivider={true} />} />
                                    </View>
                                </View>
                            )}

                            <Pressable onPress={collapsibleSimulationResults} style={[style.flex.row, style.flex.jBetween, style.flex.aCenter, style.border.bottomLightGray, style.margin.mt1]}>
                                <View style={[style.padding.py1_quarter, style.padding.px1, style.box.xDarkGray, style.box.topCap]}>
                                    <Heading style={[style.text.h2, style.margin.mb0]}>Simulation Results</Heading>
                                </View>
                                <View>
                                    <Icons.MaterialCommunityIcons name={simulationResultsVisibility === true ? 'chevron-up-circle' : 'chevron-down-circle'} color={globals.white} size={24} />
                                </View>
                            </Pressable>

                            {simulationResultsVisibility === true && (
                                <View style={[style.padding.p1, style.box.xDarkGray, style.box.bottomCap]}>
                                    <View style={[style.flex.column, {position: 'relative', zIndex: 2}]}>
                                        <View style={[style.margin.mb2, style.flex.column, styles.w100]}>
                                            <View style={[style.flex.centerAll, style.flex.row, style.margin.mb1]}>
                                                <View>
                                                    <Heading style={[styles.h3, style.text.center]}>Sim Results</Heading>
                                                </View>
                                            </View>
                                            <View style={[style.flex.row, style.flex.jBetween, style.flex.aCenter, styles.w100, style.margin.mb1_half]}>
                                                <Text style={[{marginBottom: 0, color: 'white'}]}>{gameData.awayStats.totalYards}</Text>
                                                <Heading style={styles.h4}>All Stats</Heading>
                                                <Text style={[{marginBottom: 0, color: 'white'}]}>{gameData.homeStats.totalYards}</Text>
                                            </View>
                                            <View style={[style.flex.row, styles.w100,]}>
                                                <View style={[{height: 4, width: gameData.awayStats.totalYardShare, backgroundColor: globals.blue, paddingRight: 4}]}></View>
                                                <View style={[{height: 4, width: gameData.homeStats.totalYardShare, backgroundColor: globals.purple, paddingLeft: 4}]}></View>
                                            </View>
                                        </View>

                                        <View style={[style.margin.mb2, style.flex.column, styles.w100]}>
                                            <View style={[style.flex.row, style.flex.jBetween, style.flex.aCenter, styles.w100, style.margin.mb1_half]}>
                                                <Text style={[{marginBottom: 0, color: 'white'}]}>{gameData.awayStats.totalPassingYards}</Text>
                                                <Heading style={styles.h4}>Stats 1</Heading>
                                                <Text style={[{marginBottom: 0, color: 'white'}]}>{gameData.homeStats.totalPassingYards}</Text>
                                            </View>
                                            <View style={[style.flex.row, styles.w100,]}>
                                                <View style={[{height: 4, width: gameData.awayStats.totalPassingYardShare, backgroundColor: globals.blue, paddingRight: 4}]}></View>
                                                <View style={[{height: 4, width: gameData.homeStats.totalPassingYardShare, backgroundColor: globals.purple, paddingLeft: 4}]}></View>
                                            </View>
                                        </View>

                                        <View style={[style.margin.mb2, style.flex.column, styles.w100]}>
                                            <View style={[style.flex.row, style.flex.jBetween, style.flex.aCenter, styles.w100, style.margin.mb1_half]}>
                                                <Text style={[{marginBottom: 0, color: 'white'}]}>{gameData.awayStats.totalRunningYards}</Text>
                                                <Heading style={styles.h4}>Stats 2</Heading>
                                                <Text style={[{marginBottom: 0, color: 'white'}]}>{gameData.homeStats.totalRunningYards}</Text>
                                            </View>
                                            <View style={[style.flex.row, styles.w100,]}>
                                                <View style={[{height: 4, width: gameData.awayStats.totalRunningYardShare, backgroundColor: globals.blue, paddingRight: 4}]}></View>
                                                <View style={[{height: 4, width: gameData.homeStats.totalRunningYardShare, backgroundColor: globals.purple, paddingLeft: 4}]}></View>
                                            </View>
                                        </View>

                                        <View style={[style.margin.mb1, style.flex.column, styles.w100]}>
                                            <View style={[style.flex.row, style.flex.jBetween, style.flex.aCenter, styles.w100, style.margin.mb1_half]}>
                                                <Text style={[{marginBottom: 0, color: 'white'}]}>{gameData.awayStats.averageYPP}</Text>
                                                <Heading style={styles.h4}>Stats 3</Heading>
                                                <Text style={[{marginBottom: 0, color: 'white'}]}>{gameData.homeStats.averageYPP}</Text>
                                            </View>
                                            <View style={[style.flex.row, styles.w100,]}>
                                                <View style={[{height: 4, width: gameData.awayStats.averageYPPShare, backgroundColor: globals.blue, paddingRight: 4}]}></View>
                                                <View style={[{height: 4, width: gameData.homeStats.averageYPPShare, backgroundColor: globals.purple, paddingLeft: 4}]}></View>
                                            </View>
                                        </View>

                                        <View style={[style.flex.centerAll]}>
                                            <Heading style={[styles.h1]}>{gameData.winner} Wins!</Heading>
                                        </View>

                                        <View style={[style.flex.row, style.flex.jBetween]}>
                                            <View style={[styles.w50, style.padding.p1_quarter, style.flex.centerAll]}>
                                                <Icons.MaterialCommunityIcons name={'soccer'} size={40} color={globals.blue}/>
                                                <Heading style={[{fontSize: 42}]}>{gameData.awayStats.score}</Heading>
                                                <Paragraph style={[style.margin.mb0]}>AWAY TEAM</Paragraph>
                                            </View>

                                            <View style={[styles.w50, style.padding.p1_quarter, style.flex.centerAll]}>
                                                <Icons.MaterialCommunityIcons name={'soccer'} size={40} color={globals.purple} />
                                                <Heading style={[{fontSize: 42}]}>{gameData.homeStats.score}</Heading>
                                                <Paragraph style={[style.margin.mb0]}>HOME TEAM</Paragraph>
                                            </View>
                                        </View>
                                    </View>

                                    <View style={[style.margin.mt1]}>
                                        <View style={[style.flex.centerAll]}>
                                            <ListItemWithIcon 
                                                icon={'numeric-5-circle'} 
                                                text={'Run additional simulations'} />
                                        </View>
                                        <Pressable onPress={() => getResults()} style={[style.border.round4, style.bg.darkPink, style.padding.py1_half]}>
                                            <Icons.FontAwesome5 name={'play'} size={32} color={globals.white} />
                                        </Pressable>
                                    </View>
                                </View>
                            )}

                            <View style={[style.flex.row, style.margin.mt1]}>
                                <View style={[style.width.w50, style.padding.pr1_half]}>
                                    <ListItemWithIcon 
                                        icon={'numeric-6-circle'} 
                                        text={'View associated articles'} />
                                    <Pressable onPress={() => navigation.navigate('News')} style={[style.border.round4, style.bg.darkPink, style.padding.py1_half]}>
                                        <Icons.MaterialCommunityIcons name={'newspaper-variant-multiple'} size={32} color={globals.white} />
                                    </Pressable>
                                </View>
                                <View style={[style.width.w50, style.padding.pl1_half]}>
                                    <ListItemWithIcon 
                                        icon={'numeric-7-circle'} 
                                        text={'View associated betting odds'} />
                                    <Pressable onPress={() => navigation.navigate('BettingTracker')} style={[style.border.round4, style.bg.darkPink, style.padding.py1_half]}>
                                        <Icons.MaterialCommunityIcons name={'dice-multiple'} size={32} color={globals.white} />
                                    </Pressable>
                                </View>
                            </View>
                        </View>
                    )}
                </>
            )}
        </View>
    )
}

const drop = StyleSheet.create({
    dropdown: {
        borderBottomColor: 'gray',
        borderBottomWidth: 0.5,
    },
    icon: {
        marginRight: 5,
    },
    placeholderStyle: {
        fontSize: 16,
        color: globals.white
    },
    selectedTextStyle: {
        fontSize: 16,
        color: globals.white,
    },
    iconStyle: {
        width: 20,
        height: 20,
    },
    inputSearchStyle: {
        height: 40,
        fontSize: 16,
    },
});