// Get dependencies
import { 
    StyleSheet,
    Dimensions,
    Platform,
    StatusBar } from 'react-native';
import Constants from 'expo-constants';
import axios from 'axios';

// Get custom
import SECRETS from './secrets';

const { expoGoConfig } = Constants;

export const globals = {
    // Colors
    darkBodyColor: '#08120d',
    screenBackgroundColor: '#1a1821',
    navigationBackgroundColor: '#173325',
    red: '#ff0000',
    pink: '#ff00aa',
    purple: '#b300d8',
    blue: '#009af9',
    green: '#b2ff39',
    yellow: '#ffd700',
    white: '#efe8ef',
    black: '#1d1d1d',
    gray: '#5c5b77',
    lightRed: '#ff6969',
    lightPink: '#fc5aa6', 
    lightPurple: '#b6adc4',
    lightBlue: '#00bfff',
    lightGreen: '#c9ffa9',
    lightGray: '#9595bc',
    xLightGray: '#bab3ba',
    darkRed: '',
    darkPink: '#b70485',
    darkPurple: '#820753',
    darkBlue: '#7415ff',
    darkGreen: '#095b00',
    darkPaleGreen: '#c9ffa9',
    darkGray: '#343147',
    xDarkGray: '#272335',
    grayGreen: '#b4bfa1',
    grayRed: '#aa8a8a',
    baseball: '#dfe1e5',
    basketball: '#ff8431',
    football: '#002ad8',
    golf: '#3ad600',
    hockey: '#83edff',
    lacrosse: '#930070',
    mma: '#ff0000',
    racing: '#424242',
    rugby: '#ffcc3e',
    soccer: '#9c02c1',
    tennis: '#d1ff5f',

    // Devices
    windowWidth: Dimensions.get('window').width,
    windowHeight: Dimensions.get('window').height,
    rem1: 16,
    rem2: 32,
    isMobile: Dimensions.get('window').width < 400 ? true : false,
    isTablet: Dimensions.get('window').width >= 400 && Dimensions.get('window').width < 768 ? true : false,
    isTabletLarge: Dimensions.get('window').width >= 768 && Dimensions.get('window').width < 992 ? true : false,
    isDesktop: Dimensions.get('window').width >= 992 && Dimensions.get('window').width <= 992 ? true : false,
    isWeb: Platform.OS === 'web' ? true : false,
    isiOS: Platform.OS === 'ios' ? true : false,
    isAndroid: Platform.OS === 'android' ? true : false,
    statusBarHeight: StatusBar.currentHeight || 0,
    tabBarHeight: 50,
    api: (typeof expoGoConfig.packagerOpts === `object`) && expoGoConfig.packagerOpts.dev
        ? expoGoConfig.debuggerHost.split(`:`).shift().concat(`:3000`)
        : '192.168.0.2:3000',
    newsAPIKEY: SECRETS.newsAPIKEY,

    // Base ESPN API URL
    base_espn_api_url: SECRETS.base_espn_api_url,

    // American Football
    espn_american_football_leagues: SECRETS.espn_american_football_leagues,
    espn_american_football_nfl: SECRETS.espn_american_football_nfl,
    espn_american_football_nfl_news: SECRETS.espn_american_football_nfl_news,
    NFLTeamsAPIURL: SECRETS.NFLTeamsAPIURL,
    NFLTeamDataAPIURL: SECRETS.NFLTeamDataAPIURL,
    NFLNewsAPIURL: SECRETS.NFLNewsAPIURL,
    NFLWeeklyScheduleAPIURL: SECRETS.NFLWeeklyScheduleAPIURL,
    NFLTeamGeneralInfoURL: SECRETS.NFLTeamGeneralInfoURL,
    NFLEventsURL: SECRETS.NFLEventsURL,
    ESPNEventSummaryAPIURLNFL: SECRETS.ESPNEventSummaryAPIURLNFL,
    ESPNScoreboardAPIURLNFL: SECRETS.ESPNScoreboardAPIURLNFL,
    PERSONAL_BettingCompaniesListAPIURL: SECRETS.PERSONAL_BettingCompaniesListAPIURL,
    NBAEventsURL: SECRETS.NBAEventsURL,
    NBATeamsURL: SECRETS.NBATeamsURL,
    NFLPostSeasonAPIURL: SECRETS.NFLPostSeasonAPIURL,

    // Australian Football
    espn_australian_football_leagues: SECRETS.espn_australian_football_leagues,
    espn_australian_football_afl: SECRETS.espn_australian_football_afl,

    // Baseball
    espn_baseball_leagues: SECRETS.espn_baseball_leagues,
    espn_baseball_college: SECRETS.espn_baseball_college,
    espn_baseball_college_softball: SECRETS.espn_baseball_college_softball,
    espn_baseball_llb: SECRETS.espn_baseball_llb,
    espn_baseball_mlb: SECRETS.espn_baseball_mlb,
    espn_baseball_mlb_news: SECRETS.espn_baseball_mlb_news,
    espn_baseball_world: SECRETS.espn_baseball_world,

    // Basketball
    espn_basketball_leagues: SECRETS.espn_basketball_leagues,
    espn_basketball_fiba: SECRETS.espn_basketball_fiba,
    espn_basketball_mens_college: SECRETS.espn_basketball_mens_college,
    espn_basketball_mens_college_news: SECRETS.espn_basketball_mens_college_news,
    espn_basketball_nba: SECRETS.espn_basketball_nba,
    espn_basketball_nba_news: SECRETS.espn_basketball_nba_news,
    espn_basketball_nbl: SECRETS.espn_basketball_nbl,
    espn_basketball_wnba: SECRETS.espn_basketball_wnba,
    espn_basketball_wnba_news: SECRETS.espn_basketball_wnba_news,
    espn_basketball_womens_college: SECRETS.espn_basketball_womens_college,
    espn_basketball_womens_college_news: SECRETS.espn_basketball_womens_college_news,

    // Golf
    espn_golf_leagues: SECRETS.espn_golf_leagues,
    espn_golf_champions_tour: SECRETS.espn_golf_champions_tour,
    espn_golf_eur: SECRETS.espn_golf_eur,
    espn_golf_liv: SECRETS.espn_golf_liv,
    espn_golf_lgpa: SECRETS.espn_golf_lgpa,
    espn_golf_ntw: SECRETS.espn_golf_ntw,
    espn_golf_pga: SECRETS.espn_golf_pga,

    // Hockey
    espn_hockey_leagues: SECRETS.espn_hockey_leagues,
    espn_hockey_world_cup: SECRETS.espn_hockey_world_cup,
    espn_hockey_nhl: SECRETS.espn_hockey_nhl,
    espn_hockey_nhl_news: SECRETS.espn_hockey_nhl_news,
    espn_hockey_college_men: SECRETS.espn_hockey_college_men,
    espn_hockey_college_women: SECRETS.espn_hockey_college_women,

    // Lacrosse
    espn_lacrosse_leagues: SECRETS.espn_lacrosse_leagues,
    espn_lacrosse_mens_college: SECRETS.espn_lacrosse_mens_college,
    espn_lacrosse_pll: SECRETS.espn_lacrosse_pll,
    espn_lacrosse_college_women: SECRETS.espn_lacrosse_college_women,

    // MMA
    espn_mma_leagues: SECRETS.espn_mma_leagues,
    espn_mma_ufc: SECRETS.espn_mma_ufc,
    espn_mma_bellator: SECRETS.espn_mma_bellator,
    espn_mma_big_fight: SECRETS.espn_mma_big_fight,
    espn_mma_m1: SECRETS.espn_mma_m1,
    espn_mma_pfl: SECRETS.espn_mma_pfl,
    espn_mma_ringside: SECRETS.espn_mma_ringside,
    espn_mma_rizin: SECRETS.espn_mma_rizin,
    espn_mma_shark_fights: SECRETS.espn_mma_shark_fights,
    espn_mma_shooto_japan: SECRETS.espn_mma_shooto_japan,

    // Racing
    espn_racing_leagues: SECRETS.espn_racing_leagues,
    espn_racing_f1: SECRETS.espn_racing_f1,
    espn_racing_irl: SECRETS.espn_racing_irl,
    espn_racing_nascar_premier: SECRETS.espn_racing_nascar_premier,
    espn_racing_nascar_secondary: SECRETS.espn_racing_nascar_secondary,
    espn_racing_nascar_truck: SECRETS.espn_racing_nascar_truck,
    espn_racing_nhra: SECRETS.espn_racing_nhra,

    // Rugby
    espn_rugby_leagues: SECRETS.espn_rugby_leagues,
    espn_rugby_league_nrl: SECRETS.espn_rugby_league_nrl,
    espn_rugby_world_cup: SECRETS.espn_rugby_world_cup,
    espn_rugby_six_nations: SECRETS.espn_rugby_six_nations,
    espn_rugby_eu_champions_cup: SECRETS.espn_rugby_eu_champions_cup,
    espn_rugby_top14: SECRETS.espn_rugby_top14,
    espn_rugby_urbatop12: SECRETS.espn_rugby_urbatop12,
    espn_rugby_super_rugby_pacific: SECRETS.espn_rugby_super_rugby_pacific,
    espn_rugby_african_car_championship: SECRETS.espn_rugby_african_car_championship,
    espn_rugby_asian_5_nations: SECRETS.espn_rugby_asian_5_nations,
    espn_rugby_fira_championship: SECRETS.espn_rugby_fira_championship,
    espn_rugby_irb_nations_cup: SECRETS.espn_rugby_irb_nations_cup,
    espn_rugby_irb_pacigic_nations_cup: SECRETS.espn_rugby_irb_pacigic_nations_cup,
    espn_rugby_world_cup_qualifier: SECRETS.espn_rugby_world_cup_qualifier,
    espn_rugby_tri_nations: SECRETS.espn_rugby_tri_nations,
    espn_rugby_tour: SECRETS.espn_rugby_tour,
    espn_rugby_womens_world_cup: SECRETS.espn_rugby_womens_world_cup,
    espn_rugby_major_league: SECRETS.espn_rugby_major_league,
    espn_rugby_americas_championship: SECRETS.espn_rugby_americas_championship,
    espn_rugby_summer_nations: SECRETS.espn_rugby_summer_nations,

    // Soccer
    espn_soccer_leagues: SECRETS.espn_soccer_leagues,
    espn_soccer_club_friendly: SECRETS.espn_soccer_club_friendly,
    espn_soccer_conmebol: SECRETS.espn_soccer_conmebol,
    espn_soccer_concacaf_league: SECRETS.espn_soccer_concacaf_league,
    espn_soccer_concacaf_league_cup: SECRETS.espn_soccer_concacaf_league_cup,
    espn_soccer_fifa_friendly: SECRETS.espn_soccer_fifa_friendly,
    espn_soccer_fifa_world: SECRETS.espn_soccer_fifa_world,
    espn_soccer_fifa_sheblelieves: SECRETS.espn_soccer_fifa_sheblelieves,
    espn_soccer_mex: SECRETS.espn_soccer_mex,
    espn_soccer_mex_2: SECRETS.espn_soccer_mex_2,
    espn_soccer_uefa_champions: SECRETS.espn_soccer_uefa_champions,
    espn_soccer_uefa_wchampions: SECRETS.espn_soccer_uefa_wchampions,
    espn_soccer_uefa_euro: SECRETS.espn_soccer_uefa_euro,
    espn_soccer_uefa_euro_news: SECRETS.espn_soccer_uefa_euro_news,
    espn_soccer_uefa_europa: SECRETS.espn_soccer_uefa_europa,
    espn_soccer_uefa_europa_conf: SECRETS.espn_soccer_uefa_europa_conf,
    espn_soccer_eng_1: SECRETS.espn_soccer_eng_1,
    espn_soccer_eng_w_1: SECRETS.espn_soccer_eng_w_1,
    espn_soccer_eng_2: SECRETS.espn_soccer_eng_2,
    espn_soccer_eng_3: SECRETS.espn_soccer_eng_3,
    espn_soccer_eng_4: SECRETS.espn_soccer_eng_4,
    espn_soccer_eng_5: SECRETS.espn_soccer_eng_5,
    espn_soccer_eng_fa: SECRETS.espn_soccer_eng_fa,
    espn_soccer_eng_league_cup: SECRETS.espn_soccer_eng_league_cup,
    espn_soccer_usa_1: SECRETS.espn_soccer_usa_1,
    espn_soccer_usa_nwsl: SECRETS.espn_soccer_usa_nwsl,
    espn_soccer_usa_open: SECRETS.espn_soccer_usa_open,
    espn_soccer_usa_usl_1: SECRETS.espn_soccer_usa_usl_1,
    espn_soccer_usa_usl_l1: SECRETS.espn_soccer_usa_usl_l1,
    espn_soccer_usa_usl_l1_cup: SECRETS.espn_soccer_usa_usl_l1_cup,
    espn_soccer_usa_1_news: SECRETS.espn_soccer_usa_1_news,
    // Tennis
    espn_tennis_leagues: SECRETS.espn_tennis_leagues,
    espn_tennis_atp: SECRETS.espn_tennis_atp,
    espn_tennis_wta: SECRETS.espn_tennis_wta,

    // Volleyball
    espn_volleyball_leagues: SECRETS.espn_volleyball_leagues,
    espn_volleyball_mens_college: SECRETS.espn_volleyball_mens_college,
    espn_volleyball_womens_college: SECRETS.espn_volleyball_womens_college,

    // Water Polo
    espn_water_polo_leagues: SECRETS.espn_water_polo_leagues,
    espn_water_polo_mens_college: SECRETS.espn_water_polo_mens_college,
    espn_water_polo_womens_college: SECRETS.espn_water_polo_womens_college,

    // Olympics
    espn_baseball_olympics: SECRETS.espn_baseball_olympics,
    espn_basketball_mens_olympics: SECRETS.espn_basketball_mens_olympics,
    espn_basketball_womens_olympics: SECRETS.espn_basketball_womens_olympics,
    espn_golf_mens_olympics: SECRETS.espn_golf_mens_olympics,
    espn_golf_womens_olympics: SECRETS.espn_golf_womens_olympics,
    espn_hockey_olympics_men: SECRETS.espn_hockey_olympics_men,
    espn_hockey_olympics_women: SECRETS.espn_hockey_olympics_women,
    espn_rugby_mens_olympics_7s: SECRETS.espn_rugby_mens_olympics_7s,
    espn_rugby_womens_olympics_7s: SECRETS.espn_rugby_womens_olympics_7s,
}

export const readCSV= async (url, hasHeaders = null) => {

    try {
        // Fetch the CSV file using Axios
        const response = await axios.get(url);

        // Check if the response status is OK
        if (response.status === 200) {
            const csvData = response.data;
            const lines = csvData.split('\n');
            const headers = lines[0].split(',');
            const data = [];
        
            for (let i = hasHeaders === true ? 1 : 0; i < lines.length; i++) {
                const line = lines[i];
                const values = line.split(',');
                const entry = {};
            
                for (let j = 0; j < headers.length; j++) {
                    const header = headers[j];
                    const value = values[j];
                    entry[header] = value;
                }
                data.push(entry);
            }

            return data;

        } else {
            console.error('Failed to fetch CSV:', response.status, response.statusText);
        }
    } catch (error) {
        console.error('Error fetching CSV:', error.message);
    }
}