import React from 'react';
import { 
	StyleSheet, 
	View, 
	Text, 
	Pressable,
	Platform,
	UIManager,
	LayoutAnimation } from 'react-native';
import { Icon } from 'react-native-elements';
import * as Icons from '@expo/vector-icons';

// Get custom
import { globals } from '../../lib/globals';
import { style } from '../../lib/styles';
import { Heading, Paragraph } from '../../shared/ContentItems';

if(Platform.OS === 'android') {
	UIManager.setLayoutAnimationEnabledExperimental(true);
}

export const AccordionComponent = (props: any) => {
	const [sections, setSections] = React.useState([]);
	const [activeSections, setActiveSections] = React.useState([]);
	const [visible, setVisible] = React.useState(false);

	const toggleVisible = () => {
		setVisible(!visible);
		LayoutAnimation.configureNext(LayoutAnimation.Presets.easeInEaseOut);
	}

	return (
		<View>

			<View style={[accordion.titleContainer]}>
				<Pressable onPress={toggleVisible} style={[style.flex.row, style.flex.aCenter, style.flex.jBetween, style.width.w100]}>
					<Heading style={props.titleStyle}>{props.title}</Heading>
					<Icons.MaterialCommunityIcons name={visible ? 'chevron-up-circle' : 'chevron-down-circle'} size={24} color={globals.white} />
				</Pressable>
			</View>

			{visible && (
				<>
					{props.content}
				</>
			)}

            
        </View>
	);
};

export const Accordion = ({ title, children }) => {
	const [isOpen, setIsOpen] = React.useState(false);
  
	const toggleOpen = () => {
		setIsOpen(value => !value);
		LayoutAnimation.configureNext(LayoutAnimation.Presets.easeInEaseOut);
	}
  
	return (
		<View style={accordion.container}>
			<Pressable onPress={toggleOpen}>{title}</Pressable>
			<View style={[styles.list, !isOpen ? styles.hidden : undefined]}>
				{children}
			</View>
		</View>
	);
};

const styles = StyleSheet.create({
	hidden: {
		height: 0,
	},
	list: {
		overflow: 'hidden'
	},
});

const accordion = StyleSheet.create({
	container: {
		flexDirection: 'column',
	},
	titleContainer: {
		flexDirection: 'row',
		justifyContent: 'space-between'
	},
	subItem: {
		paddingVertical: 8,
		paddingHorizontal: 12,
		flexDirection: 'row',
		alignItems: 'center',
		justifyContent: 'flex-start',
	},
	subItemAround: {
		width: '100%',
		paddingVertical: 1,
		paddingHorizontal: 4,
		flexDirection: 'row',
		alignItems: 'center',
		justifyContent: 'space-around',
	},
	subItemBetween: {
		width: '100%',
		paddingVertical: 1,
		paddingHorizontal: 4,
		flexDirection: 'row',
		alignItems: 'center',
		justifyContent: 'space-between',
	},
	subItemIcon: {
		marginRight: 8,
	},
	chevronOpen: {
		transform: [{ rotate: '90deg' }],
		alignSelf: 'flex-end',
	},
	chevronClosed: {
		transform: [{ rotate: '-90deg' }],
		alignSelf: 'flex-end',
	},
});
