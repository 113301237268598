// Get dependencies
import { 
    StyleSheet,
    Dimensions,
    Platform,
    StatusBar } from 'react-native';
import Constants from 'expo-constants';

// Get custom
import { globals } from './globals';

export const styles = StyleSheet.create({
    app: {
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        height: '100%',
    },
    screen: {
        backgroundColor: globals.screenBackgroundColor,
        color: 'white',
    },
    loadingContainer: {
        position: 'absolute',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        width: '100%',
        height: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: 'rgba(26,24,33, 0.5)'
    },
    lightGrayBackground: {
        backgroundColor: globals.lightGray,
    },
    screenContainer: {
        padding: 16,
    },
    section: {
        marginBottom: 16,
    },
    cardSection: {
        backgroundColor: 'white',
        padding: 12,
        borderRadius: 8,
        shadowColor: '#171717',
        shadowOffset: {width: -2, height: 4},
        shadowOpacity: 0.2,
        shadowRadius: 3,
    },
    flex: {
        display: 'flex',
    },
    content: {
        paddingVertical: 16,
    },
    bodyColor: {
        color: globals.white,
    },
    buttonColor: {
        color: globals.black
    },
    greenAccent: {
        color: globals.black
    },
    week: {
        flexDirection: 'row',
        marginVertical: 8,
        marginHorizontal: 16,
    },
    meta: {
        marginHorizontal: 8,
        justifyContent: 'center',
    },
    name: {
        fontWeight: 'bold',
        fontSize: 16,
        lineHeight: 24,
    },
    timestamp: {
        opacity: 0.5,
        fontSize: 14,
        lineHeight: 21,
    },
    avatar: {
        height: 48,
        width: 48,
        borderRadius: 24,
    },
    heading: {
        fontFamily: 'DIN',
        fontWeight: 'bold',
        fontSize: 24,
        color: globals.white
    },
    paragraph: {
        fontSize: 16,
        lineHeight: 24,
        marginBottom: 16,
        color: globals.white,
    },
    image: {
        width: '100%',
        height: 200,
        resizeMode: 'cover',
        marginVertical: 8,
    },
    code: {
        width: '100%',
        marginVertical: 12,
        fontFamily: 'Courier New',
        color: '#555555',
        fontStyle: 'italic',
    },
    h1: {
        fontSize: 24,
        fontWeight: 'bold',
        marginVertical: 12,
    }, 
    h2: {
        fontSize: 20,
        fontWeight: 'bold',
        marginTop: 4,
        marginBottom: 6,
    },
    h3: {
        fontSize: 18,
        fontWeight: 'bold',
    },
    h4: {
        fontSize: 16,
        fontWeight: 'bold',
    },
    h5: {
        fontSize: 14,
        marginBottom: 8,
    },
    h6: {
        fontSize: 12,
        fontWeight: 'bold',
        marginBottom: 12,
    },
    p: {
        marginBottom: 8,
    },
    normal: {
        fontWeight: 'normal',
    },
    bold: {
        fontWeight: 'bold',
    },
    underline: {
        textDecorationLine: 'underline',
    },
    li: {
        paddingLeft: 8,
    },
    btn: {
        borderColor: 'currentColor',
        borderWidth: 2,
        borderRadius: 4,
        paddingVertical: 8,
        paddingHorizontal: 24,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    btnText: {
        color: 'currentColor',
    },
    btnSecn: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'row',
        backgroundColor: globals.purple,
        borderRadius: 4,
        paddingVertical: 8,
        paddingHorizontal: 16,
    },
    btnSecnSub: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'row',
        backgroundColor: globals.darkPink,
        borderBottomRightRadius: 6,
        borderBottomLeftRadius: 6,
        paddingVertical: 8,
        paddingHorizontal: 16,
    },
    btnSecnText: {
        color: globals.black,
        fontWeight: 'bold',
        marginRight: 12,
    },
    mt1_half: {
        marginTop: 8,
    },
    mt1: {
        marginTop: 16,
    },
    mt2: {
        marginTop: 32,
    },
    mr1_half: {
        marginRight: 8,
    },
    mr1: {
        marginRight: 16,
    },
    mb1_half: {
        marginBottom: 8,
    },
    mb1: {
        marginBottom: 16,
    },
    mb2: {
        marginBottom: 32,
    },
    ml1_half: {
        marginLeft: 8,
    },
    ml1: {
        marginLeft: 16,
    },
    px1: {
        paddingHorizontal: 16,
    },
    px1_half: {
        paddingHorizontal: 8,
    },
    px1_quarter: {
        paddingHorizontal: 4,
    },
    boxShadow: {
        shadowColor: '#171717',
        shadowOffset: {width: -2, height: 4},
        shadowOpacity: 0.2,
        shadowRadius: 3,
    },
    darkText: {
        color: '#08081e',
    },
    alignLeft: {
        flex: 1,
        justifyContent: 'flex-start',
        alignItems: 'flex-start'
    },
    w100: {
        width: '100%',
    },
    mw100: {
        maxWidth: '100%',
    },
    w95: {
        width: '95%',
    },
    w90: {
        width: '90%',
    },
    mw90: {
        maxWidth: '90%',
    },
    w85: {
        width: '85%',
    },
    w80: {
        width: '80%',
    },
    mw80: {
        maxWidth: '80%',
    },
    w70: {
        width: '70%',
    },
    mw70: {
        maxWidth: '70%',
    },
    w60: {
        width: '60%',
    },
    mw60: {
        maxWidth: '60%',
    },
    w50: {
        width: '50%',
    },
    mw50: {
        maxWidth: '50%',
    },
    w40: {
        width: '40%',
    },
    mw40: {
        maxWidth: '40%',
    },
    w30: {
        width: '30%',
    },
    mw30: {
        maxWidth: '30%',
    },
    w20: {
        width: '20%',
    },
    mw20: {
        maxWidth: '20%',
    },
    w10: {
        width: '10%',
    },
    mw10: {
        maxWidth: '10%',
    },
    h100: {
        height: '100%',
    },
    mh100: {
        maxHeight: '100%',
    },
    h50: {
        height: '50%',
    },
    mh50: {
        maxHeight: '50%',
    },
    boxCard: {
        padding: 16,
        borderRadius: 4,
        borderRightWidth: 1,
        borderBottomWidth: 1,
        borderLeftWidth: 1, 
        borderColor: globals.black,
    },
    whiteBoxCard: {
        padding: 8,
        backgroundColor: '#ffffff',
        shadowOffset: {width: 0, height: 0},
        shadowOpacity: 0.15,
        shadowRadius: 14,
        textShadowRadius: 8,
        overflow: 'hidden',
    },
    whiteBoxCardRoundTop: {
        padding: 8,
        backgroundColor: '#ffffff',
        shadowOffset: {width: 0, height: 0},
        shadowOpacity: 0.15,
        shadowRadius: 14,
        textShadowRadius: 8,
        borderTopRightRadius: 6,
        borderTopLeftRadius: 6,
    },
    dropdown: {
        position: 'absolute',
        borderWidth: 1,
        borderColor: globals.black,
        borderRadius: 4,
    },
    dropdownBtn: {
        flexDirection: 'row',
        alignItems: 'center',
        height: 50,
        borderRadius: 6,
        paddingHorizontal: 10,
        zIndex: 1,
    },
    dropdownBtnText: {
        flex: 1,
        textAlign: 'center',
    },
    dropdownIcon: {
        marginRight: 10,
    },
    dropdownOverlay: {
        width: '100%',
        height: '100%',
    },
    dropdownItem: {
        paddingVertical: 2,
    },
    container: {
        flex: 1,
    },
    header: {
        paddingTop: 48,
        paddingBottom: 10,
        alignItems: 'center',
    },
    title: {
        fontSize: 22,
        fontWeight: 'bold',
    },
    panel: {
        paddingTop: 16,
        paddingHorizontal: 10,
    },
    label: {
        fontSize: 20,
    },
    dropdownListWrapper: { 
        borderWidth: 1,
        borderRadius: 10,
        borderColor: 'gray',
        paddingHorizontal: 20,
        paddingVertical: 12,
        flexDirection: 'row',
        justifyContent: 'space-between',
    },
    dropdownList: { 
        borderWidth: 1,
        borderRadius: 10,
        borderColor: 'gray',
        marginTop: 10,
        overflow: 'hidden',
    },
    dropdownListOption: { 
        paddingHorizontal:20,
        paddingVertical:8,
        overflow:'hidden',
    },
    dropdownListDisabledOption: { 
        paddingHorizontal: 20,
        paddingVertical: 8,
        flexDirection: 'row',
        alignItems: 'center', 
        backgroundColor: 'whitesmoke',
        opacity: 0.9,
    },
    teamHeader: {
        display: 'flex',
        flexDirection: 'row',
        paddingVertical: 8,
        justifyContent: 'space-between',
        alignItems: 'center',
    },
    teamInfo: {
        display: 'flex',
        flexDirection: 'column',
        paddingVertical: 8,
    },
    appHeaderStyle: {
        borderBottomColor: globals.purple,
        borderBottomWidth: 4,
    },
    appHeaderTitleStyle: {
        textAlign: 'center',
    },
});

export const box = StyleSheet.create({
    white: {
        backgroundColor: 'white',
    },
    bottomCap: {
        borderTopLeftRadius: 0,
        borderTopRightRadius: 0, 
        borderBottomRightRadius: 8,
        borderBottomLeftRadius: 8,
    },
    shadow: {
        shadowOffset: {width: 0, height: 0},
        shadowOpacity: 0.15,
        shadowRadius: 14,
        textShadowRadius: 8,
    }
})

export const style = {
    display: StyleSheet.create({
        flex: {
            display: 'flex',
        },
        none: {
            display: 'none',
        }
    }),

    position: StyleSheet.create({
        relative: {
            position: 'relative',
        },
        absolute: {
            position: 'absolute',
        },
        fill: {
            position: 'absolute',
            top: 0,
            right: 0,
            bottom: 0,
            left: 0,
        },
        top: {
            position: 'absolute',
            top: 0
        },
        topRight: {
            position: 'absolute',
            top: 0,
            right: 0,
        },
        topLeft: {
            position: 'absolute',
            top: 0,
            left: 0,
        },
        top1rem_quarter : {
            position: 'absolute',
            top: 2,
        },
        right: {
            position: 'absolute',
            right: 0,
        },
        right1rem: {
            position: 'absolute',
            right: 16,
        },
        right2rem: {
            position: 'absolute',
            right: 32,
        },
        bottom: {
            position: 'absolute',
            bottom: 0
        },
        bottomLeft: {
            position: 'absolute',
            bottom: 0,
            left: 0
        },
        bottomCenter: {
            position: 'absolute',
            bottom: 0,
            left: 0,
            right: 0
        },
        fullscreenUnderStatusBar: {
            top: globals.statusBarHeight,
            left: 0,
            right: 0,
            bottom: 0,
        },
        zIndex0: {
            zIndex: 0,
        },
        zIndexBottom: {
            zIndex: 0,
        },
        zIndex1: {
            zIndex: 1,
        },
        zIndexNegative1: {
            zIndex: -1,
        },
        zIndex2: {
            zIndex: 2,
        },
        zIndex3: {
            zIndex: 3,
        },
        zIndex4: {
            zIndex: 4,
        },
        zIndex5: {
            zIndex: 5,
        },
        zIndex6: {
            zIndex: 6,
        },
        zIndex7: {
            zIndex: 7,
        },
        zIndex8: {
            zIndex: 8,
        },
        zIndex9: {
            zIndex: 9,
        },
        zIndexTop: {
            zIndex: 9999999999,
        }
    }),

    text: StyleSheet.create({
        left: {
            textAlign: 'left',
        },
        right: {
            textAlign: 'right',
        },
        center: {
            textAlign: 'center',
        },
        white: {
            color: globals.white,
        },
        black: {
            color: globals.black,
        },
        gray: {
            color: globals.gray,
        },
        darkGray: {
            color: globals.darkGray,
        },
        lightGray: {
            color: globals.lightGray,
        },
        pink: {
            color: globals.pink,
        },
        purple: {
            color: globals.purple,
        },
        red: {
            color: globals.red,
        },
        yellow: {
            color: globals.yellow,
        },
        green: {
            color: globals.green,
        },
        darkGreen: {
            color: globals.darkGreen,
        },
        darkPaleGreen: {
            color: globals.darkPaleGreen,
        },
        lightGreen: {
            color: globals.lightGreen,
        },
        bold: {
            fontWeight: 'bold',
        },
        h1: {
            fontSize: 32,
            lineHeight: 40,
            fontWeight: 'bold',
            marginBottom: 18,
        },
        h2: {
            fontSize: 26,
            lineHeight: 32.5,
            fontWeight: 'bold',
            marginBottom: 13,
        },
        h3: {
            fontSize: 24,
            lineHeight: 32.5,
            fontWeight: 'bold',
            marginBottom: 13,
        },
        h4: {
            fontSize: 20,
            lineHeight: 30,
            fontWeight: 'bold',
            marginBottom: 15,
        },
        h5: {
            fontSize: 18,
            lineHeight: 22.5,
            fontWeight: 'bold',
            marginBottom: 9,
        },
        h6: {
            fontSize: 16,
            lineHeight: 20,
            fontWeight: 'bold',
        },
        futura: {
            fontFamily: 'Futura',
        },
        underline: {
            textDecorationLine: 'underline',
        },
        code: {
            width: '100%',
            marginVertical: 12,
            fontFamily: 'Courier New',
            color: '#555555',
            fontStyle: 'italic',
        },
        errorCode: {
            position: 'relative',
            width: '100%',
            paddingVertical: 4,
            paddingHorizontal: 8,
            marginVertical: 4,
            fontFamily: 'Courier New',
            color: globals.lightRed,
            fontStyle: 'italic',
        },
        successCode: {
            position: 'relative',
            width: '100%',
            padding: 4,
            marginVertical: 4,
            fontFamily: 'Courier New',
            color: globals.green,
            fontWeight: 'bold'
        }
    }),

    flex: StyleSheet.create({
        flex: {
            display: 'flex'
        },
        std: {
            display: 'flex',
            flex: 1,
        },
        none: {
            flex: 0,
        },
        row: {
            display: 'flex',
            flexDirection: 'row',
        },
        rowReverse: {
            display: 'flex',
            flexDirection: 'row-reverse',
        },
        column: {
            display: 'flex',
            flexDirection: 'column',
        },
        jAround: {
            justifyContent: 'space-around',
        },
        jCenter: {
            justifyContent: 'center',
        },
        jBetween: {
            justifyContent: 'space-between',
        },
        jStart: {
            justifyContent: 'flex-start',
        },
        jEnd: {
            justifyContent: 'flex-end',
        },
        aStart: {
            alignItems: 'flex-start',
        },
        aCenter : {
            alignItems: 'center',
        },
        aEnd: {
            alignItems: 'flex-end',
        },
        aStretch: {
            alignItems: 'stretch',
        },
        aSelfStretch: {
            alignSelf: 'stretch',
        },
        centerAll: {
            justifyContent: 'center',
            alignItems: 'center',
        },
        wrap: {
            flexWrap: 'wrap',
        },
        noWrap: {
            flexWrap: 'nowrap',
        },
        grow1: {
            flexGrow: 1,
        }
    }),

    input: StyleSheet.create({
        std: {
            borderWidth: 0,
            borderRadius: 4,
            paddingVertical: 6,
            paddingHorizontal: 8,
            marginBottom: 8,
            borderColor: globals.gray,
            backgroundColor: globals.white,
        },
        pw: {
            borderWidth: 0,
            borderRadius: 4,
            paddingVertical: 6,
            paddingLeft: 8,
            paddingRight: 32,
            marginBottom: 8,
            borderColor: globals.gray,
            backgroundColor: globals.white,
        },
        label: {
            fontWeight: 'bold',
            marginBottom: 8,
        },
        text: {
            color: globals.gray,
            fontStyle: 'italic',
        }
    }),

    link: StyleSheet.create({
        std: {
            color: globals.white,
            textDecorationLine: 'underline',
        }
    }),

    screen: StyleSheet.create({
        container: {
            maxWidth: 800,
            display: 'flex',
            flexDirection: 'column',
            paddingTop: 16,
            paddingHorizontal: 16,
            paddingBottom: 50,
            width: '100%',
            position: 'relative',
            zIndex: 0,
            marginLeft: 'auto',
            marginRight: 'auto',
        },
    }),

    bg: StyleSheet.create({
        none: {
            backgroundColor: 'rgba(0, 0, 0, 0.0)',
        },
        white: {
            backgroundColor: globals.white,
        },
        shadow: {
            backgroundColor: 'rgba(0, 0, 0, 0.65)',
        },
        gray: {
            backgroundColor: globals.gray,
        },
        black: {
            backgroundColor: globals.black,
        },
        pink: {
            backgroundColor: globals.pink,
        },
        purple: {
            backgroundColor: globals.purple,
        },
        red: {
            backgroundColor: globals.red,
        },
        green: {
            backgroundColor: globals.green,
        },
        yellow: {
            backgroundColor: globals.yellow,
        },
        lightGray: {
            backgroundColor: globals.lightGray,
        },
        darkGray: {
            backgroundColor: globals.darkGray,
        },
        xDarkGray: {
            backgroundColor: globals.xDarkGray,
        },
        darkPink: {
            backgroundColor: globals.darkPink,
        },
        lightPink: {
            backgroundColor: globals.lightPink,
        },
        darkPurple: {
            backgroundColor: globals.darkPurple,
        },
        lightPurple: {
            backgroundColor: globals.lightPurple,
        },
        
        lightGreen: {
            backgroundColor: globals.lightGreen,
        },
        darkGreen: {
            backgroundColor: globals.darkGreen,
        }
    }),

    padding: StyleSheet.create({
        p0: {
            padding: 0,
        },
        p1: {
            padding: 16,
        },
        p1_3quarter: {
            padding: 12,
        },
        p1_half: {
            padding: 8,
        },
        p1_quarter: {
            padding: 4,
        },
        px1: {
            paddingHorizontal: 16,
        },
        px_3quarter: {
            paddingHorizontal: 12,
        },
        px1_half: {
            paddingHorizontal: 8,
        },
        px1_quarter: {
            paddingHorizontal: 4,
        },
        py1: {
            paddingVertical: 16,
        },
        py1_half: {
            paddingVertical: 8,
        },
        py1_quarter: {
            paddingVertical: 4,
        },
        pt1: {
            paddingTop: 16,
        },
        pr1: {
            paddingRight: 16,
        },
        pr1_half: {
            paddingRight: 8,
        },
        pr1_quarter: {
            paddingRight: 4,
        },
        pb1: {
            paddingBottom: 16,
        },
        pb1_half: {
            paddingBottom: 8,
        },
        pb1_quarter: {
            paddingBottom: 4,
        },
        pl1: {
            paddingLeft: 16,
        },
        pl1_half: {
            paddingLeft: 8,
        },
        pl1_quarter: {
            paddingLeft: 4,
        },
        p2: {
            padding: 32,
        },
        py2: {
            paddingVertical: 32,
        },
        pt2: {
            paddingTop: 32,
        },
        pb2: {
            paddingBottom: 32,
        },
        p3: {
            padding: 48,
        },
        pb3: {
            paddingBottom: 48,
        },
        p4: {
            padding: 64,
        },
        ptSafeView: {
            paddingTop: globals.statusBarHeight,
        },
        pySafeView: {
            paddingTop: 65,
            paddingBottom: 50,
        }
    }),
    
    margin: StyleSheet.create({
        auto: {
            margin: 'auto',
        },
        m0: {
            margin: 0,
        },
        m1: {
            margin: 16,
        },
        m1_half: {
            margin: 8,
        },
        m1_quarter: {
            margin: 4,
        },
        mx0: {
            marginHorizontal: 0,
        },
        mx1: {
            marginHorizontal: 16,
        },
        my0: {
            marginVertical: 0,
        },
        my1: {
            marginVertical: 16,
        },
        my1_half: {
            marginVertical: 8,
        },
        my1_quarter: {
            marginVertical: 4,
        },
        mt0: {
            marginTop: 0,
        },
        mt1: {
            marginTop: 16,
        },
        mtNegative1: {
            marginTop: -16,
        },
        mt1_half: {
            marginTop: 8,
        },
        mt1_quarter: {
            marginTop: 4,
        },
        mr1: {
            marginRight: 16,
        },
        mr1_half: {
            marginRight: 8,
        },
        mr1_quarter: {
            marginRight: 4,
        },
        mb0: {
            marginBottom: 0,
        },
        mb1: {
            marginBottom: 16,
        },
        mb1_half: {
            marginBottom: 8,
        },
        mb1_quarter: {
            marginBottom: 4,
        },
        ml1: {
            marginLeft: 16,
        },
        ml1_half: {
            marginLeft: 8,
        },
        ml1_quarter: {
            marginLeft: 4,
        },
        m2: {
            margin: 32,
        },
        m2_half: {
            margin: 24,
        },
        m2_quarter: {
            margin: 4,
        },
        mx2: {
            marginHorizontal: 32,
        },
        my2: {
            marginVertical: 32,
        },
        my2_half: {
            marginVertical: 24,
        },
        my2_quarter: {
            marginVertical: 20,
        },
        mt2: {
            marginTop: 32,
        },
        mt3: {
            marginTop: 48,
        },
        mt4: {
            marginTop: 64,
        },
        mt2_half: {
            marginTop: 24,
        },
        mt2_quarter: {
            marginTop: 20,
        },
        mr2: {
            marginRight: 32,
        },
        mr2_half: {
            marginRight: 24,
        },
        mr2_quarter: {
            marginRight: 20,
        },
        mb2: {
            marginBottom: 32,
        },
        mb2_half: {
            marginBottom: 24,
        },
        mb2_quarter: {
            marginBottom: 20,
        },
        ml2: {
            marginLeft: 32,
        },
        ml2_half: {
            marginLeft: 24,
        },
        ml2_quarter: {
            marginLeft: 20,
        },
        m3: {
            margin: 48,
        },
        mb3: {
            marginBottom: 48,
        },
        m4: {
            margin: 64,
        }
    }),

    btn: StyleSheet.create({
        std: {
            paddingVertical: 16,
            paddingHorizontal: 24,
            borderRadius: 4,
            justifyContent: 'center',
            alignItems: 'center',
            flexDirection: 'row',
        },
        center: {
            justifyContent: 'center',
            alignItems: 'center',
            flexDirection: 'row',
        },
        primary: {
            backgroundColor: globals.pink,
        },
        solidPink: {
            backgroundColor: globals.pink,
        },
        solidDarkPink: {
            backgroundColor: globals.darkPink,
        },
        solidPurple: {
            backgroundColor: globals.purple,
        },
        outlinePrim: {
            borderWidth: 2,
            borderColor: globals.pink,
        },
        solidSec: {
            backgroundColor: globals.purple,
        },
        outlineSec: {
            borderWidth: 2,
            borderColor: globals.purple,
        },
        text: {
            color: globals.white,
            fontWeight: 'bold',
            margin: 0,
        },
        dropdown: {
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            flexDirection: 'row',
            width: '100%',
            paddingLeft: 16,
            borderRadius: 4,
            borderWidth: 2,
            borderColor: globals.pink,
        },
        dropdownBtn: {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100%',
            padding: 8,
            backgroundColor: globals.pink,
        },
        dropdownBtnText: {
            color: globals.white, 
            marginRight: 16, 
            fontWeight: 'bold'
        },
    }),
    
    bootstrap: StyleSheet.create({
        row: {
            display: 'flex',
            width: '100%',
            flexDirection: 'row',
            flexWrap: 'wrap',
        },
        col: {
            display: 'flex',
            paddingHorizontal: 8,
        },
        col12: {
            display: 'flex',
            width: '100%',
            paddingHorizontal: 8,
        },
        col11: {
            display: 'flex',
            width: '92%',
            paddingHorizontal: 8,
        },
        col10: {
            display: 'flex',
            width: '83%',
            paddingHorizontal: 8,
        },
        col9: {
            display: 'flex',
            width: '75%',
            paddingHorizontal: 8,
        },
        col8: {
            display: 'flex',
            width: '67%',
            paddingHorizontal: 8,
        },
        col7: {
            display: 'flex',
            width: '58%',
            paddingHorizontal: 8,
        },
        col6: {
            display: 'flex',
            width: '50%',
            paddingHorizontal: 8,
        },
        col5: {
            display: 'flex',
            width: '42%',
            paddingHorizontal: 8,
        },
        col4: {
            display: 'flex',
            width: '33%',
            paddingHorizontal: 8,
        },
        col3: {
            display: 'flex',
            width: '25%',
            paddingHorizontal: 8,
        },
        col2: {
            display: 'flex',
            width: '17%',
            paddingHorizontal: 8,
        },
        col1: {
            display: 'flex',
            width: '8%',
            paddingHorizontal: 8,
        },
    }),

    box: StyleSheet.create({
        white: {
            backgroundColor: 'white',
        },
        gray: {
            backgroundColor: globals.gray,
        },
        lightGray: {
            backgroundColor: globals.lightGray,
        },
        xLightGray: {
            backgroundColor: globals.xLightGray,
        },
        darkGray: {
            backgroundColor: globals.darkGray,
        },
        xDarkGray: {
            backgroundColor: globals.xDarkGray,
        },
        black: {
            backgroundColor: globals.black,
        },
        round: {
            borderTopLeftRadius: 8,
            borderTopRightRadius: 8,
            borderBottomRightRadius: 8,
            borderBottomLeftRadius: 8,
            overflow: 'hidden',
        },
        roundTop: {
            borderTopLeftRadius: 8,
            borderTopRightRadius: 8,
        },
        topCap: {
            borderTopLeftRadius: 8,
            borderTopRightRadius: 8, 
            borderBottomRightRadius: 0,
            borderBottomLeftRadius: 0,
        },
        rightCap: {
            borderTopLeftRadius: 0,
            borderTopRightRadius: 8, 
            borderBottomRightRadius: 8,
            borderBottomLeftRadius: 0,
        },
        bottomCap: {
            borderTopLeftRadius: 0,
            borderTopRightRadius: 0, 
            borderBottomRightRadius: 8,
            borderBottomLeftRadius: 8,
        },
        leftCap: {
            borderTopLeftRadius: 8,
            borderTopRightRadius: 0, 
            borderBottomRightRadius: 0,
            borderBottomLeftRadius: 8,
        },
        roundBorder: {
            borderRadius: 6,
        },
        shadow: {
            shadowOffset: {width: 0, height: 0},
            shadowOpacity: 0.15,
            shadowRadius: 14,
            textShadowRadius: 8,
        },
        shadowDark: {
            shadowOffset: {width: 0, height: 0},
            shadowOpacity: 0.45,
            shadowRadius: 14,
            textShadowRadius: 8,
        }
    }),

    form: StyleSheet.create({
        std: {
            display: 'flex',
            flex: 1,
            width: '100%',
            height: '100%',
        }
    }),

    list: StyleSheet.create({
        uo: {
            display: 'flex',
            flex: 1,
            flexDirection: 'column',
        },
        ulItem: {
            flex: 1,
            flexDirection: 'row',
            justifyContent: 'flex-start',
            alignItems: 'center',
            width: '100%',
            marginVertical: 8,
        },
        ulText: {
            flex: 1,
            flexWrap: 'wrap',
        }
    }),

    overflow: StyleSheet.create({
        hidden: {
            overflow: 'hidden',
        },
        visible: {
            overflow: 'visible',
        },
        scroll: {
            overflow: 'scroll',
        }
    }),

    border: StyleSheet.create({
        none: {
            borderWidth: 0,
        },
        std: {
            borderWidth: 1,
        },
        std2: {
            borderWidth: 2,
        },
        round: {
            borderRadius: 100,
        },
        round4: {
            borderRadius: 4,
        },
        round8: {
            borderRadius: 8,
        },
        roundContainer: {
            borderRadius: 8,
        },
        white: {
            borderWidth: 1,
            borderRadius: 4,
            borderColor: globals.white
        },
        whiteDotted: {
            borderWidth: 2,
            borderRadius: 4,
            borderStyle: 'dashed',
            borderColor: 'rgba(239, 232, 239, 0.45)',
        },
        pink: {
            borderWidth: 1,
            borderColor: globals.pink
        },
        gray: {
            borderWidth: 1,
            borderColor: globals.gray
        },
        darkGray: {
            borderWidth: 1,
            borderColor: globals.darkGray
        }, 
        bottomWhite: {
            borderBottomWidth: 1,
            borderColor: globals.white
        },
        bottomGray: {
            borderBottomWidth: 1,
            borderColor: globals.gray
        },
        bottomDarkGray: {
            borderBottomWidth: 1,
            borderColor: globals.darkGray
        },
        bottomXDarkGray: {
            borderBottomWidth: 1,
            borderColor: globals.xDarkGray
        },
        bottomXDarkGray4: {
            borderBottomWidth: 4,
            borderColor: globals.xDarkGray
        },
        bottomLightGray: {
            borderBottomWidth: 1,
            borderColor: globals.lightGray
        }
    }),

    log: StyleSheet.create({
        std: {
            padding: 20,
            margin: 10,
            borderWidth: StyleSheet.hairlineWidth,
            borderColor: '#f0f0f0',
            backgroundColor: '#f9f9f9',
        },
        box: {
            padding: 20,
            margin: 10,
            borderWidth: StyleSheet.hairlineWidth,
            borderColor: '#f0f0f0',
            backgroundColor: '#f9f9f9',
        }
    }),

    modal: StyleSheet.create({
        full: {
            top: globals.statusBarHeight,
            right: 0,
            bottom: 0,
            left: 0,
        }
    }),

    width: StyleSheet.create({
        auto: {
            width: 'auto',
        },
        w100: {
            width: '100%',
        },
        w25: {
            width: '25%',
        },
        w95: {
            width: '95%',
        },
        w90: {
            width: '90%',
        },
        w80: {
            width: '80%',
        },
        w75: {
            width: '75%',
        },
        w70: {
            width: '70%',
        },
        w60: {
            width: '60%',
        },
        w50: {
            width: '50%',
        },
        w45: {
            width: '45%',
        },
        w40: {
            width: '40%',
        },
        w37_half: {
            width: '37.5%',
        },
        w35: {
            width: '35%',
        },
        w33: {
            width: '33.33%',
        },
        w30: {
            width: '30%',
        },
        w20: {
            width: '20%',
        },
        w10: {
            width: '10%',
        },
    }), 

    height: StyleSheet.create({
        auto: {
            height: 'auto',
        },
        h100: {
            height: '100%',
        },        
        h90: {
            height: '90%',
        },
        h80: {
            height: '80%',
        },
        h75: {
            height: '75%'
        },
        h70: {
            height: '70%',
        },
        h60: {
            height: '60%',
        },
        h50: {
            height: '50%',
        },
        h40: {
            height: '40%',
        },
        h30: {
            height: '30%',
        },
        h25: {
            height: '25%',
        },
        h20: {
            height: '20%',
        },
        h10: {
            height: '10%',
        },
        mh100: {
            maxHeight: '100%',
        },        
        mh90: {
            maxHeight: '90%',
        },
        mh80: {
            maxHeight: '80%',
        },
        mh75: {
            maxHeight: '75%'
        },
        mh70: {
            maxHeight: '70%',
        },
        mh60: {
            maxHeight: '60%',
        },
        mh50: {
            maxHeight: '50%',
        },
        mh40: {
            maxHeight: '40%',
        },
        mh30: {
            maxHeight: '30%',
        },
        mh25: {
            maxHeight: '25%',
        },
        mh20: {
            maxHeight: '20%',
        },
        mh10: {
            maxHeight: '10%',
        },
    }),

    checkbox: StyleSheet.create({
        std: {
            backgroundColor: 'rgba(0, 0, 0, 0.0)',
            padding: 4,
            margin: 0,
        }
    })
}