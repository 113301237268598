import * as React from 'react';
import { 
    ScrollView,
    RefreshControl,
    View,
    Text,
    StyleSheet,
    Pressable } from 'react-native';
import { Divider, Icon } from '@rneui/themed';
import { Dropdown } from 'react-native-element-dropdown';
import axios from 'axios';
import AsyncStorage from '@react-native-async-storage/async-storage';

// Get custom
import { globals } from '../lib/globals';
import { styles, style } from '../lib/styles';
import { NFLWeeklySchedule, NFLMatchup, NFLEvents } from '../components/NFLData';
import { Section, Heading, Btn } from '../shared/ContentItems';
import { currentNFLWeek } from '../lib/nfl';
import { League } from '../components/Events/Events';
import { SportsData } from '../lib/sportsdata';
import FirebaseLoginForm from '../components/user/FirebaseLogin';

export function DashboardScreen({navigation}) {
	const [refreshing, setRefreshing] = React.useState(false);
	const [loading, setLoading] = React.useState(false);
    const [error, setError] = React.useState(null);
	const [weeks, setWeeks] = React.useState([]);
    const [currentWeek, setCurrentWeek] = React.useState(undefined);
	const [selectedWeek, setSelectedWeek] = React.useState(undefined);
	const [data, setData] = React.useState([]);

	const onRefresh = React.useCallback(() => {
		setRefreshing(true);
		setTimeout(() => {
			setRefreshing(false);
		}, 2000);
	}, []);

	const generateWeeks = () => {
		const weekArray = Array.from({ length: 18 }, (_, index) => index + 1);

		let newArray = weekArray.map((item) => {
			return {
				key: item.toString(),
				label: 'Week ' + item,
				value: item.toString(),
			};
		});
		setWeeks(newArray as never[]);
	};

	const getGameWinnerChoice = async (storageItem) => {
		await AsyncStorage.getItem(storageItem)
        .then((value) => {
            if (value !== null && value !== undefined) {
                // The item exists; `value` contains its value.
                return value;
            } else {
                // The item doesn't exist.
				return 'No winner selected';
            }
        })
        .catch((error) => {
            // Handle any errors that may occur during AsyncStorage operations.
            console.error('AsyncStorage error:', error);
        });
	}

    const logData = async () => {
        const sportsData = await SportsData.getStoredSportsData();

        console.log(sportsData);
    }
    // logData();

    const handleClickToSaveData = async (league: string, event: string) => {
        const storedLeagues = await SportsData.getStoredSportsData();

        // const dataToStore = {
        //     choiceToWin: 'Away',
        //     predictedStats: 'Away will win by 12 points'
        // }

        // if (storedLeagues) {
        //     if (storedLeagues.league.events) {
        //         storedLeagues.league.events[event] = {
        //             choiceToWin: 'Away',
        //             predictedStats: 'Away will win by 12 points'
        //         }
        //     } else {
        //         storedLeagues.league.events = storedLeagues.league.events[event]
        //     }
        // }

        // SportsData.saveLeagueSetting(league, storedLeagues);

        let sportsObj = {
            american_football: {},
            baseball: {},
            basketball: {},
            cricket: {},
            field_hockey: {},
            football: {},
            golf: {},
            hockey: {},
            lacrosse: {},
            mma: {},
            racing: {},
            rugby: {},
            tennis: {},
            volleyball: {},
            water_polo: {}
        }

        let sportsObjString = JSON.stringify(sportsObj);
        
        AsyncStorage.setItem('storedSportsData', sportsObjString);
    }

	return (
		
		<ScrollView refreshControl={<RefreshControl refreshing={refreshing} onRefresh={onRefresh}/>} contentInsetAdjustmentBehavior='automatic'>

			<View style={[style.screen.container]}>

                {/* <View>
                    <Btn onPress={() =>  handleClickToSaveData('NBA', 'event123')}><Text>CLICKY</Text></Btn>
                </View> */}

                {/**
                 * Football
                */}
                {globals.espn_american_football_nfl && (
					<Section style={[style.padding.pb3]}>
						<League leagueURL={globals.espn_american_football_nfl} sport={'american_football'} league={'nfl'} />
					</Section>
				)}
                {/* {globals.espn_australian_football_afl && (
					<Section style={[style.padding.pb3]}>
						<League leagueURL={globals.espn_australian_football_afl} sport={'australian_football'} league={'afl'} />
					</Section>
				)} */}

                {/**
                 * Golf
                */}
                {/* <Section style={[style.padding.pb3]}>
                    <League leagueURL={globals.espn_golf_champions_tour} sport={'golf'} league={'champions_tour'}  />
                </Section> */}
                {/* {globals.espn_golf_pga && (
					<Section style={[style.padding.pb3]}>
						<League leagueURL={globals.espn_golf_pga} sport={'golf'} league={'pga'} />
					</Section>
				)} */}

                {/**
                 * Hockey
                */}
                {/* <Section style={[style.padding.pb3]}>
                    <League leagueURL={globals.espn_hockey_nhl} sport={'hockey'} league={'nhl'} />
                </Section> */}

                {/**
                 * Baseball
                */}

                <Section style={style.padding.pb3}>
                    <League leagueURL={globals.espn_baseball_mlb} sport={'baseball'} league={'mlb'} />
                </Section>

                {/**
                 * Lacrosse
                */}
                {/* {globals.espn_lacrosse_pll && (
					<Section style={[style.padding.pb3]}>
						<League leagueURL={globals.espn_lacrosse_pll} title={'Premier Lacrosse League'} sport={'lacrosse'} league={'pll'} />
					</Section>
				)} */}

                {/**
                 * MMA
                */}
                {/* <Section style={[style.padding.pb3]}>
                    <League leagueURL={globals.espn_mma_ufc} sport={'mma'} league={'ufc'} />
                </Section> */}

                {/**
                 * Racing
                */}
                {/* <Section style={[style.padding.pb3]}>
                    <League leagueURL={globals.espn_racing_f1} sport={'racing'} title={'Formula 1 Racing'} league={'f1'} />
                </Section> */}
                {/* {globals.espn_racing_nascar_premier && (
					<Section style={[style.padding.pb3]}>
						<League leagueURL={globals.espn_racing_nascar_premier} sport={'racing'} league={'nascar_premier'} />
					</Section>
				)} */}

                {/**
                 * Rugby
                */}
                {/* {globals.espn_rugby_league_nrl && (
					<Section style={[style.padding.pb3]}>
						<League leagueURL={globals.espn_rugby_league_nrl} title={'nrl'} />
					</Section>
				)} */}
                {/* {globals.espn_rugby_world_cup && (
					<Section style={[style.padding.pb3]}>
						<League leagueURL={globals.espn_rugby_world_cup} />
					</Section>
				)} */}

                {/**
                 * Soccer
                */}
                {/* <Section style={[style.padding.pb3]}>
                    <League leagueURL={globals.espn_soccer_mex} sport={'soccer'} league={'mex'} />
                </Section> */}

                {/* <Section style={[style.padding.pb3]}>
                    <League leagueURL={globals.espn_soccer_usa_1} sport={'soccer'} league={'mls'} />
                </Section> */}

                {/* <Section style={[style.padding.pb3]}>
                    <League leagueURL={globals.espn_soccer_uefa_champions} sport={'soccer'} league={'uefa_champions'} />
                </Section> */}

                {/* <Section style={[style.padding.pb3]}>
                    <League leagueURL={globals.espn_soccer_eng_1} sport={'soccer'} league={'eng_1'} />
                </Section> */}

                {/* <Section style={[style.padding.pb3]}>
                    <League leagueURL={globals.espn_soccer_eng_w_1} sport={'soccer'} league={'eng_w_1'} />
                </Section> */}
				
                {/* <Section style={[style.padding.pb3]}>
                    <League leagueURL={globals.espn_soccer_uefa_wchampions} sport={'soccer'} league={'uefa_wchampions'} />
                </Section> */}

                {/**
                 * Tennis
                */}
                {/* <Section style={[style.padding.pb3]}>
                    <League leagueURL={globals.espn_tennis_atp} sport={'tennis'} title={'Men\'s Tennis ATP Tour'} league={'tennis_atp'} />
                </Section> */}

                {/* <Section style={[style.padding.pb3]}>
                    <League leagueURL={globals.espn_tennis_wta} sport={'tennis'} title={'Women\'s Tennis WTA Tour'} league={'tennis_wta'} />
                </Section> */}

                {/* <Section style={[style.padding.pb3]}>
                    <League leagueURL={globals.espn_tennis_atp} sport={'tennis'} league={'atp'}  />
                </Section> */}

                {/* <Section style={[style.padding.pb3]}>
                    <League leagueURL={globals.espn_tennis_wta} sport={'tennis'} league={'wta'}  />
                </Section> */}

                {/**
                 * Basketball
                */}
                <Section style={[style.padding.pb3]}>
                    <League leagueURL={globals.espn_basketball_mens_olympics} sport={'basketball'} league={'olympics'} />
                </Section>

                <Section style={[style.padding.pb3]}>
                    <League leagueURL={globals.espn_basketball_womens_olympics} sport={'basketball'} league={'olympics'} />
                </Section>

                {/* <Section style={[style.padding.pb3]}>
                    <League leagueURL={globals.espn_basketball_nba} sport={'basketball'} league={'nba'} />
                </Section> */}
				
                {/* <Section style={[style.padding.pb3]}>
                    <League leagueURL={globals.espn_basketball_wnba} sport={'basketball'} league={'wnba'} />
                </Section> */}
				
                {/* <Section style={[style.padding.pb3]}>
                    <League leagueURL={globals.espn_basketball_mens_college} sport={'basketball'} league={'mens_college'} />
                </Section> */}

				{/* <Section style={[style.padding.pb3]}>
                    <League leagueURL={globals.espn_basketball_womens_college} sport={'basketball'} league={'womens_college'}  />
                </Section> */}
                
			</View>

		</ScrollView>
	);
}