const base_espn_api_url = 'https://sports.core.api.espn.com/v2/sports/';
const base_espn_site_api_url = 'https://site.api.espn.com/apis/site/v2/sports/';
const SECRETS = {
    newsAPIKEY: 'b78b5161b0554e7889cc7f8e6aa5f970',

    // espn_base
    base_espn_api_url: base_espn_api_url,
    base_espn_site_api_url: base_espn_site_api_url,

    // New

    // American Football
    espn_american_football_leagues: base_espn_api_url + 'football/leagues' ,
    espn_american_football_nfl: base_espn_api_url + 'football/leagues/nfl' ,
    espn_american_football_nfl_news: base_espn_site_api_url + 'football/nfl/news' ,
    NFLTeamsAPIURL: 'https://sports.core.api.espn.com/v2/sports/football/leagues/nfl/seasons/2023/teams/',
    NFLTeamGeneralInfoURL: 'https://gg.dwd.work/data/NFL-DataMap.json',
    NFLTeamDataAPIURL: 'https://sports.core.api.espn.com/v2/sports/football/leagues/nfl/seasons/2023/types/2/teams/',
    NFLNewsAPIURL: 'https://site.api.espn.com/apis/site/v2/sports/football/nfl/news',
    NFLWeeklyScheduleAPIURL: 'https://cdn.espn.com/core/nfl/schedule?xhr=1&year=2023',
    NFLEventsURL: 'https://sports.core.api.espn.com/v2/sports/football/leagues/nfl/events/',
    ESPNScoreboardAPIURLNFL: 'https://site.api.espn.com/apis/site/v2/sports/football/nfl/scoreboard',
    ESPNEventSummaryAPIURLNFL: 'https://site.api.espn.com/apis/site/v2/sports/football/nfl/summary',
    PERSONAL_BettingCompaniesListAPIURL: 'https://gg.dwd.work/data/BettingCompanies.json',
    NFLPostSeasonAPIURL: 'https://sports.core.api.espn.com/v2/sports/football/leagues/nfl/seasons/2023/types/3/',

    // Australian Football
    espn_australian_football_leagues: base_espn_api_url + 'australian-football/leagues' ,
    espn_australian_football_afl: base_espn_api_url + 'australian-football/leagues/afl',

    // Baseball
    espn_baseball_leagues: base_espn_api_url + 'baseball/leagues' ,
    espn_baseball_college: base_espn_api_url + 'baseball/leagues/college-baseball',
    espn_baseball_college_softball: base_espn_api_url + 'baseball/leagues/college-softball' ,
    espn_baseball_llb: base_espn_api_url + 'baseball/leagues/llb',
    espn_baseball_mlb: base_espn_api_url + 'baseball/leagues/mlb' ,
    espn_baseball_mlb_news: base_espn_site_api_url + 'baseball/mlb/news' ,
    espn_baseball_world: base_espn_api_url + 'baseball/leagues/world-baseball-classic',

    // Basketball
    espn_basketball_leagues: base_espn_api_url + 'basketball/leagues' ,
    espn_basketball_fiba: base_espn_api_url + 'basketball/leagues/fiba',
    espn_basketball_mens_college: base_espn_api_url + 'basketball/leagues/mens-college-basketball',
    espn_basketball_mens_college_news: base_espn_site_api_url + 'basketball/mens-college-basketball/news',
    espn_basketball_nba: base_espn_api_url + 'basketball/leagues/nba',
    espn_basketball_nba_news: base_espn_site_api_url + 'basketball/nba/news',
    espn_basketball_wnba: base_espn_api_url + 'basketball/leagues/wnba',
    espn_basketball_wnba_news: base_espn_site_api_url + 'basketball/wnba/news',
    espn_basketball_nbl: base_espn_api_url + 'basketball/leagues/nbl',
    espn_basketball_womens_college: base_espn_api_url + 'basketball/leagues/womens-college-basketball',
    espn_basketball_womens_college_news: base_espn_site_api_url + 'basketball/womens-college-basketball/news',
    NBAURL: 'https://sports.core.api.espn.com/v2/sports/basketball/leagues/nba/',
    NBAEventsURL: 'https://sports.core.api.espn.com/v2/sports/basketball/leagues/nba/events/',
    NBATeamsURL: 'https://sports.core.api.espn.com/v2/sports/basketball/leagues/nba/seasons/2024/teams/',

    // Cricket

    // Golf
    espn_golf_leagues: base_espn_api_url + 'golf/leagues' ,
    espn_golf_champions_tour: base_espn_api_url + 'golf/leagues/champions-tour',
    espn_golf_eur: base_espn_api_url + 'golf/leagues/eur',
    espn_golf_liv: base_espn_api_url + 'golf/leagues/liv',
    espn_golf_lgpa: base_espn_api_url + 'golf/leagues/lgpa',
    espn_golf_ntw: base_espn_api_url + 'golf/leagues/ntw',
    espn_golf_pga: base_espn_api_url + 'golf/leagues/pga',

    // Hockey
    espn_hockey_leagues: base_espn_api_url + 'hockey/leagues',
    espn_hockey_world_cup: base_espn_api_url + 'hockey/leagues/hockey-world-cup',
    espn_hockey_college_men: base_espn_api_url + 'hockey/leagues/mens-college-hockey',
    espn_hockey_college_women: base_espn_api_url + 'hockey/leagues/womens-college-hockey',
    espn_hockey_nhl: base_espn_api_url + 'hockey/leagues/nhl',
    espn_hockey_nhl_news: base_espn_site_api_url + 'hockey/nhl/news',

    // Lacrosse
    espn_lacrosse_leagues: base_espn_api_url + 'lacrosse/leagues',
    espn_lacrosse_mens_college: base_espn_api_url + 'lacrosse/leagues/mens-college-lacrosse',
    espn_lacrosse_pll: base_espn_api_url + 'lacrosse/leagues/pll',
    espn_lacrosse_college_women: base_espn_api_url + 'lacrosse/leagues/womens-college-lacrosse',

    // MMA
    espn_mma_leagues: base_espn_api_url + 'mma/leagues',
    espn_mma_ufc: base_espn_api_url + 'mma/leagues/ufc',
    espn_mma_bellator: base_espn_api_url + 'mma/leagues/bellator',
    espn_mma_big_fight: base_espn_api_url + 'mma/leagues/big-fight',
    espn_mma_m1: base_espn_api_url + 'mma/leagues/m1',
    espn_mma_pfl: base_espn_api_url + 'mma/leagues/pfl',
    espn_mma_ringside: base_espn_api_url + 'mma/leagues/ringside',
    espn_mma_rizin: base_espn_api_url + 'mma/leagues/rizin',
    espn_mma_shark_fights: base_espn_api_url + 'mma/leagues/shark-fights',
    espn_mma_shooto_japan: base_espn_api_url + 'mma/leagues/shooto-japan',

    // Racing
    espn_racing_leagues: base_espn_api_url + 'racing/leagues',
    espn_racing_f1: base_espn_api_url + 'racing/leagues/f1',
    espn_racing_irl: base_espn_api_url + 'racing/leagues/irl',
    espn_racing_nascar_premier: base_espn_api_url + 'racing/leagues/nascar-premier',
    espn_racing_nascar_secondary: base_espn_api_url + 'racing/leagues/nascar-secondary',
    espn_racing_nascar_truck: base_espn_api_url + 'racing/leagues/nascar-truck',
    espn_racing_nhra: base_espn_api_url + 'racing/leagues/nhra',

    // Rugby
    espn_rugby_leagues: base_espn_api_url + 'rugby/leagues',
    espn_rugby_league_nrl: base_espn_api_url + 'rugby-league/leagues/3',
    espn_rugby_world_cup: base_espn_api_url + 'rugby/leagues/164205',
    espn_rugby_six_nations: base_espn_api_url + 'rugby/leagues/180659',
    espn_rugby_eu_champions_cup: base_espn_api_url + 'rugby/leagues/271937',
    espn_rugby_top14: base_espn_api_url + 'rugby/leagues/270559',
    espn_rugby_urbatop12: base_espn_api_url + 'rugby/leagues/2009',
    espn_rugby_super_rugby_pacific: base_espn_api_url + 'rugby/leagues/242041',
    espn_rugby_african_car_championship: base_espn_api_url + 'rugby/leagues/264129',
    espn_rugby_asian_5_nations: base_espn_api_url + 'rugby/leagues/262827',
    espn_rugby_fira_championship: base_espn_api_url + 'rugby/leagues/239923',
    espn_rugby_irb_nations_cup: base_espn_api_url + 'rugby/leagues/289230',
    espn_rugby_irb_pacigic_nations_cup: base_espn_api_url + 'rugby/leagues/256449',
    espn_rugby_world_cup_qualifier: base_espn_api_url + 'rugby/leagues/161354',
    espn_rugby_tri_nations: base_espn_api_url + 'rugby/leagues/289274',
    espn_rugby_tour: base_espn_api_url + 'rugby/leagues/282881',
    espn_rugby_womens_world_cup: base_espn_api_url + 'rugby/leagues/289237',
    espn_rugby_major_league: base_espn_api_url + 'rugby/leagues/289262',
    espn_rugby_americas_championship: base_espn_api_url + 'rugby/leagues/171198',
    espn_rugby_summer_nations: base_espn_api_url + 'rugby/leagues/289273',

    // Soccer
    espn_soccer_leagues: base_espn_api_url + 'soccer/leagues',
    espn_soccer_club_friendly: base_espn_api_url + 'soccer/leagues/club.friendly',
    espn_soccer_conmebol: base_espn_api_url + 'soccer/leagues/conmebol.america',
    espn_soccer_concacaf_league_cup: base_espn_api_url + 'soccer/leagues/concacaf.leagues.cup',
    espn_soccer_concacaf_league: base_espn_api_url + 'soccer/leagues/concacaf.leagues',
    espn_soccer_fifa_friendly: base_espn_api_url + 'soccer/leagues/fifa.friendly',
    espn_soccer_fifa_friendly_w: base_espn_api_url + 'soccer/leagues/fifa.friendly.w',
    espn_soccer_fifa_world: 'soccer/leagues/fifa.world',
    espn_soccer_fifa_wwc: 'soccer/leagues/fifa.wwc',
    espn_soccer_fifa_worldq_concacaf: 'soccer/leagues/fifa.worldq.concacaf',
    espn_soccer_fifa_worldq_uefa: 'soccer/leagues/fifa.worldq.uefa',
    espn_soccer_fifa_worldq_conmebol: 'soccer/leagues/fifa.worldq.conmebol',
    espn_soccer_fifa_worldq_caf: 'soccer/leagues/fifa.worldq.caf',
    espn_soccer_fifa_worldq_afc: 'soccer/leagues/fifa.worldq.afc',
    espn_soccer_fifa_worldq_ofc: 'soccer/leagues/fifa.worldq.ofc',
    espn_soccer_fifa_worldq_afc_conmebol: 'soccer/leagues/fifa.worldq.afc.conmebol',
    espn_soccer_fifa_wworldq_uefa: 'soccer/leagues/fifa.wworldq.uefa',
    espn_soccer_fifa_wwcq_ply: 'soccer/leagues/fifa.wwcq.ply',
    espn_soccer_fifa_cwc: 'soccer/leagues/fifa.cwc',
    espn_soccer_fifa_sheblelieves: 'soccer/leagues/fifa.sheblelieves',
    espn_soccer_mex: base_espn_api_url + 'soccer/leagues/mex.1',
    espn_soccer_mex_2: base_espn_api_url + 'soccer/leagues/mex.2',
    espn_soccer_uefa_champions: base_espn_api_url + 'soccer/leagues/uefa.champions',
    espn_soccer_uefa_wchampions: base_espn_api_url + 'soccer/leagues/uefa.wchampions',
    espn_soccer_uefa_euro: base_espn_api_url + 'soccer/leagues/uefa.euro',
    espn_soccer_uefa_euro_news: base_espn_site_api_url + 'soccer/uefa.euro/news',
    espn_soccer_uefa_europa: base_espn_api_url + 'soccer/leagues/uefa.europa',
    espn_soccer_uefa_europa_conf: base_espn_api_url + 'soccer/leagues/uefa.europa.conf',
    espn_soccer_eng_1: base_espn_api_url + 'soccer/leagues/eng.1',
    espn_soccer_eng_w_1: base_espn_api_url + 'soccer/leagues/eng.w.1',
    espn_soccer_eng_2: base_espn_api_url + 'soccer/leagues/eng.2',
    espn_soccer_eng_3: base_espn_api_url + 'soccer/leagues/eng.3',
    espn_soccer_eng_4: base_espn_api_url + 'soccer/leagues/eng.4',
    espn_soccer_eng_5: base_espn_api_url + 'soccer/leagues/eng.5',
    espn_soccer_eng_fa: base_espn_api_url + 'soccer/leagues/eng.fa',
    espn_soccer_eng_league_cup: base_espn_api_url + 'soccer/leagues/eng.league_cup',
    espn_soccer_usa_1: base_espn_api_url + 'soccer/leagues/usa.1',
    espn_soccer_usa_nwsl: base_espn_api_url + 'soccer/leagues/usa.nwsl',
    espn_soccer_usa_open: base_espn_api_url + 'soccer/leagues/usa.open',
    espn_soccer_usa_usl_1: base_espn_api_url + 'soccer/leagues/usa.usl.1',
    espn_soccer_usa_usl_l1: base_espn_api_url + 'soccer/leagues/usa.usl.l1',
    espn_soccer_usa_usl_l1_cup: base_espn_api_url + 'soccer/leagues/usa.usl.l1.cup',
    espn_soccer_usa_1_news: base_espn_site_api_url + 'soccer/usa.1/news',

    // Tennis
    espn_tennis_leagues: base_espn_api_url + 'tennis/leagues' ,
    espn_tennis_atp: base_espn_api_url + 'tennis/leagues/atp',
    espn_tennis_wta: base_espn_api_url + 'tennis/leagues/wta',

    // Volleyball
    espn_volleyball_leagues: base_espn_api_url + 'volleyball/leagues' ,
    espn_volleyball_mens_college: base_espn_api_url + 'volleyball/leagues/mens-college-volleyball',
    espn_volleyball_womens_college: base_espn_api_url + 'volleyball/leagues/womens-college-volleyball',

    // Water Polo
    espn_water_polo_leagues: base_espn_api_url + 'water-polo/leagues' ,
    espn_water_polo_mens_college: base_espn_api_url + 'water-polo/leagues/mens-college-water-polo',
    espn_water_polo_womens_college: base_espn_api_url + 'water-polo/leagues/womens-college-water-polo',

    // Olympics
    espn_baseball_olympics: base_espn_api_url + 'baseball/leagues/olympics-baseball',
    espn_basketball_mens_olympics: base_espn_api_url + 'basketball/leagues/mens-olympics-basketball',
    espn_basketball_womens_olympics: base_espn_api_url + 'basketball/leagues/womens-olympics-basketball',
    espn_golf_mens_olympics: base_espn_api_url + 'golf/leagues/mens-olympics-golf',
    espn_golf_womens_olympics: base_espn_api_url + 'golf/leagues/womens-olympics-golf',
    espn_hockey_olympics_men: base_espn_api_url + 'hockey/leagues/olympics-mens-ice-hockey',
    espn_hockey_olympics_women: base_espn_api_url + 'hockey/leagues/olympics-womens-ice-hockey',
    espn_rugby_mens_olympics_7s: base_espn_api_url + 'rugby/leagues/282',
    espn_rugby_womens_olympics_7s: base_espn_api_url + 'rugby/leagues/283',
}

export default SECRETS;