import * as React from 'react';
import axios from 'axios';
import { 
    View, 
    Text, 
    Image,
    Pressable,
    TextInput,
    LayoutAnimation, 
    Button } from 'react-native';
import { Divider } from '@rneui/themed';
import { Icon } from 'react-native-elements';
import * as Icons from '@expo/vector-icons';

// Get custom
import { simulationScript } from '../../lib/simulationScript';
import { globals } from '../../lib/globals';
import { styles, style } from '../../lib/styles';
import { Heading, Btn, SwitchToggle, Paragraph, ListItemWithIcon } from '../../shared/ContentItems';
import { NFLGameArticlesModal } from '../NFLGame';
import { NFLGameOddsModal } from '../NFLGameOdds';
import { UnorderedList2Col } from '../ui/UnorderedList';
import { AccordionComponent } from '../ui/AccordionComponent';

export const SimResults = (props) => {
    const [gameData, setGameData] = React.useState(null);
    const [homeTeamDisplayStats, setHomeTeamDisplayStats] = React.useState([]);
    const [awayTeamDisplayStats, setAwayTeamDisplayStats] = React.useState([]);
    const [isOddsModalVisible, setOddsModalVisible] = React.useState(false);
    const [isArticlesModalVisible, setIsArticlesModalVisible] = React.useState(false);
    const [simulationResultsVisibility, setSimulationResultsVisibility] = React.useState(true);

    const getResults = () => {
        setGameData(simulationScript(props.homeTeam, props.homeTeamStats, props.awayTeam, props.awayTeamStats, props.numSims, props.showPlays, props.awayPlayStyle, props.homePlayStyle));
        const awayRushAttempts = props.awayTeamStats ? props.awayTeamStats.rushing.stats.rushingAttempts.value : 27;
        const awayPassAttempts = props.awayTeamStats ? props.awayTeamStats.passing.passingAttempts.value : 34;
        const homeRushAttempts = props.homeTeamStats ? props.homeTeamStats.rushing.stats.rushingAttempts.value : 27;
        const homePassAttempts = props.homeTeamStats ? props.homeTeamStats.passing.stats.passingAttempts.value : 34;

        const awayStatsList = [
            {
                label: 'Avg Rush %',
                value: ((awayRushAttempts / (awayRushAttempts + awayPassAttempts)) * 100).toFixed(2),
            },
            {
                label: 'Avg Pass %',
                value: ((awayPassAttempts / (awayRushAttempts + awayPassAttempts)) * 100).toFixed(2),
            },
            {
                label: 'Avg Pass CMP:',
                value: props.awayTeamStats ? props.awayTeamStats.passing.stats.completionPct.displayValue : .6,
            },
            {
                label: 'Avg Rush YPAtt:',
                value: props.awayTeamStats ? props.awayTeamStats.rushing.stats.yardsPerRushAttempt.displayValue : 4,
            },
            {
                label: 'Avg Pass YPAtt:',
                value: props.awayTeamStats ? props.awayTeamStats.passing.stats.yardsPerPassAttempt.displayValue : .4,
            },
            {
                label: 'QBR:',
                value: props.awayTeamStats ? props.awayTeamStats.passing.stats.quarterbackRating.perGameValue.toFixed(1) : 1,
            }
        ];
        setAwayTeamDisplayStats(awayStatsList);
        const homeStatsList = [
            {
                label: 'Avg Rush %',
                value: ((homeRushAttempts / (homeRushAttempts + homePassAttempts)) * 100).toFixed(2),
            },
            {
                label: 'Avg Pass %',
                value: ((homePassAttempts / (homeRushAttempts + homePassAttempts)) * 100).toFixed(2),
            },
            {
                label: 'Avg Pass CMP:',
                value: props.homeTeamStats ? props.homeTeamStats.passing.stats.completionPct.displayValue : .6,
            },
            {
                label: 'Avg Rush YPAtt:',
                value: props.homeTeamStats ? props.homeTeamStats.rushing.stats.yardsPerRushAttempt.displayValue : 4,
            },
            {
                label: 'Avg Pass YPAtt:',
                value: props.homeTeamStats ? props.homeTeamStats.passing.stats.yardsPerPassAttempt.displayValue : .4,
            },
            {
                label: 'QBR:',
                value: props.homeTeamStats ? props.homeTeamStats.passing.stats.quarterbackRating.perGameValue.toFixed(1) : 1,
            }
        ];
        setHomeTeamDisplayStats(homeStatsList);

    }

    const collapsibleSimulationResults = () => {
        LayoutAnimation.configureNext(LayoutAnimation.Presets.easeInEaseOut);
        setSimulationResultsVisibility(!simulationResultsVisibility);
    }

    React.useEffect(() => {
        getResults();
    }, []);

    return (
        <View style={[style.margin.mb1]}>
            <Pressable onPress={collapsibleSimulationResults} style={[style.flex.row, style.flex.jBetween, style.flex.aCenter, style.box.xDarkGray, style.box.topCap,  style.padding.px1_half]}>
                <View style={[style.padding.py1_quarter]}>
                    <Heading style={[style.text.h2, style.margin.mb0]}>Simulation Results</Heading>
                </View>
                <View>
                    <Icons.MaterialCommunityIcons name={simulationResultsVisibility === true ? 'chevron-up-circle' : 'chevron-down-circle'} color={globals.white} size={24} />
                </View>
            </Pressable>

            {simulationResultsVisibility && (
                <View style={[style.box.xDarkGray, style.box.bottomCap, style.padding.p1]}>
                    {gameData ? (
                        <>
                            <View style={[style.flex.column, {position: 'relative', zIndex: 2}]}>
                                <View style={[style.flex.std, style.flex.row, style.flex.jBetween, style.flex.aCenter, style.margin.my1]}>
                                    <View style={[styles.w20, style.flex.row, style.flex.jStart, style.flex.aEnd]}>
                                        {props.awayTeamLogo && (
                                            <Image 
                                                source={{uri: props.awayTeamLogo}}
                                                resizeMode={'contain'}
                                                style={{ width: 25, height: 25 }}
                                            />
                                        )}
                                        
                                    </View>
                                    <View style={[styles.w60, style.flex.centerAll, style.flex.row]}>
                                        {props.numSims > 0 ? (
                                            <View style={[style.flex.column]}>
                                                <Heading style={[style.text.center]}>Sim Results</Heading>
                                                <Text style={[style.text.white]}>(avg of {props.numSims} simulations)</Text>
                                            </View>
                                        ) : (
                                            <Heading style={[style.text.center]}>Sim Results</Heading>
                                        )}
                                        
                                    </View>
                                    <View style={[styles.w20, style.flex.row, style.flex.jEnd, style.flex.aEnd]}>
                                        {props.homeTeamLogo && (
                                            <Image 
                                                source={{uri: props.homeTeamLogo}}
                                                resizeMode={'contain'}
                                                style={{ width: 25, height: 25 }}
                                            />
                                        )}
                                    </View>
                                </View>

                                <View style={[style.margin.mb2, style.flex.column, styles.w100]}>
                                    <View style={[style.flex.row, style.flex.jBetween, style.flex.aCenter, styles.w100, style.margin.mb1_half]}>
                                        <Text style={[{marginBottom: 0, color: 'white'}]}>{gameData.awayStats.totalYards}</Text>
                                        <Heading style={styles.h4}>Total Yards</Heading>
                                        <Text style={[{color: 'white'}]}>{gameData.homeStats.totalYards}</Text>
                                    </View>
                                    <View style={[style.flex.row, styles.w100,]}>
                                        <View style={[{height: 4, width: gameData.awayStats.totalYardShare, backgroundColor: '#' + props.awayTeam.color, paddingRight: 4}]}></View>
                                        <View style={[{height: 4, width: gameData.homeStats.totalYardShare, backgroundColor: '#' + props.homeTeam.color , paddingLeft: 4}]}></View>
                                    </View>
                                </View>

                                <View style={[style.margin.mb2, style.flex.column, styles.w100]}>
                                    <View style={[style.flex.row, style.flex.jBetween, style.flex.aCenter, styles.w100, style.margin.mb1_half]}>
                                        <Text style={[{marginBottom: 0, color: 'white'}]}>{gameData.awayStats.totalPassingYards}</Text>
                                        <Heading style={styles.h4}>Passing Yards</Heading>
                                        <Text style={[{color: 'white'}]}>{gameData.homeStats.totalPassingYards}</Text>
                                    </View>
                                    <View style={[style.flex.row, styles.w100,]}>
                                        <View style={[{height: 4, width: gameData.awayStats.totalPassingYardShare, backgroundColor: '#' + props.awayTeam.color, paddingRight: 4}]}></View>
                                        <View style={[{height: 4, width: gameData.homeStats.totalPassingYardShare, backgroundColor: '#' + props.homeTeam.color, paddingLeft: 4}]}></View>
                                    </View>
                                </View>

                                <View style={[style.margin.mb2, style.flex.column, styles.w100]}>
                                    <View style={[style.flex.row, style.flex.jBetween, style.flex.aCenter, styles.w100, style.margin.mb1_half]}>
                                        <Text style={[{marginBottom: 0, color: 'white'}]}>{gameData.awayStats.totalRunningYards}</Text>
                                        <Heading style={styles.h4}>Rushing Yards</Heading>
                                        <Text style={[{color: 'white'}]}>{gameData.homeStats.totalRunningYards}</Text>
                                    </View>
                                    <View style={[style.flex.row, styles.w100,]}>
                                        <View style={[{height: 4, width: gameData.awayStats.totalRunningYardShare, backgroundColor: '#' + props.awayTeam.color, paddingRight: 4}]}></View>
                                        <View style={[{height: 4, width: gameData.homeStats.totalRunningYardShare, backgroundColor: '#' + props.homeTeam.color, paddingLeft: 4}]}></View>
                                    </View>
                                </View>

                                <View style={[style.margin.mb1, style.flex.column, styles.w100]}>
                                    <View style={[style.flex.row, style.flex.jBetween, style.flex.aCenter, styles.w100, style.margin.mb1_half]}>
                                        <Text style={[{marginBottom: 0, color: 'white'}]}>{gameData.awayStats.averageYPP}</Text>
                                        <Heading style={styles.h4}>Yards Per Play</Heading>
                                        <Text style={[{color: 'white'}]}>{gameData.homeStats.averageYPP}</Text>
                                    </View>
                                    <View style={[style.flex.row, styles.w100,]}>
                                        <View style={[{height: 4, width: gameData.awayStats.averageYPPShare, backgroundColor: '#' + props.awayTeam.color, paddingRight: 4}]}></View>
                                        <View style={[{height: 4, width: gameData.homeStats.averageYPPShare, backgroundColor: '#' + props.homeTeam.color, paddingLeft: 4}]}></View>
                                    </View>
                                </View>

                                <View style={[style.flex.row, style.flex.jBetween]}>
                                    <View style={[styles.w50, style.flex.column, style.flex.centerAll]}>
                                        <View style={[style.position.relative]}>
                                            <Image 
                                                source={{uri: props.awayTeamLogo}}
                                                resizeMode={'contain'}
                                                style={{width: 75, height: 75}}
                                            />
                                            {gameData.awayStats.score > gameData.homeStats.score && (
                                                <View style={[{left: -32, borderWidth: 2, borderColor: globals.pink}, style.position.top, style.border.round, style.bg.black, style.box.shadow, style.padding.p1_half]}>
                                                    <Icons.MaterialCommunityIcons name={'trophy'} color={globals.pink} size={32} />
                                                </View>
                                            )}
                                        </View>
                                        <Heading  style={[{fontSize: 46}, style.margin.mt1_half, style.margin.mb0]}>{gameData.awayStats.score}</Heading>
                                    </View>
                                    
                                    <View style={[styles.w50, style.flex.column, style.flex.centerAll]}>
                                        <View style={[style.position.relative]}>
                                            <Image 
                                                source={{uri: props.homeTeamLogo}}
                                                resizeMode={'contain'}
                                                style={{width: 75, height: 75}}
                                            />
                                            {gameData.awayStats.score < gameData.homeStats.score && (
                                                <View style={[{left: -32, borderWidth: 2, borderColor: globals.pink}, style.position.top, style.border.round, style.bg.black, style.box.shadow, style.padding.p1_half]}>
                                                    <Icons.MaterialCommunityIcons name={'trophy'} color={globals.pink} size={32} />
                                                </View>
                                            )}
                                        </View>
                                        <Heading style={[{fontSize: 46}, style.margin.mt1_half, style.margin.mb0]}>{gameData.homeStats.score}</Heading>
                                    </View>
                                </View>

                                <View style={[style.margin.mt1]}>
                                    <Pressable onPress={() => getResults()} style={[style.border.round4, style.bg.darkPink, style.padding.py1_half, style.flex.std, style.flex.centerAll]}>
                                        <Icons.FontAwesome5 name={'play'} size={32} color={globals.white} />
                                    </Pressable>
                                </View>
                            </View>
                        </>
                    ) : (
                        <View>
                            <Paragraph>Unable to retrieve game data.</Paragraph>
                        </View>
                    )}
                </View>
            )}
        </View>
    )
}