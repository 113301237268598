// Get dependencies
import * as React from 'react';
import {  
	ScrollView,
	RefreshControl,
	View,
	TextInput,
	Platform } from 'react-native';
import { Divider } from '@rneui/themed';

// Get custom dependencies
import { globals } from '../lib/globals';
import { SportsData } from '../lib/sportsdata';
import { Section, Heading, Paragraph } from '../shared/ContentItems';
import { Welcome } from '../components/Welcome';
import { styles, style } from '../lib/styles';
import { NFLArticles } from '../components/NFLData';
import { dailyNBAGamesData, NBAGame, teamData } from '../components/NBA/NBAData';
import { AFLGame } from '../components/AFL/AFLData';
import { League } from '../components/Events/Events';
import AppBasics from '../components/dashboard/AppBasics';
import AccountTiers from '../components/dashboard/AccountTiers';
import InTheWorks from '../components/dashboard/InTheWorks';
import { LeagueArticles } from '../components/Events/Events';

export function InformationScreen({navigation}) {
	const [refreshing, setRefreshing] = React.useState(false);
	const [basketballGames, setBasketballGames] = React.useState([]);
	const [aflGames, setAflGames] = React.useState([]);

	const onRefresh = React.useCallback(() => {
		setRefreshing(true);
		setTimeout(() => {
		setRefreshing(false);
		}, 2000);
	}, []);

	const options = {
        method: 'GET',
        url: 'https://newsapi.org/v2/everything?' +
			'q=NFL&' +
			'from=2023-10-31&' +
			'sortBy=popularity&' +
			'apiKey=' + globals.newsAPIKEY,
    };

	const options2024 = {
		method: 'GET',
		url: 'https://cdn.espn.com/core/nfl/schedule?xhr=1&year=2024/',
	}

	React.useEffect(() => {
		const nbaEvents = SportsData.fetchNBAEvents();
		nbaEvents.then((result) => {
			setBasketballGames(result);
		})

		const aflEvents = SportsData.fetchAFLEvents();
		aflEvents.then((result) => {
			setAflGames(result);
		})
	}, []);

	return (
		<ScrollView refreshControl={<RefreshControl refreshing={refreshing} onRefresh={onRefresh}/>}>

			<View style={[style.screen.container, style.margin.mt4]}>

				<Section>
					<Welcome navigation={navigation}/>
				</Section>

				<Section>
					<Heading style={[style.text.h2]}>App Basics</Heading>
					<Divider style={styles.mb1} width={2} color={globals.lightGray} />
					<AppBasics navigation={navigation}/>
				</Section>
				
				<Section>
					<LeagueArticles league={globals.espn_basketball_wnba} url={globals.espn_basketball_wnba_news} limit={'1'} sport={'basketball'} title={'Latest WNBA Article'} />
				</Section>

				<Section>
					<LeagueArticles league={globals.espn_hockey_nhl} url={globals.espn_hockey_nhl_news} limit={'1'} sport={'hockey'} title={'Latest NHL Article'} />
				</Section>

				<Section>
					<LeagueArticles league={globals.espn_baseball_mlb} url={globals.espn_baseball_mlb_news} limit={'1'} sport={'baseball'} title={'Latest MLB Article'} />
				</Section>

				<Section>
					<LeagueArticles league={globals.espn_soccer_usa_1} url={globals.espn_soccer_usa_1_news} limit={'1'} sport={'soccer'} title={'Latest MLS Article'} />
				</Section>

				<Section>
					<LeagueArticles league={globals.espn_soccer_uefa_euro} url={globals.espn_soccer_uefa_euro_news} limit={'1'} sport={'soccer'} title={'Latest UEFA Article'} />
				</Section>


				{/* <Section style={[style.padding.pb3]}>
					<AccountTiers navigation={navigation} />
				</Section> */}

			</View>
		</ScrollView>
	)
}