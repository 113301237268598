;/**
 * Custom script for simulating games
 * Accepts multiple params: 
 *  - Object of stats for home team
 *  - Object of stats for away team
 * If no params passed, run simulation on overall averages
 */


export function generateScores(teamAAverageScore, teamBAverageScore, scoreVariability) {

    // Calculate expected points based on win percentages
    const teamAExpectedPoints = teamAAverageScore + (0.5 - 0.5) * scoreVariability * 2;
    const teamBExpectedPoints = teamBAverageScore + (0.5 - 0.5) * scoreVariability * 2;

    // Generate random scores around the expected points
    const teamAScore = Math.round(teamAExpectedPoints + (Math.random() - 0.5) * scoreVariability * 2);
    const teamBScore = Math.round(teamBExpectedPoints + (Math.random() - 0.5) * scoreVariability * 2);

    return {
        teamAScore,
        teamBScore
    };
}

// Example usage:
// const teamAWinPercentage = 0.7; // Team A has a 70% win rate
// const teamBWinPercentage = 0.5; // Team B has a 50% win rate

// const scores = generateBasketballScores(teamAWinPercentage, teamBWinPercentage);
// console.log(`Team A Score: ${scores.teamAScore}, Team B Score: ${scores.teamBScore}`);


// START
export function simulationScript(home, fetchedHomeStats, away, fetchedAwayStats, numSims, showPlays, awayPlayStyle, homePlayStyle) {
    // console.log('home: ', home);
    // console.log('fetchedHomeStats: ', fetchedHomeStats.passing.quarterbackRating.perGameValue);
    // console.log('away: ', away);
    // console.log('fetchedAwayStats: ', fetchedAwayStats.passing.quarterbackRating.perGameValue);
    // console.log('----');
    // console.log('# sims: ', numSims);
    // console.log('showPlays: ', showPlays);
    // console.log('awayPlayStyle: ', awayPlayStyle);
    // console.log('homePlayStyle: ', homePlayStyle);

    /**
     * Helper functions used to build simulation are defined first
     *  - generateQBRMultiplier()
     *  - generateYards()
     *  - callRunPlay()
     *  - callPassPlay()
     *  - kickPunt()
     *  - kickFieldGoal()
     *  - decidePlay()
    **/

    // Helper function to generate yards, can be used for run, pass, punt, and field goal plays
    function generateSuccessMultiplier(qbr) {

        // Generate two random values between 0 and 1
        const r1 = Math.random();
        const r2 = Math.random();

        // Use the Box-Muller transform to convert the two random values into a single value
        const y = Math.sqrt(-2 * Math.log(r1)) * Math.cos(2 * Math.PI * r2);

        const rQBR = (qbr + 30 + y) / 2;

        return Math.round(rQBR);
    }

    // Helper function to generate yards, can be used for run, pass, punt, and field goal plays
    function generateYards(mean, deviation) {

        // Generate two random values between 0 and 1
        const r1 = Math.random();
        const r2 = Math.random();
      
        // Use the Box-Muller transform to convert the two random values into a single value
        const y = Math.sqrt(-2 * Math.log(r1)) * Math.cos(2 * Math.PI * r2);

        // Scale and shift the value (adjusted per type of play)
        const rYards = mean + deviation * y;
        const yards = rYards <= 0 ? rYards * -1 : rYards;

        // Round the value to the nearest whole number
        return Math.round(yards);
    }

    // Helper function to execute a pass play
    function callRunPlay(possessor) {

        // Declare variables to return
        let averageYPC = 4;
        let averageDeviation = 4;
        let positiveYardsThreshold = 48;
        var yards = 0;
        var message = '';

        // Update averages per team data
        if(fetchedHomeStats && fetchedAwayStats) {
            if(possessor === 'home') {
                averageYPC = fetchedHomeStats.rushing.yardsPerRushAttempt.value;
                positiveYardsThreshold = generateSuccessMultiplier(fetchedHomeStats.passing.quarterbackRating.perGameValue);
            } else if (possessor === 'away') {
                averageYPC = fetchedAwayStats.rushing.yardsPerRushAttempt.value;
                positiveYardsThreshold = generateSuccessMultiplier(fetchedAwayStats.passing.quarterbackRating.perGameValue);
            }
        }

        // Run plays result in positive yardage 55% of the time on average
        if (Math.random() * 100 <= positiveYardsThreshold) {
            yards = generateYards(averageYPC, averageDeviation);
            message = 'Ran ' + yards + ' yards';
        } else {
            message = 'No Gain';
        }

        // Return object to get yards gained and an optional message to display
        return {
            yards,
            message
        };
    }

    // Helper function to execute a pass play
    function callPassPlay(possessor) {

        // Declare variables to return
        let averageYPR = 7;
        let averageDeviation = 4;
        let completionThreshold = 40;
        var yards = 0;
        var message = '';

        // Update averages per team data
        if(fetchedHomeStats && fetchedAwayStats) {
            if(possessor === 'home') {
                averageYPR = fetchedHomeStats.passing.yardsPerCompletion.value;
                completionThreshold = fetchedHomeStats.passing.completionPct.value;
            } else if (possessor === 'away') {
                averageYPR = fetchedAwayStats.passing.yardsPerCompletion.value;
                completionThreshold = fetchedAwayStats.passing.completionPct.value;
            }
        }

        // Pass plays are successful 65% of the time on average
        if (Math.random() * 100 <= completionThreshold) {
            yards = generateYards(averageYPR, averageDeviation);
            message = 'Passed ' + yards + ' yards';
        } else {
            message = 'Pass Incomplete';
        }

        // Return object to get yards gained and an optional message to display
        return {
            yards,
            message
        };
    }

    // Helper function to execute a punt play
    function kickPunt(yardsToGoal) {

        // Declare variables to return
        let averageDeviation = 10;
        var yards = yardsToGoal > 80 ? generateYards(65, averageDeviation) : generateYards(45, averageDeviation);
        var message = '';

        // The following if statements account for various scenarios
        if (yardsToGoal > 80) {
            yards = generateYards(65, averageDeviation);
        }
        if (yards >= yardsToGoal) {
            yardsToGoal = 80;
            message = 'Touchback. Opponent starts at their own 20';
        } else {
            yardsToGoal = 100 - (yardsToGoal - yards);
            message = 'Punted for ' + yards + ' yards. Opponent\'s start with ' + yardsToGoal + ' yards to their goal.';
        }

        // Return object to get yards gained, an optional message to display, and yards remaining after punt
        return {
            yards,
            message,
            yardsToGoal
        }
    }

    // Helper function to kick a field goal
    function kickFieldGoal(yardsToGoal) {

        // Declare variables to return
        let attemptYards = yardsToGoal + 10;
        var message = attemptYards + ' yard attempt.';
        var success = false;

        // Success % affected by kick distance
        if (yardsToGoal < 9) {
            success = Math.random() * 100 < 99 ? true : false;
        } else if (yardsToGoal >= 9 && yardsToGoal < 19) {
            success = Math.random() * 100 < 97 ? true : false;
        } else if (yardsToGoal >= 19 && yardsToGoal < 29) {
            success = Math.random() * 100 < 90 ? true : false;
        } else if (yardsToGoal >= 29 && yardsToGoal < 39) {
            success = Math.random() * 100 < 80 ? true : false;
        } else if (yardsToGoal >= 39 && yardsToGoal < 49) {
            success = Math.random() * 100 < 65 ? true : false;
        } else {
            success = Math.random() * 100 < 45 ? true : false;
        }

        // Update returns
        if (success === true) {
            message += ' It\'s good!';
            yardsToGoal = 80;
        } else {
            yardsToGoal = 100 - yardsToGoal;
            message += ' Missed! Turnover on downs';
        }

        // Return object to get success boolean, optional message to display, and yards remaining if field goal missed
        return {
            success,
            message,
            yardsToGoal
        };
    }

    // Helper function to decide play for every down
    function decidePlay(possessor, currentDown, yardsToFirst, yardsToGoal) {

        // Declare variables to return
        currentDown++;
        var downType = '';
        var yards = 0;
        var playType = '';
        var play = null;

        // Update play call according to current down
        if (currentDown === 4) {
            downType = '4th Down';
            if (yardsToGoal < 55) {
                play = kickFieldGoal(yardsToGoal);
                playType = 'Field Goal';
            } else {
                play = kickPunt(yardsToGoal);
                playType = 'Punt';
                yardsToGoal = play.yardsToGoal;
            }
        } else if (currentDown === 3) {
            downType = '3rd Down';
            if (yardsToFirst < 2) {
                if (Math.random() * 100 < 80) {
                    play = callRunPlay(possessor);
                    playType = 'Run';
                } else {
                    play = callPassPlay(possessor);
                    playType = 'Pass';
                }
            } else {
                if (Math.random() * 100 < 40) {
                    play = callRunPlay(possessor);
                    playType = 'Run';
                } else {
                    playType = 'Pass';
                    play = callPassPlay(possessor);
                }
            }
        } else {
            if (currentDown === 2) {
                downType = '2nd Down';
            } else if (currentDown === 1) {
                downType = '1st Down';
            }
            if (Math.random() * 100 < 45) {
                play = callRunPlay(possessor);
                playType = 'Run';
            } else {
                play = callPassPlay(possessor);
                playType = 'Pass';
            }
        }

        // Update returns
        if (playType === 'Punt') {
            yards = play.yards;
        } else {
            yards = playType != 'Field Goal' ? play.yards : 0;
        }

        // Return object to get current down, yards gained, type of play called, and play results
        return {
            downType,
            yards,
            playType,
            play
        };
    }

    /**
     * Now define functions that utilize helper functions and build simulation
     * - simulatePossession()
     * - simulateNFLGame()
    **/

    // Single possession function
    function simulatePossession(possessor, yardsToGoal) {
        
        // Declare variables to return
        var down = 0;
        var message = '';
        var totalPlays = 0;
        var yardsGained = 0;
        var yardsToGoalStartingPossession = yardsToGoal;
        
        // Declare variables for overall possessoion
        var possession = {
            punt: false,
            td: false,
            fg: false,
            playsCalled: [],
        }

        // Declare sscope for downs outside of while loop
        var setOfDowns = {
            playsCalled: [],
            yardsToFirst: 10,
            yardsGained: 0,
        }

        // Run a repeatable function to simulate 4 downs
        // In other words, while loop is defined like this: while (current team retains possession) 
        while (down < 4 && possession.td != true && possession.fg != true && possession.punt != true) {

            // While looping, push plays to arrays for tracking progress
            let currentPlay = decidePlay(possessor, down, setOfDowns.yardsToFirst, yardsToGoal); 
            setOfDowns.playsCalled.push(currentPlay);
            possession.playsCalled.push(currentPlay);

            // Update yards gained if play is run or pass
            if (currentPlay.playType === 'Run' || currentPlay.playType === 'Pass') {
                
                // Update for setOfDowns inside while loop
                setOfDowns.yardsGained += currentPlay.play.yards;
                setOfDowns.yardsToFirst = 10 - setOfDowns.yardsGained;

                // Update for overall possession
                totalPlays++;
                yardsGained += currentPlay.play.yards;
                yardsToGoal = yardsToGoal - currentPlay.play.yards;
            }

            // Restart while loop on 1st down if 10 yards gained, otherwise increment downs
            if (setOfDowns.yardsToFirst <= 0) {
                down = 0;
                setOfDowns.yardsGained = 0;
                setOfDowns.yardsToFirst = 10;
            } else {                
                down++;
            }

            // Check for change of possession and exit while loop if any of the following are true
            possession.punt = currentPlay.playType === 'Punt' ? true : false;
            possession.fg = currentPlay.playType === 'Field Goal' && currentPlay.play.success === true ? true : false;
            possession.td = possession.punt != true && possession.fg != true && yardsToGoal < 0 ? true : false;
        }

        // Set variable to get results of last play
        let lastPlay = possession.playsCalled[possession.playsCalled.length - 1];

        // Update returns
        if (possession.td == false && possession.fg === false && possession.punt === false) {
            message = 'Turnover on Downs';
            yardsToGoal = 100 - lastPlay.play.yardsToGoal;
        } else {
            if (possession.td == true) {
                message = 'Touchdown!';
                yardsToGoal = 80;
            } 
            if (possession.fg === true) {
                message = 'Field Goal!';
                yardsToGoal = 80;
            }
            if (possession.punt === true) {
                message = 'Punt';
                yardsToGoal = lastPlay.play.yardsToGoal;
            }
        }

        // Return object to get yards to goal, result message, overall yards gained, remaining yards, and possession results for every possession 
        return {
            yardsToGoalStartingPossession,
            message,
            yardsGained,
            yardsToGoal,
            possession,
        };        
    }

    // Define function to finally simulate entire NFL Game
    function simulateNFLGame (possessions) {

        if (numSims > 1) {
            possessions = numSims * possessions;
        }

        // Set yardsToGoal variable used for each team's possession
        let yardsToGoal = 0;
        let possessor = '';

        // Set variables to hold stats for each team
        var allPossessions = [];
        var homePossessions = [];
        var awayPossessions = [];
        var homeStats = {
            name: home.name ? home.name : 'Home',
            tds: 0,
            fgs: 0,
            score: 0,
            totalPlays: 0,
            totalYards: 0,
            totalYardShare: 0,
            totalRunPlays: 0,
            totalRunningYards: 0,
            totalRunningYardsShare: 0,
            totalPassPlays: 0,
            totalPassingYards: 0,
            totalPassingYardsShare: 0,
            averageYPC: 0,
            averageYPCmp: 0,
            averageYPP: 0,
            averageYPPShare: 0,
            possessions: homePossessions,
            fetchedData: fetchedHomeStats,
        };
        var awayStats = {
            name: away.name ? away.name : 'Away',
            tds: 0,
            fgs: 0,
            score: 0,
            totalPlays: 0,
            totalYards: 0,
            totalYardShare: 0,
            totalRunPlays: 0,
            totalRunningYards: 0,
            totalRunningYardsShare: 0,
            totalPassPlays: 0,
            totalPassingYards: 0,
            totalPassingYardsShare: 0,
            averageYPC: 0,
            averageYPCmp: 0,
            averageYPP: 0,
            averageYPPShare: 0,
            possessions: awayPossessions,
            fetchedData: fetchedAwayStats,
        };
        var game = {
            heading: home.name + ' @ ' + away.name,
            winner: '',
            homeStats: homeStats,
            awayStats: awayStats
        }

        function simulateTieBreaker () {

            // Run the simulations and add possessions to separate array
            for (let i = 0; i < 2; i++) {
                
                // Set yardsToGoal as 80 for kickoffs
                if (i === 0 || i === 13) {
                    yardsToGoal = 80;
                } else {
                    yardsToGoal = allPossessions[i - 1].yardsToGoal;
                }

                if(i % 2 === 0) {
                    possessor = 'home';
                } else {
                    possessor = 'away';
                }

                allPossessions.push(simulatePossession(possessor, yardsToGoal));
            }
        }

        // Run the simulations and add possessions to separate array
        for (let i = 0; i < possessions; i++) {
            
            // Set yardsToGoal as 80 for kickoffs
            if (i === 0 || i === 13) {
                yardsToGoal = 80;
            } else {
                yardsToGoal = allPossessions[i - 1].yardsToGoal;
            }

            if(i % 2 === 0) {
                possessor = 'home';
            } else {
                possessor = 'away';
            }

            allPossessions.push(simulatePossession(possessor, yardsToGoal));
        }

        // Add results to each team's stats
        for (let i = 0; i < allPossessions.length; i++) {
            let plays = allPossessions[i].possession.playsCalled;
            let rushingPlays = 0;
            let passingPlays = 0;
            

            if (i % 2 === 0) {
                homePossessions.push(allPossessions[i]);

                // Sum up yardage for run and pass plays
                for (let i = 0; i < plays.length; i++) {
                    let play = plays[i];
                    
                    if (play.playType === 'Run' || play.playType === 'Pass') {
                        homeStats.totalYards += play.yards;
                        if (play.playType === 'Run' ) {
                            homeStats.totalRunningYards += play.yards;
                            homeStats.totalRunPlays++;
                            // homeStats.totalRunPlays += i;
                        } else if (play.playType === 'Pass') {
                            homeStats.totalPassingYards += play.yards;
                            // homeStats.totalPassPlays += i;
                            homeStats.totalPassPlays++;
                        }
                    } 
                }

                // Sum up TDs and FGs made 
                if (allPossessions[i].message === 'Touchdown!') {
                    homeStats.tds++;
                } else if (allPossessions[i].message === 'Field Goal!') {
                    homeStats.fgs++;
                }
            } else {
                awayPossessions.push(allPossessions[i]);

                // Sum up yardage for run and pass plays
                for (let i = 0; i < plays.length; i++) {
                    let play = plays[i];
                    
                    if (play.playType === 'Run' || play.playType === 'Pass') {
                        awayStats.totalYards += play.yards;
                        if (play.playType === 'Run') {
                            awayStats.totalRunningYards += play.yards;
                            // awayStats.totalRunPlays += i;
                            awayStats.totalRunPlays++;
                        } else if (play.playType === 'Pass') {
                            awayStats.totalPassingYards += play.yards;
                            // awayStats.totalPassPlays += i;
                            awayStats.totalPassPlays++;
                        }
                    } 
                }

                // Sum up TDs and FGs
                if (allPossessions[i].message === 'Touchdown!') {
                    awayStats.tds++;
                } else if (allPossessions[i].message === 'Field Goal!') {
                    awayStats.fgs++;
                }
            }
        }

        // Set variables to determine each team's percentages
        if (numSims > 0) {
            // Update homeStats
            homeStats.tds = Math.round(homeStats.tds / numSims);
            homeStats.fgs = Math.round(homeStats.fgs / numSims);
            homeStats.totalPlays = Math.round(homeStats.totalPlays / numSims);
            homeStats.totalYards = Math.round(homeStats.totalYards / numSims);
            homeStats.totalRunPlays = Math.round(homeStats.totalRunPlays / numSims);
            homeStats.totalRunningYards = Math.round(homeStats.totalRunningYards / numSims);
            homeStats.totalPassPlays = Math.round(homeStats.totalPassPlays / numSims);
            homeStats.totalPassingYards = Math.round(homeStats.totalPassingYards / numSims);

            // Updated awayStats
            awayStats.tds = Math.round(awayStats.tds / numSims);
            awayStats.fgs = Math.round(awayStats.fgs / numSims);
            awayStats.totalPlays = Math.round(awayStats.totalPlays / numSims);
            awayStats.totalYards = Math.round(awayStats.totalYards / numSims);
            awayStats.totalRunPlays = Math.round(awayStats.totalRunPlays / numSims);
            awayStats.totalRunningYards = Math.round(awayStats.totalRunningYards / numSims);
            awayStats.totalPassPlays = Math.round(awayStats.totalPassPlays / numSims);
            awayStats.totalPassingYards = Math.round(awayStats.totalPassingYards / numSims);
        }
        let totalYardsOverall = homeStats.totalYards + awayStats.totalYards;
        let totalRunningYardsOverall = homeStats.totalRunningYards + awayStats.totalRunningYards;
        let totalPassingYardsOverall = homeStats.totalPassingYards + awayStats.totalPassingYards;

        // Calculate home team's final score and stats
        homeStats.score = (homeStats.tds * 7) + (homeStats.fgs * 3);
        homeStats.totalPlays = homeStats.totalRunPlays + homeStats.totalPassPlays;
        homeStats.averageYPC = Math.round((homeStats.totalRunningYards / homeStats.totalRunPlays) * 10) / 10;
        homeStats.totalRunningYardShare = ((Math.round((homeStats.totalRunningYards / totalRunningYardsOverall) * 1000) / 1000) * 100);
        homeStats.totalRunningYardShare = Math.round(homeStats.totalRunningYardShare).toString() + '%';
        homeStats.averageYPCmp = Math.round((homeStats.totalPassingYards / homeStats.totalPassPlays) * 10) / 10;
        homeStats.totalPassingYardShare = (Math.round((homeStats.totalPassingYards / totalPassingYardsOverall) * 1000) / 1000) * 100;
        homeStats.totalPassingYardShare = Math.round(homeStats.totalPassingYardShare).toString() + '%';
        homeStats.averageYPP = Math.round((homeStats.totalYards / homeStats.totalPlays) * 10) / 10;
        homeStats.totalYardShare = (Math.round((homeStats.totalYards / totalYardsOverall) * 1000) / 1000) * 100;
        homeStats.totalYardShare = Math.round(homeStats.totalYardShare).toString() + '%';

        // Calculate away team's final score and stats
        awayStats.score = (awayStats.tds * 7) + (awayStats.fgs * 3);
        awayStats.totalPlays = awayStats.totalRunPlays + awayStats.totalPassPlays;
        awayStats.averageYPC = Math.round((awayStats.totalRunningYards / awayStats.totalRunPlays) * 10) / 10;
        awayStats.totalRunningYardShare = (Math.round((awayStats.totalRunningYards / totalRunningYardsOverall) * 1000) / 1000) * 100;
        awayStats.totalRunningYardShare = Math.round(awayStats.totalRunningYardShare).toString() + '%';
        awayStats.averageYPCmp = Math.round((awayStats.totalPassingYards / awayStats.totalPassPlays) * 10) / 10;
        awayStats.totalPassingYardShare = (Math.round((awayStats.totalPassingYards / totalPassingYardsOverall) * 1000) / 1000) * 100;
        awayStats.totalPassingYardShare = Math.round(awayStats.totalPassingYardShare).toString() + '%';
        awayStats.averageYPP = Math.round((awayStats.totalYards / awayStats.totalPlays) * 10) / 10;
        awayStats.totalYardShare = (Math.round((awayStats.totalYards / totalYardsOverall) * 1000) / 1000) * 100;
        awayStats.totalYardShare = Math.round(awayStats.totalYardShare).toString() + '%';

        // Calculate averageYPPShare for each team
        let totalYPP = homeStats.averageYPP + awayStats.averageYPP; 
        homeStats.averageYPPShare = ((Math.round((homeStats.averageYPP / totalYPP) * 1000) / 1000) * 100);
        homeStats.averageYPPShare = Math.round(homeStats.averageYPPShare).toString() + '%';
        awayStats.averageYPPShare = ((Math.round((awayStats.averageYPP / totalYPP) * 1000) / 1000) * 100);
        awayStats.averageYPPShare = Math.round(awayStats.averageYPPShare).toString() + '%';
        
        if (homeStats.score === awayStats.score) {
            game.winner = 'Tie';
        } else {
            if (homeStats.score > awayStats.score) {
                game.winner = homeStats.name;
            } else {
                game.winner = awayStats.name;
            }
        }

        // Set up JSON data for game
        const gameJSON = JSON.stringify(game);
        const homeJSON = JSON.stringify(homeStats);
        const awayJSON = JSON.stringify(awayStats);

        // Parse through the data
        const parsedGAME = JSON.parse(gameJSON);
        const parsedTEAMA = JSON.parse(homeJSON);
        const parsedTEAMB = JSON.parse(awayJSON);

        // Return game object
        console.log(game);
        return game;
    }

    return simulateNFLGame(24);
}