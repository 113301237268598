import * as React from 'react';
import {
	Text,
	TextProps,
	View,
	TextInput,
	Pressable,
	Modal,
	ScrollView,
	Dimensions,
	PressableProps,
	Switch,
	LayoutAnimation,
	StyleSheet } from 'react-native';
import { Icon } from 'react-native-elements';
import * as Icons from '@expo/vector-icons';
import { Divider, ListItem } from '@rneui/themed';

// Get custom
import { globals } from '../lib/globals';
import { styles, style } from '../lib/styles';

export const Section = ({ style = null, children, ...rest }) => {
  
	return (
		<View style={[styles.section, style && style, rest]}>
			{children}
		</View>
	);
};

export const Heading = ({
	style,
	...rest
	}: TextProps & { children: React.ReactNode }) => {

	return (
		<Text style={[styles.heading, styles.bodyColor, style]} {...rest} />
	);
};
  
export const Paragraph = ({
	style,
	...rest
	}: TextProps & { children: React.ReactNode }) => {

	return (
		<Text style={[styles.paragraph, style]} {...rest} />
	);
};

export const CodeText = ({
	style,
	...rest
	}: TextProps & { children: React.ReactNode }) => {

	return (
		<Text style={[styles.code, style]} {...rest} />
	);
};

export const DividerGreen = ({
	style = null,
	...rest
	}) => {

	return (
		<Divider style={[style]} color={'#bbffda'} {...rest} />
	);
};

export const DividerPink = ({style = null, ...rest}) => {

	return (
		<Divider style={[style]} color={'#ff00ff'} {...rest} />
	);
}

/**
 * Custom Dropdown menu 
 * Thank you to @eristoddle
 *  - https://github.com/eristoddle/log-rocket-react-native-dropdown-tutoria
 */
interface DropdownProps {
	label: string;
	data: Array<{ label: string; value: string }>;
	onSelect: (item: { label: string; value: string }) => void;
}

export const Dropdown: React.FC<DropdownProps> = ({label, data, onSelect}) => {
	const DropdownButton = React.useRef<any>(null);
	const [visible, setVisible] = React.useState(false);
	const [selected, setSelected] = React.useState(undefined);
	const [dropdownTop, setDropdownTop] = React.useState(0);
	const dimensions = Dimensions.get('window');
	const imageHeight = Math.round(dimensions.width * 9 / 16);

	const toggleDropdown = (): void => {
		visible ? setVisible(false) : openDropdown();
	};

	const openDropdown = (): void => {
		DropdownButton.current.measure((_fx, _fy, _w, h, _px, py) => {
			setDropdownTop(py + h);
		});
		setVisible(true);
	};

	const onItemPress = (item): void => {
		setSelected(item);
		onSelect(item);
		setVisible(false);
	};

	const renderDropdown = (): React.ReactElement<any, any> => {
		return (
		<Modal visible={visible} transparent animationType="fade" style={{width: globals.windowWidth - 32, left: 16, zIndex: 1}}>
			<View style={[styles.dropdown, styles.mt1_half, {width: globals.windowWidth - 32, left: 16, top: dropdownTop, height: 250, borderWidth: 2, borderColor: globals.pink}]}>
				<ScrollView nestedScrollEnabled={true}>
					{data.map((item, i) => (
						<ListItem key={i} containerStyle={{backgroundColor: globals.black}}>
							<ListItem.Content>
								<ListItem.Title>
									<Pressable onPress={() => onItemPress(item)} style={{zIndex: 9}}>
										<Text style={{fontWeight: 'bold', color: globals.white}}>{item.label}</Text>
									</Pressable>
								</ListItem.Title>
							</ListItem.Content>
						</ListItem>
					))} 
			</ScrollView>
			</View>
		</Modal>
		);
	};

	return (
		<Pressable
			ref={DropdownButton}
			style={[style.btn.dropdown]}
			onPress={toggleDropdown}>

			{renderDropdown()}

			<Text style={[style.btn.dropdownBtnText]}>
				{(selected && selected.label) || label}
			</Text>

			<View style={[style.btn.dropdownBtn]}>
				<Icons.MaterialCommunityIcons name={'chevron-down'} size={16} color={globals.white} />
			</View>
		
		</Pressable>
	);
};

export const Btn = ({
	style,
	onPress,
	children,
	...rest
	}: TextProps & PressableProps & { children: React.ReactNode }) => {

	return (
		<Pressable style={[style]} onPress={onPress} {...rest}>
			<Text style={{color: globals.white, fontWeight: 'bold'}}>{children}</Text>
		</Pressable>
	);
};

/**
 * Custom Toggle Button
 */
export const ToggleBtn = ({ isVisible = true, onToggle, isVisibleText = null, isNotVisibleText = null }) => {
	const [isCollapsed, setIsCollapsed] = React.useState(!isVisible);

	const toggleCollapse = () => {
		setIsCollapsed(!isCollapsed);
		if (onToggle) {
		onToggle(!isCollapsed);
		}
	};

	React.useEffect(() => {
		setIsCollapsed(!isVisible);
	}, [isVisible]);

	isVisibleText = isVisibleText ? isVisibleText : 'Hide';
	isNotVisibleText = isNotVisibleText ? isNotVisibleText : 'Show';

	return (
		<View style={styles.container}>
			<Pressable onPress={toggleCollapse}>
				<Text>{isCollapsed ? isNotVisibleText : isVisibleText}</Text>
			</Pressable>
		</View>
	);
};

/**
 * Custom Hide Content Button
 */
export const HideContentButton = () => {
	const [isContentVisible, setIsContentVisible] = React.useState(true);

	const toggleContentVisibility = () => {
		setIsContentVisible(!isContentVisible);
	};

	return (
		<View style={styles.container}>
			<Pressable onPress={toggleContentVisibility}>
				<Text>{isContentVisible ? 'Hide Content' : 'Show Content'}</Text>
			</Pressable>

			{isContentVisible && (
				<View style={styles.content}>
					<Text>This is the content to be hidden or shown.</Text>
				</View>
			)}
		</View>
	);
};

/**
 * Custom Toggle Button
 */
export const SwitchToggle = ({ toggleState, setToggleState, descriptionText, toggleStyles, ...props }) => {
	const trackColorFalse = toggleStyles.trackColorFalse ? toggleStyles.trackColorFalse : globals.xDarkGray;
	const trackColorTrue = toggleStyles.trackColorTrue ? toggleStyles.trackColorTrue : globals.xDarkGray;
	const thumbColorOn = toggleStyles.thumbColorOn ? toggleStyles.thumbColorOn : globals.purple;
	const thumbColorOff = toggleStyles.thumbColorOff ? toggleStyles.thumbColorOff : globals.lightGray;
	const iOSBackground = toggleStyles.iOSBackground ? toggleStyles.iOSBackground : globals.xDarkGray;

	return (
		<View style={[props.reverse === true ? style.flex.rowReverse : style.flex.row, style.flex.aCenter]}>
			{descriptionText && (
				<Text style={[props.reverse === true ? style.margin.ml1 : style.margin.mr1, style.text.white]}>{descriptionText}</Text>
			)}
			<Switch
				trackColor={{
					false: trackColorFalse, 
					true: trackColorTrue,
				}}
				thumbColor={toggleState ? thumbColorOn : thumbColorOff}
				ios_backgroundColor={iOSBackground}
				value={toggleState}
				onValueChange={(value) => setToggleState(value)}
			/>
		</View>
	);
};

/**
 * Custom button to access screens from anywhere
 */
export const GoToButton = ({navigation, screenName, text}) => {

	return (
		<Pressable onPress={() => navigation.navigate(screenName)} style={[style.btn.std, style.btn.primary]}>
			<Text style={[style.text.white, style.text.center, style.text.bold]}>{text}</Text>
		</Pressable>
	)
}

/**
 * Custom form for email
 */
export const BasicTextInput = ({label, placeholderText}) => {
	const placeholder = placeholderText.toString();
	const [text, onChangeText] = React.useState(placeholder);

	return (
		<View style={[style.margin.mb2]}>
			<Text style={[style.input.label]}>{label}</Text>
			<View style={[style.input.std]}>
				<TextInput style={[style.input.text]} onChangeText={onChangeText} value={text} />
			</View>
		</View>
		
	);
}

/**
 * Custom list items
 */
export const ListItemWithIcon = (props) => {

    return (
        <View style={[props.containerStyle ? props.containerStyle : style.list.ulItem]}>
            <View style={[props.iconContainerStyle ? props.iconContainerStyle : style.padding.pr1_quarter]}>
                <Icons.MaterialCommunityIcons 
                    name={props.icon} 
                    size={props.iconSize ? props.iconSize : 24} 
                    color={props.iconColor ? props.iconColor : globals.white}
					iconStyle={props.iconStyle ? props.iconStyle : null}
                />
            </View>
            <View style={[props.textContainerStyle ? props.textContainerStyle : [style.padding.pl1_quarter, {flex: 1}]]}>
                <Text style={[props.textStyle ? props.textStyle : style.text.white]}>{props.text}</Text>
            </View>
        </View>
    );
}

/**
 * Customizable button for expanding content
 */
export const DrpDwnBtn = (props: any) => {
	const [itemVisibility, setItemVisibility] = React.useState(false);

	const itemExpanded = () => {
        LayoutAnimation.configureNext(LayoutAnimation.Presets.easeInEaseOut);
        setItemVisibility(!itemVisibility);
    }

	return (
		<Btn onPress={itemExpanded} style={[style.flex.std, style.flex.centerAll, style.btn.solidPink, style.width.w100, style.height.h100]}>
			<Icons.MaterialCommunityIcons name={itemVisibility ? 'chevron-up' : 'chevron-down'} size={25} color={globals.white} />
		</Btn>
	)
}