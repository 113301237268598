// Get dependencies
import * as React from 'react';
import { 
    View,
    Text,
    Pressable,
    TextInput } from 'react-native';
import { Icon } from '@rneui/themed';
import { getAuth, createUserWithEmailAndPassword, sendEmailVerification } from 'firebase/auth';
import { firebasedb } from '../../shared/services/firebase.db.service';

// Get custom
import { globals } from '../../lib/globals';
import { Paragraph } from '../../shared/ContentItems';
import { style } from '../../lib/styles';
import { useUser } from '../../shared/services/user.service';

const FirebaseRegisterForm = () => {
    const [firstName, setFirstName] = React.useState('');
    const [lastName, setLastName] = React.useState('');
    const [email, setEmail] = React.useState('');
    const [password, setPassword] = React.useState('');
    const [registerError, setRegisterError] = React.useState(null);
    const [registerSuccess, setRegisterSuccess] = React.useState(null);
    const [showPassword, setShowPassword] = React.useState(false);

    const user = useUser();

    function registerClicked() {

        try {
            firebasedb.register({firstName, lastName, email, password});
            console.log(user);
        } catch (error) {
            console.error(error);
        }
    }


    return (

        <View>
            
            {registerSuccess ? (
                <>
                    <View style={style.margin.mb1}>
                        <Paragraph>{registerSuccess}</Paragraph>
                    </View>
                </>
            ) : (
                <>
                    <View style={style.margin.mb1}>
                        <Paragraph style={[style.input.label]}>Name</Paragraph>
                        <View style={[style.display.flex, style.flex.jAround, style.flex.row]}>
                            <TextInput
                                style={[style.input.std]}
                                placeholder={'First'}
                                placeholderTextColor={globals.xLightGray}
                                value={firstName}
                                onChangeText={(text) => setFirstName(text)}
                            />
                            <TextInput
                                style={[style.input.std]}
                                placeholder={'Last'}
                                placeholderTextColor={globals.xLightGray}
                                value={lastName}
                                onChangeText={(text) => setLastName(text)}
                            />
                        </View>
                    </View>
                    <View style={style.margin.mb1}>
                        <Paragraph style={[style.input.label]}>Registration Email</Paragraph>
                        <TextInput
                            style={[style.input.std]}
                            placeholder={'example@test.com'}
                            placeholderTextColor={globals.xLightGray}
                            value={email}
                            onChangeText={(text) => setEmail(text)}
                        />
                    </View>
                    
                    <View style={style.margin.mb1}>
                        <Paragraph style={[style.input.label]}>Registration Password</Paragraph>
                        
                        <View style={[style.position.relative]}>
                            <TextInput
                                style={[style.input.pw]}
                                placeholderTextColor={globals.xLightGray}
                                placeholder={'********'}
                                value={password}
                                secureTextEntry={showPassword ? true : false}
                                onChangeText={(text) => setPassword(text)}
                            />
                            <Pressable onPress={() => setShowPassword(!showPassword)} style={[style.position.absolute, style.position.right1rem, style.position.top1rem_quarter]}>
                                <Icon type={'material'} size={24} color={showPassword ? globals.xLightGray : globals.gray} name={showPassword ? 'visibility-off' : 'visibility'}/>
                            </Pressable>
                        </View>
                    </View>

                    <View style={style.margin.my1}>
                        <Pressable onPress={() => registerClicked()} style={[style.btn.std, style.btn.primary]}>
                            <Paragraph style={[style.btn.text, style.text.center, style.margin.mb0]}>Register</Paragraph>
                        </Pressable>
                    </View>
                </>
            )}
            
            {registerError && (
                <View style={[style.box.white, style.box.round, style.padding.p1_quarter]}>
                    <Paragraph style={[style.text.errorCode]}>{registerError}</Paragraph>
                </View>
            )}

        </View>

    )
}

export default FirebaseRegisterForm;