import * as React from 'react';
import { 
  ScrollView,
  RefreshControl,
  View,
  Pressable } from 'react-native';
import AsyncStorage from '@react-native-async-storage/async-storage';

// Get custom
import { style } from '../lib/styles';
import { Paragraph } from '../shared/ContentItems';
import { firebasedb } from '../shared/services/firebase.db.service';
import { User } from '../components/user/User';
import FirebaseLoginForm from '../components/user/FirebaseLogin';
import FirebaseRegisterForm from '../components/user/FireBaseRegister';

export function ProfileSettingsScreen({navigation}) {
	const [refreshing, setRefreshing] = React.useState(false);
	const [isRegisterModalVisible, setRegisterModalVisible] = React.useState(false);
	const [isLoggedIn, setIsLoggedIn] = React.useState(false);

	const onRefresh = React.useCallback(() => {
		setRefreshing(true);
		setTimeout(() => {
			setRefreshing(false);
		}, 2000);
	}, []);

	React.useEffect(() => {
        AsyncStorage.getItem('loggedIn').then((res) => {
            if (res === 'true') setIsLoggedIn(true); 
            else setIsLoggedIn(false);
        })
    })

	return (
		<ScrollView refreshControl={<RefreshControl refreshing={refreshing} onRefresh={onRefresh} />}>

			<View style={[style.screen.container]}>

				{isLoggedIn ? (
					<>
						<User />
						<Pressable onPress={() => firebasedb.logout()} >
							<Paragraph style={[style.link.std, style.text.center]}>Logout</Paragraph>
						</Pressable>
					</>
				) : (
					<>
						{isRegisterModalVisible ? (
							<View style={style.margin.mt4}>
								<FirebaseRegisterForm />
								<Pressable onPress={() => setRegisterModalVisible(false)} >
									<Paragraph style={[style.link.std, style.text.center]}>Back to Login</Paragraph>
								</Pressable>
							</View>
						) : (
							<View style={style.margin.mt4}>
								<FirebaseLoginForm navigation={(navigation)} />
								<Pressable onPress={() => setRegisterModalVisible(true)} >
									<Paragraph style={[style.link.std, style.text.center]}>Register</Paragraph>
								</Pressable>
							</View>
						)}
					</>
				)}

			</View>

		</ScrollView>
	);
}
