// Get dependencies
import * as React from 'react';
import { 
    View,
    Text,
    Pressable,
    TextInput } from 'react-native';
import * as Icons from '@expo/vector-icons';

// Get custom
import { globals } from '../../lib/globals';
import { Paragraph } from '../../shared/ContentItems';
import { style } from '../../lib/styles';
import { firebasedb } from '../../shared/services/firebase.db.service';
import { User } from './User';

const FirebaseLoginForm = ({navigation}) => {
    const [email, setEmail] = React.useState('');
    const [password, setPassword] = React.useState('');
    const [loginError, setLoginError] = React.useState(null);
    const [loginSuccess, setLoginSuccess] = React.useState(null);
    const [showPassword, setShowPassword] = React.useState(false);

    function handleLoginClicked() {

        try {
            firebasedb.login({email, password});
            setLoginSuccess('Logged In Successfully!');
        } catch (error) {
            console.error(error);
            setLoginError(error);
        }
    }

    return (

        <View>
            
            {loginSuccess ? (
                <>
                    <View style={style.margin.mb1}>
                        <User />
                        <Paragraph>{loginSuccess}</Paragraph>
                    </View>
                </>
            ) : (
                <>
                    <View style={style.margin.mb1}>
                        <Paragraph style={[style.input.label]}>Email</Paragraph>
                        <TextInput
                            style={[style.input.std]}
                            placeholder={'example@test.com'}
                            placeholderTextColor={globals.xLightGray}
                            value={email}
                            onChangeText={(text) => setEmail(text)}
                        />
                    </View>
                    
                    <View style={style.margin.mb1}>
                        <Paragraph style={[style.input.label]}>Password</Paragraph>
                        
                        <View style={[style.position.relative]}>
                            <TextInput
                                style={[style.input.pw]}
                                placeholderTextColor={globals.xLightGray}
                                placeholder={'********'}
                                value={password}
                                secureTextEntry={showPassword ? true : false}
                                onChangeText={(text) => setPassword(text)}
                            />
                            <Pressable onPress={() => setShowPassword(!showPassword)} style={[style.position.absolute, style.position.right1rem, style.position.top1rem_quarter]}>
                                <Icons.MaterialIcons size={24} color={showPassword ? globals.xLightGray : globals.gray} name={showPassword ? 'visibility-off' : 'visibility'}/>
                            </Pressable>
                        </View>
                    </View>

                    <View style={style.margin.my1}>
                        <Pressable onPress={() => handleLoginClicked()} style={[style.btn.std, style.btn.primary]}>
                            <Paragraph style={[style.btn.text, style.text.center, style.margin.mb0]}>Login</Paragraph>
                        </Pressable>
                    </View>
                </>
            )}
            
            {loginError && (
                <View style={[style.box.white, style.box.round, style.padding.p1_quarter]}>
                    <Paragraph style={[style.text.errorCode]}>{loginError}</Paragraph>
                </View>
            )}

        </View>

    )
}

export default FirebaseLoginForm;